export default [
    {
        k: 'Preescolar',
        v: 'Preescolar'
    },
    {
        k: 'Primaria',
        v: 'Primaria'
    },
    {
        k: 'Secundaria',
        v: 'Secundaria'
    },
    {
        k: 'Profesional técnico',
        v: 'Profesional técnico'
    },
    {
        k: 'Bachillerato o su equivalente',
        v: 'Bachillerato o equivalente'
    }	
]
