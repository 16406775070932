import { ref, watch, computed, getCurrentInstance } from '@vue/composition-api'
import store from '@/store'
import { formatDateMX, kFormatter } from '@core/utils/filter'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductList() {
  // Use toast
  const toast = useToast()

  const refProductListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'product', 
      label: 'Name',
      //sortable: true
    },
    { 
      key: 's', 
      label: 'Sat Id',
      //sortable: true 
    },
    { 
      key: 'amount', 
      label: 'Price',
      //sortable: true 
    },
    {
      key: 'le',
      label: 'Last Invoiced',
      //sortable: true,
    },
    { 
      key: 'actions',
      label: 'Actions',
    },
  ]
  const perPage = ref(10)
  const totalProducts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refProductListTable.value ? refProductListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    }
  })

  const refetchData = () => {
    refProductListTable.value.refresh()
  }

  watch([searchQuery], (v) => {
    if(searchQuery.value !== '' && searchQuery.value.length >= 4) {
      refetchData()  
    } else if (searchQuery.value === '') {
      refetchData()  
    }
  })

  watch([currentPage, perPage] , () => {
    refetchData()  
  })

  const fetchProducts = (ctx, callback) => {
    store
      .dispatch('fg-product/listProduct', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { products, total } = response.data

        callback(products)
        totalProducts.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error al obtener la lista de productos',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveProductTypeVariant = type => {
    if (type === 'p') return 'primary'
    if (type === 's') return 'secondary'
    return 'primary'
  }

  return {
    fetchProducts,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductListTable,

    resolveProductTypeVariant,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
