import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    listProduct(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchPYS(ctx, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/common/search/ps', { search })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product/get', { uuid: uuid })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, product ) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product/add', { product: product })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product/update', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProduct(ctx, uuid) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product/delete', { uuid: uuid })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
