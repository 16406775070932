export default [
    {
        k: '26.50',
        v: '26.50',
        c_impuesto_k: '003'
    },
    {
        k: '30.00',
        v: '30.00',
        c_impuesto_k: '003'
    },
    {
        k: '53.00',
        v: '53.00',
        c_impuesto_k: '003'
    },
    {
        k: '50.00',
        v: '50.00',
        c_impuesto_k: '003'
    },
    {
        k: '160.00',
        v: '160.00',
        c_impuesto_k: '003'
    },
    {
        k: '30.40',
        v: '30.40',
        c_impuesto_k: '003'
    },
    {
        k: '25.00',
        v: '25.00',
        c_impuesto_k: '003'
    },
    {
        k: '9.00',
        v: '9.00',
        c_impuesto_k: '003'
    },
    {
        k: '8.00',
        v: '8.00',
        c_impuesto_k: '003'
    },
    {
        k: '7.00',
        v: '7.00',
        c_impuesto_k: '003'
    },
    {
        k: '6.00',
        v: '6.00',
        c_impuesto_k: '003'
    }
]