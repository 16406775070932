export default [
    {
        k: 'H87',
        v: 'Pieza',
        o: 1
    },
    {
        k: 'EA',
        v: 'Elemento',
        o: 2
    },
    {
        k: 'E48',
        v: 'Unidad de servicio',
        o: 3
    },
    {
        k: 'ACT',
        v: 'Actividad',
        o: 4
    },
    {
        k: 'KGM',
        v: 'Kilogramo',
        o: 5
    },
    {
        k: 'E51',
        v: 'Trabajo',
        o: 6
    },
    {
        k: 'A9',
        v: 'Tarifa',
        o: 7
    },
    {
        k: 'MTR',
        v: 'Metro',
        o: 8
    },
    {
        k: 'AB',
        v: 'Paquete a granel',
        o: 9
    },
    {
        k: 'BB',
        v: 'Caja base',
        o: 10
    },
    {
        k: 'KT',
        v: 'Kit',
        o: 11
    },
    {
        k: 'SET',
        v: 'Conjunto',
        o: 12
    },
    {
        k: 'LTR',
        v: 'Litro',
        o: 13
    },
    {
        k: 'MON',
        v: 'Mes',
        o: 14
    },
    {
        k: 'XBX',
        v: 'Caja',
        o: 15
    },
    {
        k: 'HUR',
        v: 'Hora',
        o: 16
    },
    {
        k: 'MTK',
        v: 'Metro cuadrado',
        o: 17
    },
    {
        k: '11',
        v: 'Equipos',
        o: 18
    },
    {
        k: 'MGM',
        v: 'Miligramo',
        o: 19
    },
    {
        k: 'XPK',
        v: 'Paquete',
        o: 20
    },
   /* {
        k: 'XKI',
        v: 'Kit (Conjunto de piezas)',
        o: 21
    },*/
    {
        k: 'AS',
        v: 'Variedad',
        o: 22
    },
    {
        k: 'GRM',
        v: 'Gramo',
        o: 23
    },
    {
        k: 'PR',
        v: 'Par',
        o: 24
    },
    {
        k: 'DPC',
        v: 'Docenas de piezas',
        o: 25
    },
    {
        k: 'XUN',
        v: 'Unidad',
        o: 26
    },
    {
        k: 'DAY',
        v: 'Día',
        o: 27
    },
    {
        k: 'XLT',
        v: 'Lote',
        o: 28
    },
    {
        k: '10',
        v: 'Grupos',
        o: 29
    },
    {
        k: 'MLT',
        v: 'Mililitro',
        o: 30
    },
    {
        k: 'E54',
        v: 'Viaje',
        o: 31
    },
    {
        k: '18',
        v: 'Tambor de cincuenta y cinco galones (EUA)',
        o: 32
    },
    {
        k: '19',
        v: 'Camión cisterna',
        o: 33
    },
    {
        k: '26',
        v: 'Tonelada real',
        o: 34
    },
    {
        k: '29',
        v: 'Libra por mil pies cuadrados',
        o: 35
    },
    {
        k: '30',
        v: 'Día de potencia de caballos por tonelada métrica de aire seco',
        o: 36
    },
    {
        k: '31',
        v: 'Pescar',
        o: 37
    },
    {
        k: '32',
        v: 'Kilogramo por tonelada métrica seca del aire',
        o: 38
    },
    {
        k: '36',
        v: 'Pie cúbico por minuto por pie cuadrado',
        o: 39
    },
    {
        k: '44',
        v: 'Bolsa a granel de quinientos kilos',
        o: 40
    },
    {
        k: '45',
        v: 'Bolsa a granel de trescientos kilos',
        o: 41
    },
    {
        k: '46',
        v: 'Bolsa a granel de cincuenta libras',
        o: 42
    },
    {
        k: '47',
        v: 'Bolso de cincuenta libras',
        o: 43
    },
    {
        k: '48',
        v: 'Carga masiva',
        o: 44
    },
    {
        k: '53',
        v: 'Kilogramo teórico',
        o: 45
    },
    {
        k: '54',
        v: 'Tonelada teórica (UK)',
        o: 46
    },
    {
        k: '62',
        v: 'Por ciento por 1000 horas',
        o: 47
    },
    {
        k: '63',
        v: 'Tasa de fracaso en el tiempo',
        o: 48
    },
    {
        k: '64',
        v: 'Libra por pulgada cuadrada, calibre',
        o: 49
    },
    {
        k: '66',
        v: 'Oersted',
        o: 50
    },
    {
        k: '69',
        v: 'Escala específica de prueba',
        o: 51
    },
    {
        k: '71',
        v: 'Voltios amperios por libra',
        o: 52
    },
    {
        k: '72',
        v: 'Vatio por libra',
        o: 53
    },
    {
        k: '73',
        v: 'Amperios por centímetro',
        o: 54
    },
    {
        k: '76',
        v: 'Gauss',
        o: 55
    },
    {
        k: '78',
        v: 'Kilogauss',
        o: 56
    },
    {
        k: '84',
        v: 'Kilopound-force por pulgada cuadrada',
        o: 57
    },
    {
        k: '90',
        v: 'Saybold segundo universal',
        o: 58
    },
    {
        k: '92',
        v: 'Calorías por centímetro cúbico',
        o: 59
    },
    {
        k: '93',
        v: 'Calorías por gramo',
        o: 60
    },
    {
        k: '94',
        v: 'Unidad de curl',
        o: 61
    },
    {
        k: '95',
        v: 'Veinte mil galones',
        o: 62
    },
    {
        k: '96',
        v: 'Diez mil galones (US)',
        o: 63
    },
    {
        k: '97',
        v: 'Diez kilos de tambor',
        o: 64
    },
    {
        k: '98',
        v: 'Quince kilos de tambor',
        o: 65
    },
    {
        k: '05',
        v: 'Ascensor',
        o: 66
    },
    {
        k: '06',
        v: 'Pequeño aerosol',
        o: 67
    },
    {
        k: '08',
        v: 'Montón de calor',
        o: 68
    },
    {
        k: '13',
        v: 'Raciones',
        o: 69
    },
    {
        k: '14',
        v: 'Shot',
        o: 70
    },
    {
        k: '15',
        v: 'Palo, medida militar.',
        o: 71
    },
    {
        k: '16',
        v: 'Tambor de 115 kilogramos',
        o: 72
    },
    {
        k: '17',
        v: 'Tambor de cien libras',
        o: 73
    },
    {
        k: '1A',
        v: 'Milla de carros',
        o: 74
    },
    {
        k: '1B',
        v: 'Recuento de automóviles',
        o: 75
    },
    {
        k: '1C',
        v: 'Conteo de locomotoras',
        o: 76
    },
    {
        k: '1D',
        v: 'Caboose count',
        o: 77
    },
    {
        k: '1E',
        v: 'Coche vacío',
        o: 78
    },
    {
        k: '1F',
        v: 'Milla de tren',
        o: 79
    },
    {
        k: '1G',
        v: 'Galón del uso del combustible (los EUA)',
        o: 80
    },
    {
        k: '1H',
        v: 'Milla de caboose',
        o: 81
    },
    {
        k: '1I',
        v: 'Tipo de interés fijo',
        o: 82
    },
    {
        k: '1J',
        v: 'Tonelada milla',
        o: 83
    },
    {
        k: '1K',
        v: 'Milla locomotora',
        o: 84
    },
    {
        k: '1L',
        v: 'Recuento total de automóviles',
        o: 85
    },
    {
        k: '1M',
        v: 'Milla total del coche',
        o: 86
    },
    {
        k: '1X',
        v: 'Cuarto de milla',
        o: 87
    },
    {
        k: '20',
        v: 'Contenedores de veinte pies',
        o: 88
    },
    {
        k: '21',
        v: 'Contenedor de cuarenta pies',
        o: 89
    },
    {
        k: '22',
        v: 'Decilitro por gramo',
        o: 90
    },
    {
        k: '23',
        v: 'Gramo por centímetro cúbico',
        o: 91
    },
    {
        k: '24',
        v: 'Libra teórica',
        o: 92
    },
    {
        k: '25',
        v: 'Gramo por centímetro cuadrado',
        o: 93
    },
    {
        k: '27',
        v: 'Tonelada teórica',
        o: 94
    },
    {
        k: '28',
        v: 'Kilogramo por metro cuadrado',
        o: 95
    },
    {
        k: '2A',
        v: 'Radián por segundo',
        o: 96
    },
    {
        k: '2B',
        v: 'Radián por segundo cuadrado',
        o: 97
    },
    {
        k: '2C',
        v: 'Roentgen',
        o: 98
    },
    {
        k: '2G',
        v: 'Voltios CA',
        o: 99
    },
    {
        k: '2H',
        v: 'Voltios CD',
        o: 100
    },
    {
        k: '2I',
        v: 'Unidad térmica británica (tabla internacional) por hora',
        o: 101
    },
    {
        k: '2J',
        v: 'Centímetro cúbico por segundo',
        o: 102
    },
    {
        k: '2K',
        v: 'Pie cúbico por hora',
        o: 103
    },
    {
        k: '2L',
        v: 'Pie cúbico por minuto',
        o: 104
    },
    {
        k: '2M',
        v: 'Centímetro por segundo',
        o: 105
    },
    {
        k: '2N',
        v: 'Decibel',
        o: 106
    },
    {
        k: '2P',
        v: 'Kilobyte',
        o: 107
    },
    {
        k: '2Q',
        v: 'Kilobecquerel',
        o: 108
    },
    {
        k: '2R',
        v: 'Kilocurie',
        o: 109
    },
    {
        k: '2U',
        v: 'Megagramo',
        o: 110
    },
    {
        k: '2V',
        v: 'Megagrama por hora',
        o: 111
    },
    {
        k: '2X',
        v: 'Metro por minuto',
        o: 112
    },
    {
        k: '2Y',
        v: 'Milliroentgen',
        o: 113
    },
    {
        k: '2Z',
        v: 'Milivoltio',
        o: 114
    },
    {
        k: '33',
        v: 'Kilopascal por grtr',
        o: 115
    },
    {
        k: '34',
        v: 'Kilopascal por milímetro',
        o: 116
    },
    {
        k: '35',
        v: 'Milímetro por un segundo centímetro cuadrado',
        o: 117
    },
    {
        k: '37',
        v: 'Onza por pie cuadrado',
        o: 118
    },
    {
        k: '38',
        v: 'Onzas por pie cuadrado por 0,01 pulgadas',
        o: 119
    },
    {
        k: '3B',
        v: 'Megajoule',
        o: 120
    },
    {
        k: '3C',
        v: 'Manmonth',
        o: 121
    },
    {
        k: '3E',
        v: 'Libra por libra de producto',
        o: 122
    },
    {
        k: '3G',
        v: 'Libra por pieza de producto',
        o: 123
    },
    {
        k: '3H',
        v: 'Kilogramo por kilogramo de producto',
        o: 124
    },
    {
        k: '3I',
        v: 'Kilogramo por pedazo de producto',
        o: 125
    },
    {
        k: '40',
        v: 'Mililitro por segundo',
        o: 126
    },
    {
        k: '41',
        v: 'Mililitro por minuto',
        o: 127
    },
    {
        k: '4B',
        v: 'Gorra',
        o: 128
    },
    {
        k: '4C',
        v: 'Centistokes',
        o: 129
    },
    {
        k: '4E',
        v: 'Veinte pack',
        o: 130
    },
    {
        k: '4G',
        v: 'Microlitro',
        o: 131
    },
    {
        k: '4H',
        v: 'Micra',
        o: 132
    },
    {
        k: '4K',
        v: 'Miliamperio',
        o: 133
    },
    {
        k: '4L',
        v: 'Megabyte',
        o: 134
    },
    {
        k: '4M',
        v: 'Miligramo por hora',
        o: 135
    },
    {
        k: '4N',
        v: 'Megabequerel',
        o: 136
    },
    {
        k: '4O',
        v: 'Microfaradio',
        o: 137
    },
    {
        k: '4P',
        v: 'Newton por metro',
        o: 138
    },
    {
        k: '4Q',
        v: 'Onza pulgada',
        o: 139
    },
    {
        k: '4R',
        v: 'Onza pie',
        o: 140
    },
    {
        k: '4T',
        v: 'Picofaradio',
        o: 141
    },
    {
        k: '4U',
        v: 'Libra por hora',
        o: 142
    },
    {
        k: '4W',
        v: 'Tonelada (EUA) por hora',
        o: 143
    },
    {
        k: '4X',
        v: 'Kilolitro por hora',
        o: 144
    },
    {
        k: '56',
        v: 'Sitas',
        o: 145
    },
    {
        k: '57',
        v: 'Malla',
        o: 146
    },
    {
        k: '58',
        v: 'kilogramo neto',
        o: 147
    },
    {
        k: '59',
        v: 'Parte por millón',
        o: 148
    },
    {
        k: '5A',
        v: 'Barril por minuto',
        o: 149
    },
    {
        k: '5B',
        v: 'Batch',
        o: 150
    },
    {
        k: '5C',
        v: 'Galón (US) por mil',
        o: 151
    },
    {
        k: '5E',
        v: 'Mmscf/day',
        o: 152
    },
    {
        k: '5F',
        v: 'Libra por mil',
        o: 153
    },
    {
        k: '5G',
        v: 'bomba',
        o: 154
    },
    {
        k: '5H',
        v: 'Escenario',
        o: 155
    },
    {
        k: '5I',
        v: 'Pies cúbicos estándar',
        o: 156
    },
    {
        k: '5J',
        v: 'Caballos de potencia hidráulica',
        o: 157
    },
    {
        k: '5K',
        v: 'Contar por minuto',
        o: 158
    },
    {
        k: '5P',
        v: 'Nivel sísmico',
        o: 159
    },
    {
        k: '5Q',
        v: 'Línea sísmica',
        o: 160
    },
    {
        k: '60',
        v: 'Tanto por ciento en peso',
        o: 161
    },
    {
        k: '61',
        v: 'Parte por mil millones (EUA)',
        o: 162
    },
    {
        k: '74',
        v: 'Milipascal',
        o: 163
    },
    {
        k: '77',
        v: 'Mili-pulgada',
        o: 164
    },
    {
        k: '80',
        v: 'Libra por pulgada cuadrado absoluta',
        o: 165
    },
    {
        k: '81',
        v: 'Henry',
        o: 166
    },
    {
        k: '85',
        v: 'Pie libra-fuerza',
        o: 167
    },
    {
        k: '87',
        v: 'Libra por pie cúbico',
        o: 168
    },
    {
        k: '89',
        v: 'Poise',
        o: 169
    },
    {
        k: '91',
        v: 'Stokes',
        o: 170
    },
    {
        k: 'A1',
        v: '15 ° C calorías',
        o: 171
    },
    {
        k: 'A10',
        v: 'Amperio por metro cuadrado por joule segundo',
        o: 172
    },
    {
        k: 'A11',
        v: 'Ángstrom',
        o: 173
    },
    {
        k: 'A12',
        v: 'Unidad astronómica',
        o: 174
    },
    {
        k: 'A13',
        v: 'Attojoule',
        o: 175
    },
    {
        k: 'A14',
        v: 'Barn',
        o: 176
    },
    {
        k: 'A15',
        v: 'Barn por electrovoltio',
        o: 177
    },
    {
        k: 'A16',
        v: 'Barn por electrovoltio',
        o: 178
    },
    {
        k: 'A17',
        v: 'Barn por esteroradian',
        o: 179
    },
    {
        k: 'A18',
        v: 'Becquerel por kilogramo',
        o: 180
    },
    {
        k: 'A19',
        v: 'Becquerel por metro cúbico',
        o: 181
    },
    {
        k: 'A2',
        v: 'Amperio por centímetro',
        o: 182
    },
    {
        k: 'A20',
        v: 'Unidad térmica británica (tabla internacional) por segundo pie cuadrado grado rankine.',
        o: 183
    },
    {
        k: 'A21',
        v: 'Unidad térmica británica (tabla internacional) por libra grado rankine',
        o: 184
    },
    {
        k: 'A22',
        v: 'Unidad térmica británica (tabla internacional) por segundo pie grado rankine',
        o: 185
    },
    {
        k: 'A23',
        v: 'Unidad térmica británica (tabla internacional) por hora pie cuadrado grado rankine.',
        o: 186
    },
    {
        k: 'A24',
        v: 'Candela por metro cuadrado',
        o: 187
    },
    {
        k: 'A25',
        v: 'Caballo de vapor',
        o: 188
    },
    {
        k: 'A26',
        v: 'Culombio metro',
        o: 189
    },
    {
        k: 'A27',
        v: 'Culombio metro cuadrado por voltio',
        o: 190
    },
    {
        k: 'A28',
        v: 'Culombio por centímetro cúbico',
        o: 191
    },
    {
        k: 'A29',
        v: 'Culombio por metro cúbico',
        o: 192
    },
    {
        k: 'A3',
        v: 'Amperio por milímetro',
        o: 193
    },
    {
        k: 'A30',
        v: 'Culombio por milímetro cúbico',
        o: 194
    },
    {
        k: 'A31',
        v: 'Culombio por kilogramo-segundo',
        o: 195
    },
    {
        k: 'A32',
        v: 'Culombio por Mol',
        o: 196
    },
    {
        k: 'A33',
        v: 'Culombio por centímetro cuadrado',
        o: 197
    },
    {
        k: 'A34',
        v: 'Culombio por metro cuadrado',
        o: 198
    },
    {
        k: 'A35',
        v: 'Culombio por milímetro cuadrado',
        o: 199
    },
    {
        k: 'A36',
        v: 'Centímetro cúbico por Mol',
        o: 200
    },
    {
        k: 'A37',
        v: 'Decímetro cuadrado por Mol',
        o: 201
    },
    {
        k: 'A38',
        v: 'Cubic pooulo p',
        o: 202
    },
    {
        k: 'A39',
        v: 'Metro cúbico por kilogramo',
        o: 203
    },
    {
        k: 'A4',
        v: 'Amperio por centímetro cuadrado',
        o: 204
    },
    {
        k: 'A40',
        v: 'Metro cúbico por Mol',
        o: 205
    },
    {
        k: 'A41',
        v: 'Amperio por metro cuadrado',
        o: 206
    },
    {
        k: 'A42',
        v: 'Curie por kilogramo',
        o: 207
    },
    {
        k: 'A43',
        v: 'Tonelaje de peso muerto',
        o: 208
    },
    {
        k: 'A44',
        v: 'Decalitro',
        o: 209
    },
    {
        k: 'A45',
        v: 'Decámetro',
        o: 210
    },
    {
        k: 'A47',
        v: 'Decitex',
        o: 211
    },
    {
        k: 'A48',
        v: 'Grado rankine',
        o: 212
    },
    {
        k: 'A49',
        v: 'Negador',
        o: 213
    },
    {
        k: 'A5',
        v: 'Amperio metro cuadrado',
        o: 214
    },
    {
        k: 'A50',
        v: 'Dina segundo por centímetro cúbico',
        o: 215
    },
    {
        k: 'A51',
        v: 'Dina segundo por centímetro',
        o: 216
    },
    {
        k: 'A52',
        v: 'Dina segundo por centímetro a la quinta potencia',
        o: 217
    },
    {
        k: 'A53',
        v: 'Electronvoltio',
        o: 218
    },
    {
        k: 'A54',
        v: 'Electronvoltio por metro',
        o: 219
    },
    {
        k: 'A55',
        v: 'Electronvoltio por metro cuadrado',
        o: 220
    },
    {
        k: 'A56',
        v: 'Electronvoltio metro cuadrado por kilogramo',
        o: 221
    },
    {
        k: 'A57',
        v: 'Ergio',
        o: 222
    },
    {
        k: 'A58',
        v: 'Erg por centímetro',
        o: 223
    },
    {
        k: 'A59',
        v: 'La cobertura de nubes 8-parte',
        o: 224
    },
    {
        k: 'A6',
        v: 'Amperio por metro cuadrado Kelvin cuadrado',
        o: 225
    },
    {
        k: 'A60',
        v: 'Erg por centímetro cúbico',
        o: 226
    },
    {
        k: 'A61',
        v: 'Erg por gramo',
        o: 227
    },
    {
        k: 'A62',
        v: 'Erg por segundo gramo',
        o: 228
    },
    {
        k: 'A63',
        v: 'Erg por segundo',
        o: 229
    },
    {
        k: 'A64',
        v: 'Erg por segundo centímetro cuadrado',
        o: 230
    },
    {
        k: 'A65',
        v: 'Erg por centímetro cuadrado segundo',
        o: 231
    },
    {
        k: 'A66',
        v: 'Erg centímetro cuadrado',
        o: 232
    },
    {
        k: 'A67',
        v: 'Erg centímetro cuadrado por gramo',
        o: 233
    },
    {
        k: 'A68',
        v: 'Exajoule',
        o: 234
    },
    {
        k: 'A69',
        v: 'Faradio por metro',
        o: 235
    },
    {
        k: 'A7',
        v: 'Amperio por milímetro cuadrado',
        o: 236
    },
    {
        k: 'A70',
        v: 'Femtojoule',
        o: 237
    },
    {
        k: 'A71',
        v: 'Femtómetro',
        o: 238
    },
    {
        k: 'A73',
        v: 'Pie por segundo al cuadrado',
        o: 239
    },
    {
        k: 'A74',
        v: 'Pie libra-fuerza por segundo',
        o: 240
    },
    {
        k: 'A75',
        v: 'Tonelada de carga',
        o: 241
    },
    {
        k: 'A76',
        v: 'Galón',
        o: 242
    },
    {
        k: 'A77',
        v: 'Gaussian CGS (Centímetro-Gram-Segundo sistema) unidad de desplazamiento',
        o: 243
    },
    {
        k: 'A78',
        v: 'Gaussiano CGS (Centímetro-Gram-Segundo sistema) unidad de corriente eléctrica',
        o: 244
    },
    {
        k: 'A79',
        v: 'Gaussian CGS (Centímetro-Gram-Segundo sistema) unidad de carga eléctrica',
        o: 245
    },
    {
        k: 'A8',
        v: 'Amperio segundo',
        o: 246
    },
    {
        k: 'A80',
        v: 'Gaussiano CGS (Centímetro-Gram-Segundo sistema) unidad de la fuerza del campo eléctrico',
        o: 247
    },
    {
        k: 'A81',
        v: 'Gaussian CGS (Centímetro-Gram-Segundo sistema) unidad de polarización eléctrica',
        o: 248
    },
    {
        k: 'A82',
        v: 'Gaussiano CGS (Centímetro-Gram-Segundo sistema) unidad de potencial eléctrico',
        o: 249
    },
    {
        k: 'A83',
        v: 'Gaussian CGS (Centímetro-Gram-Segundo sistema) unidad de magnetización',
        o: 250
    },
    {
        k: 'A84',
        v: 'GigaCulombio por metro cúbico',
        o: 251
    },
    {
        k: 'A85',
        v: 'Gigaelectrónvoltio',
        o: 252
    },
    {
        k: 'A86',
        v: 'Gigahertz',
        o: 253
    },
    {
        k: 'A87',
        v: 'GigaOhm',
        o: 254
    },
    {
        k: 'A88',
        v: 'GigaOhm metro',
        o: 255
    },
    {
        k: 'A89',
        v: 'Gigapascal',
        o: 256
    },
    {
        k: 'A90',
        v: 'Gigawatt',
        o: 257
    },
    {
        k: 'A91',
        v: 'Grado centesimal',
        o: 258
    },
    {
        k: 'A93',
        v: 'Gramo por metro cúbico',
        o: 259
    },
    {
        k: 'A94',
        v: 'Gramo por Mol',
        o: 260
    },
    {
        k: 'A95',
        v: 'Gray',
        o: 261
    },
    {
        k: 'A96',
        v: 'Gray por segundo',
        o: 262
    },
    {
        k: 'A97',
        v: 'Hectopascal',
        o: 263
    },
    {
        k: 'A98',
        v: 'Henry por metro',
        o: 264
    },
    {
        k: 'A99',
        v: 'Bit',
        o: 265
    },
    {
        k: 'AA',
        v: 'Balón',
        o: 266
    },
    {
        k: 'ACR',
        v: 'Acre',
        o: 267
    },
    {
        k: 'AD',
        v: 'Byte',
        o: 268
    },
    {
        k: 'AE',
        v: 'Amperio por metro',
        o: 269
    },
    {
        k: 'AH',
        v: 'Minuto adicional',
        o: 270
    },
    {
        k: 'AI',
        v: 'Minuto y medio por llamada',
        o: 271
    },
    {
        k: 'AJ',
        v: 'policía',
        o: 272
    },
    {
        k: 'AK',
        v: 'Braza',
        o: 273
    },
    {
        k: 'AL',
        v: 'Línea de acceso',
        o: 274
    },
    {
        k: 'AMH',
        v: 'Amperio hora',
        o: 275
    },
    {
        k: 'AMP',
        v: 'Amperio',
        o: 276
    },
    {
        k: 'ANN',
        v: 'Año',
        o: 277
    },
    {
        k: 'AP',
        v: 'Libra de aluminio solamente',
        o: 278
    },
    {
        k: 'APZ',
        v: 'Onza troy u onza farmacéutica',
        o: 279
    },
    {
        k: 'AQ',
        v: 'Unidad del factor anti-hemofílico.',
        o: 280
    },
    {
        k: 'AR',
        v: 'supositorio',
        o: 281
    },
    {
        k: 'ARE',
        v: 'Are',
        o: 282
    },
    {
        k: 'ASM',
        v: 'Grado alcohólico en masa',
        o: 283
    },
    {
        k: 'ASU',
        v: 'Grado alcohólico volumétrico',
        o: 284
    },
    {
        k: 'ATM',
        v: 'Atmósfera estándar',
        o: 285
    },
    {
        k: 'ATT',
        v: 'Atmósfera técnica',
        o: 286
    },
    {
        k: 'AW',
        v: 'Relleno de polvo en viales',
        o: 287
    },
    {
        k: 'AWG',
        v: 'Calibre de alambre americano',
        o: 288
    },
    {
        k: 'AY',
        v: 'Montaje',
        o: 289
    },
    {
        k: 'AZ',
        v: 'Unidad térmica británica (tabla internacional) por libra',
        o: 290
    },
    {
        k: 'B0',
        v: 'Btu por pie cúbico',
        o: 291
    },
    {
        k: 'B1',
        v: 'Barril (EUA) por día',
        o: 292
    },
    {
        k: 'B10',
        v: 'Bits por segundo',
        o: 293
    },
    {
        k: 'B11',
        v: 'Joule por kilogramo kelvin',
        o: 294
    },
    {
        k: 'B12',
        v: 'Joule por metro',
        o: 295
    },
    {
        k: 'B13',
        v: 'Joule por metro cuadrado',
        o: 296
    },
    {
        k: 'B14',
        v: 'Joule por metro a la cuarta potencia',
        o: 297
    },
    {
        k: 'B15',
        v: 'Juole por Mol',
        o: 298
    },
    {
        k: 'B16',
        v: 'Juoule por Mol kelvin',
        o: 299
    },
    {
        k: 'B17',
        v: 'Crédito',
        o: 300
    },
    {
        k: 'B18',
        v: 'Segundos joule',
        o: 301
    },
    {
        k: 'B19',
        v: 'Dígito',
        o: 302
    },
    {
        k: 'B2',
        v: 'litera',
        o: 303
    },
    {
        k: 'B20',
        v: 'Joule metro cuadrado por kilogramo',
        o: 304
    },
    {
        k: 'B21',
        v: 'Kelvin por watt',
        o: 305
    },
    {
        k: 'B22',
        v: 'Kiloamperio',
        o: 306
    },
    {
        k: 'B23',
        v: 'Kiloamperio por metro cuadrado',
        o: 307
    },
    {
        k: 'B24',
        v: 'Kiloamperio por metro',
        o: 308
    },
    {
        k: 'B25',
        v: 'Kilobecquerel por metro cúbico',
        o: 309
    },
    {
        k: 'B26',
        v: 'KiloCulombio',
        o: 310
    },
    {
        k: 'B27',
        v: 'KiloCulombio por metro cúbico',
        o: 311
    },
    {
        k: 'B28',
        v: 'KiloCulombio por metro cuadrado',
        o: 312
    },
    {
        k: 'B29',
        v: 'Kiloelectrónvoltio',
        o: 313
    },
    {
        k: 'B3',
        v: 'Libra de bateo',
        o: 314
    },
    {
        k: 'B30',
        v: 'Gibibit',
        o: 315
    },
    {
        k: 'B31',
        v: 'Kilogramo metro por segundo',
        o: 316
    },
    {
        k: 'B32',
        v: 'Kilogramo metro cuadrado',
        o: 317
    },
    {
        k: 'B33',
        v: 'Kilogramo metro cuadrado por segundo',
        o: 318
    },
    {
        k: 'B34',
        v: 'Kilogramo por decímetro cúbico',
        o: 319
    },
    {
        k: 'B35',
        v: 'Kilogramo por litro',
        o: 320
    },
    {
        k: 'B36',
        v: 'Calorías (termoquímicas) por gramo',
        o: 321
    },
    {
        k: 'B37',
        v: 'Kilogramo de fuerza',
        o: 322
    },
    {
        k: 'B38',
        v: 'Kilogramo-metro de la fuerza',
        o: 323
    },
    {
        k: 'B39',
        v: 'Kilogramo-fuerza del metro por segundo',
        o: 324
    },
    {
        k: 'B4',
        v: 'Barril, unidad imperial',
        o: 325
    },
    {
        k: 'B40',
        v: 'Kilogramo de fuerza por metro cuadrado',
        o: 326
    },
    {
        k: 'B41',
        v: 'Kilojoule por kelvin',
        o: 327
    },
    {
        k: 'B42',
        v: 'Kilojoule por kilogramo',
        o: 328
    },
    {
        k: 'B43',
        v: 'Kilojoule por kilogramo kelvin',
        o: 329
    },
    {
        k: 'B44',
        v: 'Kilojoule por Mol',
        o: 330
    },
    {
        k: 'B45',
        v: 'KiloMol',
        o: 331
    },
    {
        k: 'B46',
        v: 'KiloMol por metro cúbico',
        o: 332
    },
    {
        k: 'B47',
        v: 'Kilonewton',
        o: 333
    },
    {
        k: 'B48',
        v: 'Kilonewton metro',
        o: 334
    },
    {
        k: 'B49',
        v: 'KiloOhm',
        o: 335
    },
    {
        k: 'B5',
        v: 'palanquilla',
        o: 336
    },
    {
        k: 'B50',
        v: 'KiloOhm metro',
        o: 337
    },
    {
        k: 'B51',
        v: 'KiloLibra',
        o: 338
    },
    {
        k: 'B52',
        v: 'Kilosegundo',
        o: 339
    },
    {
        k: 'B53',
        v: 'Kilosiemens',
        o: 340
    },
    {
        k: 'B54',
        v: 'Kilosiemens por metro',
        o: 341
    },
    {
        k: 'B55',
        v: 'Kilovoltio por metro',
        o: 342
    },
    {
        k: 'B56',
        v: 'Kiloweber por metro',
        o: 343
    },
    {
        k: 'B57',
        v: 'Año luz',
        o: 344
    },
    {
        k: 'B58',
        v: 'Litro por Mol',
        o: 345
    },
    {
        k: 'B59',
        v: 'Lumen hora',
        o: 346
    },
    {
        k: 'B6',
        v: 'Bollo',
        o: 347
    },
    {
        k: 'B60',
        v: 'Lumen por metro cuadrado',
        o: 348
    },
    {
        k: 'B61',
        v: 'Luminosidad por watt',
        o: 349
    },
    {
        k: 'B62',
        v: 'Lumen segundo',
        o: 350
    },
    {
        k: 'B63',
        v: 'Hora de luz',
        o: 351
    },
    {
        k: 'B64',
        v: 'Segundo de luz',
        o: 352
    },
    {
        k: 'B65',
        v: 'Maxwell',
        o: 353
    },
    {
        k: 'B66',
        v: 'Megaamperio por metro cuadrado',
        o: 354
    },
    {
        k: 'B67',
        v: 'Megabecquerel por kilogramo',
        o: 355
    },
    {
        k: 'B68',
        v: 'Gigabit',
        o: 356
    },
    {
        k: 'B69',
        v: 'MegaCulombio por metro cúbico',
        o: 357
    },
    {
        k: 'B7',
        v: 'Ciclo',
        o: 358
    },
    {
        k: 'B70',
        v: 'MegaCulombio por metro cuadrado',
        o: 359
    },
    {
        k: 'B71',
        v: 'Megaelectrónvoltio',
        o: 360
    },
    {
        k: 'B72',
        v: 'Megagramo por metro cúbico',
        o: 361
    },
    {
        k: 'B73',
        v: 'Meganewton',
        o: 362
    },
    {
        k: 'B74',
        v: 'Meganewton metro',
        o: 363
    },
    {
        k: 'B75',
        v: 'MegaOhm',
        o: 364
    },
    {
        k: 'B76',
        v: 'MegaOhm metro',
        o: 365
    },
    {
        k: 'B77',
        v: 'Megasiemens por metro',
        o: 366
    },
    {
        k: 'B78',
        v: 'Megavoltio',
        o: 367
    },
    {
        k: 'B79',
        v: 'Megavoltio por metro',
        o: 368
    },
    {
        k: 'B8',
        v: 'Joule por metro cúbico',
        o: 369
    },
    {
        k: 'B80',
        v: 'Gigabit por segundo',
        o: 370
    },
    {
        k: 'B81',
        v: 'Reciprocidad del metro cuadrado, reciprocidad del segundo',
        o: 371
    },
    {
        k: 'B82',
        v: 'Pulgadas por pie lineal',
        o: 372
    },
    {
        k: 'B83',
        v: 'Metro a la cuarta potencia',
        o: 373
    },
    {
        k: 'B84',
        v: 'Microamperio',
        o: 374
    },
    {
        k: 'B85',
        v: 'Microbar',
        o: 375
    },
    {
        k: 'B86',
        v: 'MicroCulombio',
        o: 376
    },
    {
        k: 'B87',
        v: 'MicroCulombio por metro cúbico',
        o: 377
    },
    {
        k: 'B88',
        v: 'MicroCulombio por metro cuadrado',
        o: 378
    },
    {
        k: 'B89',
        v: 'Microfaradio por metro',
        o: 379
    },
    {
        k: 'B9',
        v: 'Batt',
        o: 380
    },
    {
        k: 'B90',
        v: 'Microhenry',
        o: 381
    },
    {
        k: 'B91',
        v: 'Microhenry por metro',
        o: 382
    },
    {
        k: 'B92',
        v: 'Micronewton',
        o: 383
    },
    {
        k: 'B93',
        v: 'Micronewton metro',
        o: 384
    },
    {
        k: 'B94',
        v: 'Micro Ohm',
        o: 385
    },
    {
        k: 'B95',
        v: 'MicroOhm metro',
        o: 386
    },
    {
        k: 'B96',
        v: 'Micropascal',
        o: 387
    },
    {
        k: 'B97',
        v: 'Microrradián',
        o: 388
    },
    {
        k: 'B98',
        v: 'Microsegundo',
        o: 389
    },
    {
        k: 'B99',
        v: 'Microsiemens',
        o: 390
    },
    {
        k: 'BAR',
        v: 'Bar [unidad de presión]',
        o: 391
    },
    {
        k: 'BFT',
        v: 'Tablero de pies',
        o: 392
    },
    {
        k: 'BH',
        v: 'Cepillo',
        o: 393
    },
    {
        k: 'BHP',
        v: 'Potencia al freno',
        o: 394
    },
    {
        k: 'BIL',
        v: 'Billón',
        o: 395
    },
    {
        k: 'BLD',
        v: 'Barril seco (EUA)',
        o: 396
    },
    {
        k: 'BLL',
        v: 'Barril (EUA)',
        o: 397
    },
    {
        k: 'BP',
        v: 'Tabledo de cien pies',
        o: 398
    },
    {
        k: 'BPM',
        v: 'Latidos por minuto',
        o: 399
    },
    {
        k: 'BQL',
        v: 'Becquerel',
        o: 400
    },
    {
        k: 'BTU',
        v: 'Unidad térmica británica (tabla internacional)',
        o: 401
    },
    {
        k: 'BUA',
        v: 'Bushel (EUA)',
        o: 402
    },
    {
        k: 'BUI',
        v: 'Bushel (UK)',
        o: 403
    },
    {
        k: 'BW',
        v: 'Peso base',
        o: 404
    },
    {
        k: 'BZ',
        v: 'Millones de BTUs',
        o: 405
    },
    {
        k: 'C0',
        v: 'Llamada',
        o: 406
    },
    {
        k: 'C1',
        v: 'Libra de producto compuesto (peso total)',
        o: 407
    },
    {
        k: 'C10',
        v: 'Milifaradio',
        o: 408
    },
    {
        k: 'C11',
        v: 'Miligalón',
        o: 409
    },
    {
        k: 'C12',
        v: 'Miligramo por metro',
        o: 410
    },
    {
        k: 'C13',
        v: 'MilliGray',
        o: 411
    },
    {
        k: 'C14',
        v: 'Milihenry',
        o: 412
    },
    {
        k: 'C15',
        v: 'Milijoule',
        o: 413
    },
    {
        k: 'C16',
        v: 'Milímetro por segundo',
        o: 414
    },
    {
        k: 'C17',
        v: 'Milímetro cuadrado por segundo',
        o: 415
    },
    {
        k: 'C18',
        v: 'MiliMol',
        o: 416
    },
    {
        k: 'C19',
        v: 'Mol por kilogramo',
        o: 417
    },
    {
        k: 'C2',
        v: 'Carset',
        o: 418
    },
    {
        k: 'C20',
        v: 'Milinewton',
        o: 419
    },
    {
        k: 'C21',
        v: 'Kibibit',
        o: 420
    },
    {
        k: 'C22',
        v: 'Milinewton por metro',
        o: 421
    },
    {
        k: 'C23',
        v: 'MiliOhm metro',
        o: 422
    },
    {
        k: 'C24',
        v: 'Milipascal segundo',
        o: 423
    },
    {
        k: 'C25',
        v: 'Milirradián',
        o: 424
    },
    {
        k: 'C26',
        v: 'Milisegundo',
        o: 425
    },
    {
        k: 'C27',
        v: 'Milisiemens',
        o: 426
    },
    {
        k: 'C28',
        v: 'MilliSievert',
        o: 427
    },
    {
        k: 'C29',
        v: 'Militesla',
        o: 428
    },
    {
        k: 'C3',
        v: 'Microvoltios por metro',
        o: 429
    },
    {
        k: 'C30',
        v: 'Milivoltio por metro',
        o: 430
    },
    {
        k: 'C31',
        v: 'Miliwatt',
        o: 431
    },
    {
        k: 'C32',
        v: 'Miliwatt por metro cuadrado',
        o: 432
    },
    {
        k: 'C33',
        v: 'Miliweber',
        o: 433
    },
    {
        k: 'C34',
        v: 'Mol',
        o: 434
    },
    {
        k: 'C35',
        v: 'Mol por decímetro cúbico',
        o: 435
    },
    {
        k: 'C36',
        v: 'Mol por metro cúbico',
        o: 436
    },
    {
        k: 'C37',
        v: 'Kilobits',
        o: 437
    },
    {
        k: 'C38',
        v: 'Mol por litro',
        o: 438
    },
    {
        k: 'C39',
        v: 'Nanoamperio',
        o: 439
    },
    {
        k: 'C4',
        v: 'partido de carga',
        o: 440
    },
    {
        k: 'C40',
        v: 'NanoCulombio',
        o: 441
    },
    {
        k: 'C41',
        v: 'Nanofaradio',
        o: 442
    },
    {
        k: 'C42',
        v: 'Nanofaradio por metro',
        o: 443
    },
    {
        k: 'C43',
        v: 'Nanohenry',
        o: 444
    },
    {
        k: 'C44',
        v: 'Nanohenry por metro',
        o: 445
    },
    {
        k: 'C45',
        v: 'Nanómetro',
        o: 446
    },
    {
        k: 'C46',
        v: 'NanoOhm metro',
        o: 447
    },
    {
        k: 'C47',
        v: 'Nanosegundo',
        o: 448
    },
    {
        k: 'C48',
        v: 'Nanotesla',
        o: 449
    },
    {
        k: 'C49',
        v: 'Nanowatt',
        o: 450
    },
    {
        k: 'C5',
        v: 'Costo',
        o: 451
    },
    {
        k: 'C50',
        v: 'Neperio',
        o: 452
    },
    {
        k: 'C51',
        v: 'Neperio por segundo',
        o: 453
    },
    {
        k: 'C52',
        v: 'Picómetro',
        o: 454
    },
    {
        k: 'C53',
        v: 'Newton metro segundo',
        o: 455
    },
    {
        k: 'C54',
        v: 'Newton metro cuadrado por kilogramo cuadrado',
        o: 456
    },
    {
        k: 'C55',
        v: 'Newton por metro cuadrado',
        o: 457
    },
    {
        k: 'C56',
        v: 'Newton por milímetro cuadrado',
        o: 458
    },
    {
        k: 'C57',
        v: 'Newton segundo',
        o: 459
    },
    {
        k: 'C58',
        v: 'Segundos newton por metro',
        o: 460
    },
    {
        k: 'C59',
        v: 'Octava',
        o: 461
    },
    {
        k: 'C6',
        v: 'celda',
        o: 462
    },
    {
        k: 'C60',
        v: 'Ohm centímetro',
        o: 463
    },
    {
        k: 'C61',
        v: 'Ohm metro',
        o: 464
    },
    {
        k: 'C62',
        v: 'Uno',
        o: 465
    },
    {
        k: 'C63',
        v: 'Pársec',
        o: 466
    },
    {
        k: 'C64',
        v: 'Pascal por kelvin',
        o: 467
    },
    {
        k: 'C65',
        v: 'Pascal segundo',
        o: 468
    },
    {
        k: 'C66',
        v: 'Segundos pascal por metro cúbico',
        o: 469
    },
    {
        k: 'C67',
        v: 'Segundos pascal por metro',
        o: 470
    },
    {
        k: 'C68',
        v: 'Petajoule',
        o: 471
    },
    {
        k: 'C69',
        v: 'Phon',
        o: 472
    },
    {
        k: 'C7',
        v: 'Centipoise',
        o: 473
    },
    {
        k: 'C70',
        v: 'Picoamperio',
        o: 474
    },
    {
        k: 'C71',
        v: 'PicoCulombio',
        o: 475
    },
    {
        k: 'C72',
        v: 'Picofaradio por metro',
        o: 476
    },
    {
        k: 'C73',
        v: 'Picohenry',
        o: 477
    },
    {
        k: 'C74',
        v: 'Kilobits por segundo',
        o: 478
    },
    {
        k: 'C75',
        v: 'Picowatt',
        o: 479
    },
    {
        k: 'C76',
        v: 'Picowatt  por metro cuadrado',
        o: 480
    },
    {
        k: 'C77',
        v: 'Calibre de libra',
        o: 481
    },
    {
        k: 'C78',
        v: 'Libra fuerza',
        o: 482
    },
    {
        k: 'C79',
        v: 'Kilovoltios horas amperios',
        o: 483
    },
    {
        k: 'C8',
        v: 'MilliCulombio por kilogramo',
        o: 484
    },
    {
        k: 'C80',
        v: 'Rad',
        o: 485
    },
    {
        k: 'C81',
        v: 'Radián',
        o: 486
    },
    {
        k: 'C82',
        v: 'Radianmetro cuadrado por Mol',
        o: 487
    },
    {
        k: 'C83',
        v: 'Rradian metro cuadrado por kilogramo',
        o: 488
    },
    {
        k: 'C84',
        v: 'Radían por metro',
        o: 489
    },
    {
        k: 'C85',
        v: 'Reciprocidad Ámstrong',
        o: 490
    },
    {
        k: 'C86',
        v: 'Reciprocidad del metro cúbico',
        o: 491
    },
    {
        k: 'C87',
        v: 'Reciprocidad metro cúbico por segundo',
        o: 492
    },
    {
        k: 'C88',
        v: 'Recíproco joule por metro cúbico',
        o: 493
    },
    {
        k: 'C89',
        v: 'Henry recíproco',
        o: 494
    },
    {
        k: 'C9',
        v: 'Grupo bobinas',
        o: 495
    },
    {
        k: 'C90',
        v: 'Recíproco Henry',
        o: 496
    },
    {
        k: 'C91',
        v: 'Reciprocidad de kelvin ó kelvin a la potencia menos 1',
        o: 497
    },
    {
        k: 'C92',
        v: 'Reciprocidad Metro',
        o: 498
    },
    {
        k: 'C93',
        v: 'Reciprocidad Metro cuadrado',
        o: 499
    },
    {
        k: 'C94',
        v: 'Reciprocidad Minuto',
        o: 500
    },
    {
        k: 'C95',
        v: 'Reciprocidad Mol',
        o: 501
    },
    {
        k: 'C96',
        v: 'Reciprocidad Pascal o pascal a la potencia menos 1',
        o: 502
    },
    {
        k: 'C97',
        v: 'Reciprocidad Segundo',
        o: 503
    },
    {
        k: 'C98',
        v: 'Segundo recíproco por metro cúbico',
        o: 504
    },
    {
        k: 'C99',
        v: 'Reciprocidad Segundo por metro cuadrado',
        o: 505
    },
    {
        k: 'CCT',
        v: 'Capacidad de carga en toneladas métricas',
        o: 506
    },
    {
        k: 'CDL',
        v: 'Candela',
        o: 507
    },
    {
        k: 'CE',
        v: 'Cada mes',
        o: 508
    },
    {
        k: 'CEL',
        v: 'Grados celsius',
        o: 509
    },
    {
        k: 'CEN',
        v: 'Centenar',
        o: 510
    },
    {
        k: 'CG',
        v: 'Tarjeta',
        o: 511
    },
    {
        k: 'CGM',
        v: 'Centigramo',
        o: 512
    },
    {
        k: 'CK',
        v: 'Conector',
        o: 513
    },
    {
        k: 'CKG',
        v: 'Culombio por kilogramo',
        o: 514
    },
    {
        k: 'CLF',
        v: 'Cientos de hojas',
        o: 515
    },
    {
        k: 'CLT',
        v: 'Centilitro',
        o: 516
    },
    {
        k: 'CMK',
        v: 'Centímetro cuadrado',
        o: 517
    },
    {
        k: 'CMQ',
        v: 'Centímetro cúbico',
        o: 518
    },
    {
        k: 'CMT',
        v: 'Centímetro',
        o: 519
    },
    {
        k: 'CNP',
        v: 'Cientos de paquetes',
        o: 520
    },
    {
        k: 'CNT',
        v: 'Cental (UK)',
        o: 521
    },
    {
        k: 'COU',
        v: 'Culombio',
        o: 522
    },
    {
        k: 'CTG',
        v: 'Contenido en gramos',
        o: 523
    },
    {
        k: 'CTM',
        v: 'Quilatage métrico',
        o: 524
    },
    {
        k: 'CTN',
        v: 'Tonelada de contenido (métrica)',
        o: 525
    },
    {
        k: 'CUR',
        v: 'Curie',
        o: 526
    },
    {
        k: 'CWA',
        v: 'Hundred pound',
        o: 527
    },
    {
        k: 'CWI',
        v: 'Hundredweight',
        o: 528
    },
    {
        k: 'CZ',
        v: 'Combo',
        o: 529
    },
    {
        k: 'D03',
        v: 'Kilovatio hora por hora',
        o: 530
    },
    {
        k: 'D04',
        v: 'Lot [unidad de peso]',
        o: 531
    },
    {
        k: 'D1',
        v: 'Segundo recíproco por estereorradián',
        o: 532
    },
    {
        k: 'D10',
        v: 'Siemens por metro',
        o: 533
    },
    {
        k: 'D11',
        v: 'Mebibit',
        o: 534
    },
    {
        k: 'D12',
        v: 'Siemens metro cuadrado por Mol',
        o: 535
    },
    {
        k: 'D13',
        v: 'Sievert',
        o: 536
    },
    {
        k: 'D14',
        v: 'Yarda mil lineal',
        o: 537
    },
    {
        k: 'D15',
        v: 'Sone',
        o: 538
    },
    {
        k: 'D16',
        v: 'Centímetro cuadrado por erg',
        o: 539
    },
    {
        k: 'D17',
        v: 'Centímetro cuadrado por esteroradian erg',
        o: 540
    },
    {
        k: 'D18',
        v: 'Metro kelvin',
        o: 541
    },
    {
        k: 'D19',
        v: 'Metro cuadrado kelvin por watt.',
        o: 542
    },
    {
        k: 'D2',
        v: 'Segundo recíproco por estereorradián metro cuadrado',
        o: 543
    },
    {
        k: 'D20',
        v: 'Metro cuadrado por joule',
        o: 544
    },
    {
        k: 'D21',
        v: 'Metro cuadrado por kilogramo',
        o: 545
    },
    {
        k: 'D22',
        v: 'Metro cuadrado por Mol',
        o: 546
    },
    {
        k: 'D23',
        v: 'Gramo pluma (proteína)',
        o: 547
    },
    {
        k: 'D24',
        v: 'Metro cuadrado por esteroradian',
        o: 548
    },
    {
        k: 'D25',
        v: 'Metro cuadrado por esteroradian joule',
        o: 549
    },
    {
        k: 'D26',
        v: 'Metro cuadrado por voltiosegundo',
        o: 550
    },
    {
        k: 'D27',
        v: 'Estereorradían',
        o: 551
    },
    {
        k: 'D28',
        v: 'sifón',
        o: 552
    },
    {
        k: 'D29',
        v: 'Terahertz',
        o: 553
    },
    {
        k: 'D30',
        v: 'Terajoule',
        o: 554
    },
    {
        k: 'D31',
        v: 'Terawatt',
        o: 555
    },
    {
        k: 'D32',
        v: 'Terawatt hora',
        o: 556
    },
    {
        k: 'D33',
        v: 'Tesla',
        o: 557
    },
    {
        k: 'D34',
        v: 'Tex',
        o: 558
    },
    {
        k: 'D35',
        v: 'Calorías (termoquímicas)',
        o: 559
    },
    {
        k: 'D36',
        v: 'Megabit',
        o: 560
    },
    {
        k: 'D37',
        v: 'Calorías (termoquímicas) por gramo de kelvin',
        o: 561
    },
    {
        k: 'D38',
        v: 'Calorías (termoquímicas) por segundo centímetro kelvin',
        o: 562
    },
    {
        k: 'D39',
        v: 'Calorías (termoquímicas) por segundo centímetro cuadrado kelvin',
        o: 563
    },
    {
        k: 'D40',
        v: 'Mil litros',
        o: 564
    },
    {
        k: 'D41',
        v: 'Tonelada por metro cúbico',
        o: 565
    },
    {
        k: 'D42',
        v: 'Año tropical',
        o: 566
    },
    {
        k: 'D43',
        v: 'Unidad de masa atómica unificada',
        o: 567
    },
    {
        k: 'D44',
        v: 'Var',
        o: 568
    },
    {
        k: 'D45',
        v: 'Voltio cuadrado por kelvin cuadrado',
        o: 569
    },
    {
        k: 'D46',
        v: 'Voltioio-amperio',
        o: 570
    },
    {
        k: 'D47',
        v: 'Voltio por centímetro',
        o: 571
    },
    {
        k: 'D48',
        v: 'Voltio por Kelvin',
        o: 572
    },
    {
        k: 'D49',
        v: 'Millivoltio por Kelvin',
        o: 573
    },
    {
        k: 'D5',
        v: 'Kilogramo por centímetro cuadrado',
        o: 574
    },
    {
        k: 'D50',
        v: 'Voltio por metro',
        o: 575
    },
    {
        k: 'D51',
        v: 'Voltio por milímetro',
        o: 576
    },
    {
        k: 'D52',
        v: 'Watt por kelvin',
        o: 577
    },
    {
        k: 'D53',
        v: 'Watt por metro kelvin',
        o: 578
    },
    {
        k: 'D54',
        v: 'Watt por metro cuadrado',
        o: 579
    },
    {
        k: 'D55',
        v: 'Watt por metro cuadrado kelvin',
        o: 580
    },
    {
        k: 'D56',
        v: 'Watt por metro cuadrado kelvin a la cuarta potencia',
        o: 581
    },
    {
        k: 'D57',
        v: 'Watt por estereorradián',
        o: 582
    },
    {
        k: 'D58',
        v: 'Watt por estereorradián metro cuadrado',
        o: 583
    },
    {
        k: 'D59',
        v: 'Weber por metro',
        o: 584
    },
    {
        k: 'D6',
        v: 'Roentgen por segundo',
        o: 585
    },
    {
        k: 'D60',
        v: 'Weber por milímetro',
        o: 586
    },
    {
        k: 'D61',
        v: 'Minuto [unidad de ángulo]',
        o: 587
    },
    {
        k: 'D62',
        v: 'Segundo [unidad de ángulo]',
        o: 588
    },
    {
        k: 'D63',
        v: 'Libro',
        o: 589
    },
    {
        k: 'D64',
        v: 'bloquear',
        o: 590
    },
    {
        k: 'D65',
        v: 'Redondo',
        o: 591
    },
    {
        k: 'D66',
        v: 'casete',
        o: 592
    },
    {
        k: 'D67',
        v: 'Dólar por hora',
        o: 593
    },
    {
        k: 'D68',
        v: 'Número de palabras',
        o: 594
    },
    {
        k: 'D69',
        v: 'Pulgada a la cuarta potencia',
        o: 595
    },
    {
        k: 'D7',
        v: 'Sandwich',
        o: 596
    },
    {
        k: 'D70',
        v: 'Calorías (tabla internacional)',
        o: 597
    },
    {
        k: 'D71',
        v: 'Calorías (tabla internacional) por segundo centímetro kelvin',
        o: 598
    },
    {
        k: 'D72',
        v: 'Calorías (tabla internacional) por segundo centímetro cuadrado kelvin',
        o: 599
    },
    {
        k: 'D73',
        v: 'Joule metro cuadrado',
        o: 600
    },
    {
        k: 'D74',
        v: 'Kilogramo por Mol',
        o: 601
    },
    {
        k: 'D75',
        v: 'Calorías (tabla internacional) por gramo',
        o: 602
    },
    {
        k: 'D76',
        v: 'Calorías (tabla internacional) por gramo de kelvin',
        o: 603
    },
    {
        k: 'D77',
        v: 'MegaCulombio',
        o: 604
    },
    {
        k: 'D78',
        v: 'Megajoule por segundo',
        o: 605
    },
    {
        k: 'D79',
        v: 'Viga',
        o: 606
    },
    {
        k: 'D8',
        v: 'Draize score',
        o: 607
    },
    {
        k: 'D80',
        v: 'Microwatt',
        o: 608
    },
    {
        k: 'D81',
        v: 'Microtesla',
        o: 609
    },
    {
        k: 'D82',
        v: 'Microvoltio',
        o: 610
    },
    {
        k: 'D83',
        v: 'Milinewton metro',
        o: 611
    },
    {
        k: 'D85',
        v: 'Microwatt por metro cuadrado',
        o: 612
    },
    {
        k: 'D86',
        v: 'MiliCulombio',
        o: 613
    },
    {
        k: 'D87',
        v: 'MiliMol por kilogramo',
        o: 614
    },
    {
        k: 'D88',
        v: 'MiliCulombio por metro cúbico',
        o: 615
    },
    {
        k: 'D89',
        v: 'MiliCulombio por metro cuadrado',
        o: 616
    },
    {
        k: 'D9',
        v: 'Dina por centímetro cuadrado',
        o: 617
    },
    {
        k: 'D90',
        v: 'Metro cúbico (neta)',
        o: 618
    },
    {
        k: 'D91',
        v: 'Rem',
        o: 619
    },
    {
        k: 'D92',
        v: 'banda',
        o: 620
    },
    {
        k: 'D93',
        v: 'Segundo por metro cúbico',
        o: 621
    },
    {
        k: 'D94',
        v: 'Segundo por metro cúbico Radian',
        o: 622
    },
    {
        k: 'D95',
        v: 'Joule por gramo',
        o: 623
    },
    {
        k: 'D96',
        v: 'Libra bruta',
        o: 624
    },
    {
        k: 'D98',
        v: 'Libra masiva',
        o: 625
    },
    {
        k: 'D99',
        v: 'manga',
        o: 626
    },
    {
        k: 'DAA',
        v: 'Decar',
        o: 627
    },
    {
        k: 'DAD',
        v: 'Decena de días',
        o: 628
    },
    {
        k: 'DB',
        v: 'Libra seca',
        o: 629
    },
    {
        k: 'DC',
        v: 'Disco (disco)',
        o: 630
    },
    {
        k: 'DD',
        v: 'Grado [unidad de ángulo]',
        o: 631
    },
    {
        k: 'DE',
        v: 'Acuerdo',
        o: 632
    },
    {
        k: 'DEC',
        v: 'Década',
        o: 633
    },
    {
        k: 'DG',
        v: 'Decigramo',
        o: 634
    },
    {
        k: 'DI',
        v: 'dispensador',
        o: 635
    },
    {
        k: 'DJ',
        v: 'Decagramo',
        o: 636
    },
    {
        k: 'DLT',
        v: 'Decilitro',
        o: 637
    },
    {
        k: 'DMA',
        v: 'Decámetro cúbico',
        o: 638
    },
    {
        k: 'DMK',
        v: 'Decímetro cuadrado',
        o: 639
    },
    {
        k: 'DMO',
        v: 'Kiloliter norma',
        o: 640
    },
    {
        k: 'DMQ',
        v: 'Decímetro cúbico',
        o: 641
    },
    {
        k: 'DMT',
        v: 'Decímetro',
        o: 642
    },
    {
        k: 'DN',
        v: 'Decinewton metro',
        o: 643
    },
    {
        k: 'DPR',
        v: 'Docenas de pares',
        o: 644
    },
    {
        k: 'DPT',
        v: 'Peso de desplazamiento',
        o: 645
    },
    {
        k: 'DQ',
        v: 'registro de datos',
        o: 646
    },
    {
        k: 'DRA',
        v: 'Dram (EUA)',
        o: 647
    },
    {
        k: 'DRI',
        v: 'Dram (UK)',
        o: 648
    },
    {
        k: 'DRL',
        v: 'Docena de rodillos',
        o: 649
    },
    {
        k: 'DRM',
        v: 'Drachm (UK)',
        o: 650
    },
    {
        k: 'DS',
        v: 'monitor',
        o: 651
    },
    {
        k: 'DT',
        v: 'Tonelada seca',
        o: 652
    },
    {
        k: 'DTN',
        v: 'Decitonelada métrica',
        o: 653
    },
    {
        k: 'DU',
        v: 'dina',
        o: 654
    },
    {
        k: 'DWT',
        v: 'Pennyweight',
        o: 655
    },
    {
        k: 'Dx',
        v: 'Dina por centímetro',
        o: 656
    },
    {
        k: 'DY',
        v: 'Libro de directorio',
        o: 657
    },
    {
        k: 'DZN',
        v: 'Docena',
        o: 658
    },
    {
        k: 'DZP',
        v: 'Docena de paquete',
        o: 659
    },
    {
        k: 'E01',
        v: 'Newton por centímetro cuadrado',
        o: 660
    },
    {
        k: 'E07',
        v: 'Megawatt hora por hora',
        o: 661
    },
    {
        k: 'E08',
        v: 'Megavatios por hertz',
        o: 662
    },
    {
        k: 'E09',
        v: 'Miliamperio hora',
        o: 663
    },
    {
        k: 'E10',
        v: 'Día de grado',
        o: 664
    },
    {
        k: 'E11',
        v: 'Gigacalorie',
        o: 665
    },
    {
        k: 'E12',
        v: 'Mille',
        o: 666
    },
    {
        k: 'E14',
        v: 'Kilocaloría (tabla internacional)',
        o: 667
    },
    {
        k: 'E15',
        v: 'Kilocaloría (termoquímica) por hora',
        o: 668
    },
    {
        k: 'E16',
        v: 'Millón de btu (ti) por hora',
        o: 669
    },
    {
        k: 'E17',
        v: 'Pie cúbico por segundo',
        o: 670
    },
    {
        k: 'E18',
        v: 'Tonelada por hora',
        o: 671
    },
    {
        k: 'E19',
        v: 'Ping',
        o: 672
    },
    {
        k: 'E20',
        v: 'Megabit por segundo',
        o: 673
    },
    {
        k: 'E21',
        v: 'Shares',
        o: 674
    },
    {
        k: 'E22',
        v: 'Tue',
        o: 675
    },
    {
        k: 'E23',
        v: 'Neumático',
        o: 676
    },
    {
        k: 'E25',
        v: 'Unidad activa',
        o: 677
    },
    {
        k: 'E27',
        v: 'Dosis',
        o: 678
    },
    {
        k: 'E28',
        v: 'Tonelada seca de aire',
        o: 679
    },
    {
        k: 'E3',
        v: 'remolque',
        o: 680
    },
    {
        k: 'E30',
        v: 'Hebra',
        o: 681
    },
    {
        k: 'E31',
        v: 'Metro cuadrado por litro',
        o: 682
    },
    {
        k: 'E32',
        v: 'Litros por hora',
        o: 683
    },
    {
        k: 'E33',
        v: 'Por mil pies',
        o: 684
    },
    {
        k: 'E34',
        v: 'Gigabyte',
        o: 685
    },
    {
        k: 'E35',
        v: 'Terabyte',
        o: 686
    },
    {
        k: 'E36',
        v: 'Petabyte',
        o: 687
    },
    {
        k: 'E37',
        v: 'Pixel',
        o: 688
    },
    {
        k: 'E38',
        v: 'Megapíxeles',
        o: 689
    },
    {
        k: 'E39',
        v: 'Puntos por pulgada',
        o: 690
    },
    {
        k: 'E4',
        v: 'Kilo bruto',
        o: 691
    },
    {
        k: 'E40',
        v: 'Parte por cien mil',
        o: 692
    },
    {
        k: 'E41',
        v: 'Kilogramo-fuerza por milímetro cuadrado',
        o: 693
    },
    {
        k: 'E42',
        v: 'Kilogramo-fuerza por centímetro cuadrado',
        o: 694
    },
    {
        k: 'E43',
        v: 'Joule por centímetro cuadrado',
        o: 695
    },
    {
        k: 'E44',
        v: 'Metros kilogramo-fuerza por centímetro cuadrado',
        o: 696
    },
    {
        k: 'E45',
        v: 'MiliOhm',
        o: 697
    },
    {
        k: 'E46',
        v: 'Kilovatio hora por metro cúbico',
        o: 698
    },
    {
        k: 'E47',
        v: 'Kilovatio hora por kelvin',
        o: 699
    },
    {
        k: 'E49',
        v: 'Día de trabajo',
        o: 700
    },
    {
        k: 'E5',
        v: 'Tonelada métrica larga',
        o: 701
    },
    {
        k: 'E50',
        v: 'Unidad de cuenta',
        o: 702
    },
    {
        k: 'E52',
        v: 'Run foot',
        o: 703
    },
    {
        k: 'E53',
        v: 'Prueba',
        o: 704
    },
    {
        k: 'E55',
        v: 'Utilizar',
        o: 705
    },
    {
        k: 'E56',
        v: 'Bien',
        o: 706
    },
    {
        k: 'E57',
        v: 'Zona',
        o: 707
    },
    {
        k: 'E58',
        v: 'Exabit por segundo',
        o: 708
    },
    {
        k: 'E59',
        v: 'Exbibyte',
        o: 709
    },
    {
        k: 'E60',
        v: 'Pebibyte',
        o: 710
    },
    {
        k: 'E61',
        v: 'Tebibyte',
        o: 711
    },
    {
        k: 'E62',
        v: 'Gibibyte',
        o: 712
    },
    {
        k: 'E63',
        v: 'Mebibyte',
        o: 713
    },
    {
        k: 'E64',
        v: 'Kibibyte',
        o: 714
    },
    {
        k: 'E65',
        v: 'Exbibit por metro',
        o: 715
    },
    {
        k: 'E66',
        v: 'Exbibit por metro cuadrado',
        o: 716
    },
    {
        k: 'E67',
        v: 'Exbibit por metro cúbico',
        o: 717
    },
    {
        k: 'E68',
        v: 'Gigabyte por segundo',
        o: 718
    },
    {
        k: 'E69',
        v: 'Gibibit por metro',
        o: 719
    },
    {
        k: 'E70',
        v: 'Gibibit por metro cuadrado',
        o: 720
    },
    {
        k: 'E71',
        v: 'Gibibit por metro cúbico',
        o: 721
    },
    {
        k: 'E72',
        v: 'Kibibit por metro',
        o: 722
    },
    {
        k: 'E73',
        v: 'Kibibit por metro cuadrado',
        o: 723
    },
    {
        k: 'E74',
        v: 'Kikibit por metro cúbico.',
        o: 724
    },
    {
        k: 'E75',
        v: 'Mebbit por metro.',
        o: 725
    },
    {
        k: 'E76',
        v: 'Mebbitt por metro cuadrado.',
        o: 726
    },
    {
        k: 'E77',
        v: 'Mebbit por metro cúbico.',
        o: 727
    },
    {
        k: 'E78',
        v: 'Petabit',
        o: 728
    },
    {
        k: 'E79',
        v: 'Pebibit por segundo.',
        o: 729
    },
    {
        k: 'E80',
        v: 'Pebibit por metro.',
        o: 730
    },
    {
        k: 'E81',
        v: 'Pebibit por metro cuadrado.',
        o: 731
    },
    {
        k: 'E82',
        v: 'Pebibit por metro cúbico.',
        o: 732
    },
    {
        k: 'E83',
        v: 'Tebibit.',
        o: 733
    },
    {
        k: 'E84',
        v: 'Tebibit por segundo',
        o: 734
    },
    {
        k: 'E85',
        v: 'Tebibit por metro.',
        o: 735
    },
    {
        k: 'E86',
        v: 'Tebibit por metro cúbico.',
        o: 736
    },
    {
        k: 'E87',
        v: 'Tebibit por metro cuadrado',
        o: 737
    },
    {
        k: 'E88',
        v: 'Bit por metro',
        o: 738
    },
    {
        k: 'E89',
        v: 'Bit por metro cuadrado',
        o: 739
    },
    {
        k: 'E90',
        v: 'Centímetro recíproco',
        o: 740
    },
    {
        k: 'E91',
        v: 'Día recíproco',
        o: 741
    },
    {
        k: 'E92',
        v: 'Decímetro cúbico por hora',
        o: 742
    },
    {
        k: 'E93',
        v: 'Kilogramo por hora',
        o: 743
    },
    {
        k: 'E94',
        v: 'KiloMol por segundo',
        o: 744
    },
    {
        k: 'E95',
        v: 'Mol por segundo',
        o: 745
    },
    {
        k: 'E96',
        v: 'Grado por segundo',
        o: 746
    },
    {
        k: 'E97',
        v: 'Mililitro por gadro celsius metro',
        o: 747
    },
    {
        k: 'E98',
        v: 'Grado celsius por kelvin',
        o: 748
    },
    {
        k: 'E99',
        v: 'Hectopascal por bar',
        o: 749
    },
    {
        k: 'EB',
        v: 'Casilla de correo electrónico',
        o: 750
    },
    {
        k: 'EP',
        v: 'Paquete de once',
        o: 751
    },
    {
        k: 'EQ',
        v: 'Galón equivalente',
        o: 752
    },
    {
        k: 'F01',
        v: 'Bit por metro cúbico',
        o: 753
    },
    {
        k: 'F02',
        v: 'Kelvin por kelvin',
        o: 754
    },
    {
        k: 'F03',
        v: 'Kilopascal por bar',
        o: 755
    },
    {
        k: 'F04',
        v: 'Milibar por bar',
        o: 756
    },
    {
        k: 'F05',
        v: 'Megapascal por bar',
        o: 757
    },
    {
        k: 'F06',
        v: 'Poise por bar',
        o: 758
    },
    {
        k: 'F07',
        v: 'Pascal por bar',
        o: 759
    },
    {
        k: 'F08',
        v: 'Miliamperio por pulgada',
        o: 760
    },
    {
        k: 'F1',
        v: 'Mil pies cúbicos por día',
        o: 761
    },
    {
        k: 'F10',
        v: 'Kelvin por hora',
        o: 762
    },
    {
        k: 'F11',
        v: 'Kelvin por minuto',
        o: 763
    },
    {
        k: 'F12',
        v: 'Kelvin por segundo',
        o: 764
    },
    {
        k: 'F13',
        v: 'Slug',
        o: 765
    },
    {
        k: 'F14',
        v: 'Gramo por kelvin',
        o: 766
    },
    {
        k: 'F15',
        v: 'Kilogramo por kelvin',
        o: 767
    },
    {
        k: 'F16',
        v: 'Miligramo por kelvin',
        o: 768
    },
    {
        k: 'F17',
        v: 'Libra fuerza por pie',
        o: 769
    },
    {
        k: 'F18',
        v: 'Kilogramo centímetro cuadrado',
        o: 770
    },
    {
        k: 'F19',
        v: 'Kilogramo milimetro cuadrado',
        o: 771
    },
    {
        k: 'F20',
        v: 'Libra pulgada cuadrada',
        o: 772
    },
    {
        k: 'F21',
        v: 'Libra fuerza pulgada',
        o: 773
    },
    {
        k: 'F22',
        v: 'Libra fuerza por pie entre amperio',
        o: 774
    },
    {
        k: 'F23',
        v: 'Gramo por decímetro cúbico',
        o: 775
    },
    {
        k: 'F24',
        v: 'Kilogramo por kiloMol',
        o: 776
    },
    {
        k: 'F25',
        v: 'Gramo por hertz',
        o: 777
    },
    {
        k: 'F26',
        v: 'Gramo por día',
        o: 778
    },
    {
        k: 'F27',
        v: 'Gramo por hora',
        o: 779
    },
    {
        k: 'F28',
        v: 'Gramo por minuto',
        o: 780
    },
    {
        k: 'F29',
        v: 'Gramo por segundo',
        o: 781
    },
    {
        k: 'F30',
        v: 'Kilogramo por día',
        o: 782
    },
    {
        k: 'F31',
        v: 'Kilogramo por minuto',
        o: 783
    },
    {
        k: 'F32',
        v: 'Miligramo por dia',
        o: 784
    },
    {
        k: 'F33',
        v: 'Miligramo por minuto',
        o: 785
    },
    {
        k: 'F34',
        v: 'Miligramo por segundo',
        o: 786
    },
    {
        k: 'F35',
        v: 'Gramo por día kelvin',
        o: 787
    },
    {
        k: 'F36',
        v: 'Gramo por hora kelvin',
        o: 788
    },
    {
        k: 'F37',
        v: 'Gramo por minuto kelvin',
        o: 789
    },
    {
        k: 'F38',
        v: 'Gramo por segundo kelvin',
        o: 790
    },
    {
        k: 'F39',
        v: 'Kilogramo por día kelvin',
        o: 791
    },
    {
        k: 'F40',
        v: 'Kilogramo por hora kelvin',
        o: 792
    },
    {
        k: 'F41',
        v: 'Kilogramo por minuto kelvin',
        o: 793
    },
    {
        k: 'F42',
        v: 'Kilogramo por segundo kelvin',
        o: 794
    },
    {
        k: 'F43',
        v: 'Miligramo por día kelvin',
        o: 795
    },
    {
        k: 'F44',
        v: 'Miligramo por hora kelvin',
        o: 796
    },
    {
        k: 'F45',
        v: 'Miligramo por minuto kelvin',
        o: 797
    },
    {
        k: 'F46',
        v: 'Miligramo por segundo kelvin',
        o: 798
    },
    {
        k: 'F47',
        v: 'Newton por milímetro',
        o: 799
    },
    {
        k: 'F48',
        v: 'Libra fuerza por pulgada',
        o: 800
    },
    {
        k: 'F49',
        v: 'Rod (Unidad de distancia)',
        o: 801
    },
    {
        k: 'F50',
        v: 'Micrómetro por kelvin',
        o: 802
    },
    {
        k: 'F51',
        v: 'Centímetro por kelvin',
        o: 803
    },
    {
        k: 'F52',
        v: 'Metro por kelvin',
        o: 804
    },
    {
        k: 'F53',
        v: 'Mililitro por kelvin',
        o: 805
    },
    {
        k: 'F54',
        v: 'MiliOhm por metro',
        o: 806
    },
    {
        k: 'F55',
        v: 'Ohm por milla (milla estatal)',
        o: 807
    },
    {
        k: 'F56',
        v: 'Ohm por kilómetro',
        o: 808
    },
    {
        k: 'F57',
        v: 'Miliamperio por libra-fuerza por pulgada cuadrada',
        o: 809
    },
    {
        k: 'F58',
        v: 'Bar recíproco',
        o: 810
    },
    {
        k: 'F59',
        v: 'Miliamperio por bar',
        o: 811
    },
    {
        k: 'F60',
        v: 'Grado celsius por bar',
        o: 812
    },
    {
        k: 'F61',
        v: 'Kelvin por bar',
        o: 813
    },
    {
        k: 'F62',
        v: 'Gramo por día bar',
        o: 814
    },
    {
        k: 'F63',
        v: 'Gramo por hora bar',
        o: 815
    },
    {
        k: 'F64',
        v: 'Gramo por minuto bar',
        o: 816
    },
    {
        k: 'F65',
        v: 'Gramo por segundo bar',
        o: 817
    },
    {
        k: 'F66',
        v: 'Kililogramo por día bar',
        o: 818
    },
    {
        k: 'F67',
        v: 'Kilogramo por hora bar',
        o: 819
    },
    {
        k: 'F68',
        v: 'Kilogramo por minuto bar',
        o: 820
    },
    {
        k: 'F69',
        v: 'Kilogramo por segundo bar',
        o: 821
    },
    {
        k: 'F70',
        v: 'Miligramo por día bar',
        o: 822
    },
    {
        k: 'F71',
        v: 'Miligramo por hora bar',
        o: 823
    },
    {
        k: 'F72',
        v: 'Miligramo por minuto bar',
        o: 824
    },
    {
        k: 'F73',
        v: 'Miligramo por segundo bar',
        o: 825
    },
    {
        k: 'F74',
        v: 'Gramo por bar',
        o: 826
    },
    {
        k: 'F75',
        v: 'Miligramo por bar',
        o: 827
    },
    {
        k: 'F76',
        v: 'Miliamperio por milímetro',
        o: 828
    },
    {
        k: 'F77',
        v: 'Pascal segundo por kelvin',
        o: 829
    },
    {
        k: 'F78',
        v: 'Pulgada de agua',
        o: 830
    },
    {
        k: 'F79',
        v: 'Pulgada de mercurio',
        o: 831
    },
    {
        k: 'F80',
        v: 'Caballos de fuerza de agua',
        o: 832
    },
    {
        k: 'F81',
        v: 'Bar por kelvin',
        o: 833
    },
    {
        k: 'F82',
        v: 'Hectopascal por kelvin',
        o: 834
    },
    {
        k: 'F83',
        v: 'Kilopascal por kelvin',
        o: 835
    },
    {
        k: 'F84',
        v: 'Milibar por kelvin',
        o: 836
    },
    {
        k: 'F85',
        v: 'Megapascal por kelvin',
        o: 837
    },
    {
        k: 'F86',
        v: 'Poise por kelvin',
        o: 838
    },
    {
        k: 'F87',
        v: 'Voltio por litro minuto',
        o: 839
    },
    {
        k: 'F88',
        v: 'Newton centímetro',
        o: 840
    },
    {
        k: 'F89',
        v: 'Newton metro por grados',
        o: 841
    },
    {
        k: 'F9',
        v: 'Fibra por centímetro cúbico de aire',
        o: 842
    },
    {
        k: 'F90',
        v: 'Newton metro por amperio',
        o: 843
    },
    {
        k: 'F91',
        v: 'Bar litro por segundo',
        o: 844
    },
    {
        k: 'F92',
        v: 'Bar metro cúbico por segundo',
        o: 845
    },
    {
        k: 'F93',
        v: 'Hectopascal litro por segundo',
        o: 846
    },
    {
        k: 'F94',
        v: 'Hectopascal metro cúbico por segundo',
        o: 847
    },
    {
        k: 'F95',
        v: 'Milibar litro por segundo',
        o: 848
    },
    {
        k: 'F96',
        v: 'Milibar metro cúbico por segundo',
        o: 849
    },
    {
        k: 'F97',
        v: 'Megapascal litro por segundo',
        o: 850
    },
    {
        k: 'F98',
        v: 'Megapascal metro cúbico por segundo',
        o: 851
    },
    {
        k: 'F99',
        v: 'Pascal litro por segundo',
        o: 852
    },
    {
        k: 'FAH',
        v: 'Grado fahrenheit',
        o: 853
    },
    {
        k: 'FAR',
        v: 'Farad',
        o: 854
    },
    {
        k: 'FB',
        v: 'campo',
        o: 855
    },
    {
        k: 'FBM',
        v: 'Medidor de fibra',
        o: 856
    },
    {
        k: 'FC',
        v: 'Mil pies cúbicos',
        o: 857
    },
    {
        k: 'FD',
        v: 'Millones de partículas por pie cúbico',
        o: 858
    },
    {
        k: 'FE',
        v: 'Pie de pista',
        o: 859
    },
    {
        k: 'FF',
        v: 'Cien metros cúbicos',
        o: 860
    },
    {
        k: 'FG',
        v: 'Parche transdérmico',
        o: 861
    },
    {
        k: 'FH',
        v: 'MicroMol',
        o: 862
    },
    {
        k: 'FIT',
        v: 'Fallas en el tiempo',
        o: 863
    },
    {
        k: 'FL',
        v: 'Flake ton',
        o: 864
    },
    {
        k: 'FM',
        v: 'Millones de pies cúbicos',
        o: 865
    },
    {
        k: 'FOT',
        v: 'Pie',
        o: 866
    },
    {
        k: 'FP',
        v: 'Libra por pie cuadrado',
        o: 867
    },
    {
        k: 'FR',
        v: 'Pie por minuto',
        o: 868
    },
    {
        k: 'FS',
        v: 'Pie por segundo',
        o: 869
    },
    {
        k: 'FTK',
        v: 'Pie cuadrado',
        o: 870
    },
    {
        k: 'FTQ',
        v: 'Pie cúbico',
        o: 871
    },
    {
        k: 'G01',
        v: 'Pascal metro cúbico por segundo',
        o: 872
    },
    {
        k: 'G04',
        v: 'Centímetro por bar',
        o: 873
    },
    {
        k: 'G05',
        v: 'Metro por bar',
        o: 874
    },
    {
        k: 'G06',
        v: 'Milímetro por bar',
        o: 875
    },
    {
        k: 'G08',
        v: 'Pulgada cuadrada por segundo',
        o: 876
    },
    {
        k: 'G09',
        v: 'Metro cuadrado por segundo kelvin',
        o: 877
    },
    {
        k: 'G10',
        v: 'Stokes por kelvin',
        o: 878
    },
    {
        k: 'G11',
        v: 'Gramo por centímetro cúbico bar',
        o: 879
    },
    {
        k: 'G12',
        v: 'Gramo por decímetro cúbico bar',
        o: 880
    },
    {
        k: 'G13',
        v: 'Gramo por litro bar',
        o: 881
    },
    {
        k: 'G14',
        v: 'Gramo por metro cúbico bar',
        o: 882
    },
    {
        k: 'G15',
        v: 'Gramo por mililitro bar',
        o: 883
    },
    {
        k: 'G16',
        v: 'Kilogramo por centímetro cúbico bar',
        o: 884
    },
    {
        k: 'G17',
        v: 'Kilogramo por litro bar',
        o: 885
    },
    {
        k: 'G18',
        v: 'Kilogramo por metro cúbico bar',
        o: 886
    },
    {
        k: 'G19',
        v: 'Newton metro por kilogramo',
        o: 887
    },
    {
        k: 'G2',
        v: 'Galón (EUA) por minuto',
        o: 888
    },
    {
        k: 'G20',
        v: 'Libra-fuerza pie por libra',
        o: 889
    },
    {
        k: 'G21',
        v: 'Taza (unidad de volumen)',
        o: 890
    },
    {
        k: 'G23',
        v: 'Peck',
        o: 891
    },
    {
        k: 'G24',
        v: 'Cucharada (estados unidos)',
        o: 892
    },
    {
        k: 'G25',
        v: 'Cucharilla (estados unidos)',
        o: 893
    },
    {
        k: 'G26',
        v: 'Estere',
        o: 894
    },
    {
        k: 'G27',
        v: 'Centímetro cúbico por kelvin',
        o: 895
    },
    {
        k: 'G28',
        v: 'Litro por kelvin',
        o: 896
    },
    {
        k: 'G29',
        v: 'Metro cúbico por kelvin',
        o: 897
    },
    {
        k: 'G3',
        v: 'Galón (RU) por minuto',
        o: 898
    },
    {
        k: 'G30',
        v: 'Mililitro por klevin',
        o: 899
    },
    {
        k: 'G31',
        v: 'Kilogramo por centímetro cúbico',
        o: 900
    },
    {
        k: 'G32',
        v: 'Onza (avoirdupois) por yarda cúbica',
        o: 901
    },
    {
        k: 'G33',
        v: 'Gramo por centímetro cúbico kelvin',
        o: 902
    },
    {
        k: 'G34',
        v: 'Gramo por decímetro cúbico kelvin',
        o: 903
    },
    {
        k: 'G35',
        v: 'Gramo por litro kelvin',
        o: 904
    },
    {
        k: 'G36',
        v: 'Gramo por metro cúbico kelvin',
        o: 905
    },
    {
        k: 'G37',
        v: 'Gramo por mililitro kelvin',
        o: 906
    },
    {
        k: 'G38',
        v: 'Kilogramo por centímetro cúbico kelvin',
        o: 907
    },
    {
        k: 'G39',
        v: 'Kilogramo por litro kelvin',
        o: 908
    },
    {
        k: 'G40',
        v: 'Kilogramo por metro cúbico kelvin',
        o: 909
    },
    {
        k: 'G41',
        v: 'Metro cuadrado por segundo bar',
        o: 910
    },
    {
        k: 'G42',
        v: 'Microsiemens por centímetro',
        o: 911
    },
    {
        k: 'G43',
        v: 'Microsiemens por metro',
        o: 912
    },
    {
        k: 'G44',
        v: 'Nanosiemens por centímetro',
        o: 913
    },
    {
        k: 'G45',
        v: 'Nanosiemens por metro',
        o: 914
    },
    {
        k: 'G46',
        v: 'Stokes por bar',
        o: 915
    },
    {
        k: 'G47',
        v: 'Centímetro cúbico por día',
        o: 916
    },
    {
        k: 'G48',
        v: 'Centímetro cúbico por hora',
        o: 917
    },
    {
        k: 'G49',
        v: 'Centímetro cúbico por minuto',
        o: 918
    },
    {
        k: 'G50',
        v: 'Galón por hora',
        o: 919
    },
    {
        k: 'G51',
        v: 'Litro por segundo',
        o: 920
    },
    {
        k: 'G52',
        v: 'Metro cúbico por día',
        o: 921
    },
    {
        k: 'G53',
        v: 'Metro cúbico por minuto',
        o: 922
    },
    {
        k: 'G54',
        v: 'Mililitro por día',
        o: 923
    },
    {
        k: 'G55',
        v: 'Mililitro por hora',
        o: 924
    },
    {
        k: 'G56',
        v: 'Pulgada cúbica por hora',
        o: 925
    },
    {
        k: 'G57',
        v: 'Pulgada cúbica por minuto',
        o: 926
    },
    {
        k: 'G58',
        v: 'Pulgada cúbica por segundo',
        o: 927
    },
    {
        k: 'G59',
        v: 'Miliamperio por litro minuto',
        o: 928
    },
    {
        k: 'G60',
        v: 'Voltio por bar',
        o: 929
    },
    {
        k: 'G61',
        v: 'Centímetro cúbico por día kelvin',
        o: 930
    },
    {
        k: 'G62',
        v: 'Centímetro cúbico por hora kelvin',
        o: 931
    },
    {
        k: 'G63',
        v: 'Centímetro cúbico por minuto kelvin',
        o: 932
    },
    {
        k: 'G64',
        v: 'Centímetro cúbico por segundo kelvin',
        o: 933
    },
    {
        k: 'G65',
        v: 'Litro por día kelvin',
        o: 934
    },
    {
        k: 'G66',
        v: 'Litro por hora kelvin',
        o: 935
    },
    {
        k: 'G67',
        v: 'Litro por minuto kelvin',
        o: 936
    },
    {
        k: 'G68',
        v: 'Litro por segundo kelvin',
        o: 937
    },
    {
        k: 'G69',
        v: 'Metro cúbico por día kelvin',
        o: 938
    },
    {
        k: 'G7',
        v: 'Hoja de microficha',
        o: 939
    },
    {
        k: 'G70',
        v: 'Metro cúbico por hora kelvin',
        o: 940
    },
    {
        k: 'G71',
        v: 'Metro cúbico por minuto kelvin',
        o: 941
    },
    {
        k: 'G72',
        v: 'Metro cúbico por segundo kelvin',
        o: 942
    },
    {
        k: 'G73',
        v: 'Mililitro por día kelvin',
        o: 943
    },
    {
        k: 'G74',
        v: 'Mililitro por hora kelvin',
        o: 944
    },
    {
        k: 'G75',
        v: 'Mililitro por minuto kelvin',
        o: 945
    },
    {
        k: 'G76',
        v: 'Mililitro por segundo kelvin',
        o: 946
    },
    {
        k: 'G77',
        v: 'Milímetro a la cuarta potencia',
        o: 947
    },
    {
        k: 'G78',
        v: 'Centímetro cúbico por día bar',
        o: 948
    },
    {
        k: 'G79',
        v: 'Centímetro cúbico por hora bar',
        o: 949
    },
    {
        k: 'G80',
        v: 'Centímetro cúbico por minuto bar',
        o: 950
    },
    {
        k: 'G81',
        v: 'Centímetro cúbico por segundo bar',
        o: 951
    },
    {
        k: 'G82',
        v: 'Litro por día bar',
        o: 952
    },
    {
        k: 'G83',
        v: 'Litro por hora bar',
        o: 953
    },
    {
        k: 'G84',
        v: 'Litro por minuto bar',
        o: 954
    },
    {
        k: 'G85',
        v: 'Litro por segundo bar',
        o: 955
    },
    {
        k: 'G86',
        v: 'Metro cúbico por día bar',
        o: 956
    },
    {
        k: 'G87',
        v: 'Metro cúbico por hora bar',
        o: 957
    },
    {
        k: 'G88',
        v: 'Metro cúbico por minuto bar',
        o: 958
    },
    {
        k: 'G89',
        v: 'Metro cúbico por segundo bar',
        o: 959
    },
    {
        k: 'G90',
        v: 'Mililitro por día bar',
        o: 960
    },
    {
        k: 'G91',
        v: 'Mililitro por hora bar',
        o: 961
    },
    {
        k: 'G92',
        v: 'Mililitro por minuto bar',
        o: 962
    },
    {
        k: 'G93',
        v: 'Mililitro por segundo bar',
        o: 963
    },
    {
        k: 'G94',
        v: 'Centímetro cúbico por bar',
        o: 964
    },
    {
        k: 'G95',
        v: 'Litro por bar',
        o: 965
    },
    {
        k: 'G96',
        v: 'Metro cúbico por bar',
        o: 966
    },
    {
        k: 'G97',
        v: 'Mililitro por bar',
        o: 967
    },
    {
        k: 'G98',
        v: 'Micro henry por kiloOhm',
        o: 968
    },
    {
        k: 'G99',
        v: 'Micro henry por Ohm',
        o: 969
    },
    {
        k: 'GB',
        v: 'Galón (EUA) por día',
        o: 970
    },
    {
        k: 'GBQ',
        v: 'Gigabecquerel',
        o: 971
    },
    {
        k: 'GC',
        v: 'Gramo por 100 gramos',
        o: 972
    },
    {
        k: 'Gd',
        v: 'Barril bruto',
        o: 973
    },
    {
        k: 'GDW',
        v: 'Gramo, peso seco',
        o: 974
    },
    {
        k: 'GE',
        v: 'Libra por galón (EUA)',
        o: 975
    },
    {
        k: 'GF',
        v: 'Gramo por metro (gramo por 100 centímetros)',
        o: 976
    },
    {
        k: 'GFI',
        v: 'Gramo de isótopo fisible',
        o: 977
    },
    {
        k: 'GGR',
        v: 'Grandioso',
        o: 978
    },
    {
        k: 'GH',
        v: 'Medio galón (US)',
        o: 979
    },
    {
        k: 'GIA',
        v: 'Gill (us)',
        o: 980
    },
    {
        k: 'GIC',
        v: 'Gramo, incluido el contenedor',
        o: 981
    },
    {
        k: 'GII',
        v: 'Gill (uk)',
        o: 982
    },
    {
        k: 'GIP',
        v: 'Grama, incluido el embalaje interior',
        o: 983
    },
    {
        k: 'GJ',
        v: 'Gramo por mililitro',
        o: 984
    },
    {
        k: 'GK',
        v: 'Gramo por kilogramo',
        o: 985
    },
    {
        k: 'GL',
        v: 'Gramo por litro',
        o: 986
    },
    {
        k: 'GLD',
        v: 'Galón seco (EUA)',
        o: 987
    },
    {
        k: 'GLI',
        v: 'Galón (UK)',
        o: 988
    },
    {
        k: 'GLL',
        v: 'Galón (EUA)',
        o: 989
    },
    {
        k: 'GM',
        v: 'Gramo por metro cuadrado',
        o: 990
    },
    {
        k: 'GN',
        v: 'Galón bruto',
        o: 991
    },
    {
        k: 'GO',
        v: 'Miligramo por metro cuadrado',
        o: 992
    },
    {
        k: 'GP',
        v: 'Miligramo por metro cúbico',
        o: 993
    },
    {
        k: 'GQ',
        v: 'Microgramo por metro cúbico',
        o: 994
    },
    {
        k: 'GRN',
        v: 'Grano',
        o: 995
    },
    {
        k: 'GRO',
        v: 'Gross',
        o: 996
    },
    {
        k: 'GT',
        v: 'Tonelada bruta',
        o: 997
    },
    {
        k: 'GV',
        v: 'Gigajoule',
        o: 998
    },
    {
        k: 'GW',
        v: 'Galón por mil pies cúbicos',
        o: 999
    },
    {
        k: 'GWH',
        v: 'Gigawatt hora',
        o: 1000
    },
    {
        k: 'GY',
        v: 'Patio grueso',
        o: 1001
    },
    {
        k: 'GZ',
        v: 'Sistema de calibración',
        o: 1002
    },
    {
        k: 'H03',
        v: 'Henry por kiloOhm',
        o: 1003
    },
    {
        k: 'H04',
        v: 'Henry por Ohm',
        o: 1004
    },
    {
        k: 'H05',
        v: 'Milihenry por kiloOhm',
        o: 1005
    },
    {
        k: 'H06',
        v: 'Milihenry por Ohm',
        o: 1006
    },
    {
        k: 'H07',
        v: 'Pascal segundo por bar',
        o: 1007
    },
    {
        k: 'H08',
        v: 'Microbequerel',
        o: 1008
    },
    {
        k: 'H09',
        v: 'Año recíproco',
        o: 1009
    },
    {
        k: 'H1',
        v: 'Media página - electrónica',
        o: 1010
    },
    {
        k: 'H10',
        v: 'Hora recíproca',
        o: 1011
    },
    {
        k: 'H11',
        v: 'Mes recíproco',
        o: 1012
    },
    {
        k: 'H12',
        v: 'Grado celsius por hora',
        o: 1013
    },
    {
        k: 'H13',
        v: 'Grado celsius por minuto',
        o: 1014
    },
    {
        k: 'H14',
        v: 'Grado celsius por segundo',
        o: 1015
    },
    {
        k: 'H15',
        v: 'Centímetro cuadrado por gramo',
        o: 1016
    },
    {
        k: 'H16',
        v: 'Decámetro cuadrado',
        o: 1017
    },
    {
        k: 'H18',
        v: 'Hectómetro cuadrado',
        o: 1018
    },
    {
        k: 'H19',
        v: 'Hectómetro cúbico',
        o: 1019
    },
    {
        k: 'H2',
        v: 'Medio litro',
        o: 1020
    },
    {
        k: 'H20',
        v: 'Kilómetro cúbico',
        o: 1021
    },
    {
        k: 'H21',
        v: 'Blanco',
        o: 1022
    },
    {
        k: 'H22',
        v: 'Voltio pulgada cuadrada por libra fuerza',
        o: 1023
    },
    {
        k: 'H23',
        v: 'Voltio por pulgada',
        o: 1024
    },
    {
        k: 'H24',
        v: 'Voltio por microsegundo',
        o: 1025
    },
    {
        k: 'H25',
        v: 'Por ciento por kelvin',
        o: 1026
    },
    {
        k: 'H26',
        v: 'Ohm por metro',
        o: 1027
    },
    {
        k: 'H27',
        v: 'Grado por metro',
        o: 1028
    },
    {
        k: 'H28',
        v: 'Microfaradio por kilómetro',
        o: 1029
    },
    {
        k: 'H29',
        v: 'Microgramo por litro',
        o: 1030
    },
    {
        k: 'H30',
        v: 'Micrómetro cuadrado',
        o: 1031
    },
    {
        k: 'H31',
        v: 'Amperio por kilogramo',
        o: 1032
    },
    {
        k: 'H32',
        v: 'Amperio cuadrado segundo',
        o: 1033
    },
    {
        k: 'H33',
        v: 'Faradio por kilómetro',
        o: 1034
    },
    {
        k: 'H34',
        v: 'Hertz metro',
        o: 1035
    },
    {
        k: 'H35',
        v: 'Kelvin metro por watt',
        o: 1036
    },
    {
        k: 'H36',
        v: 'MegaOhm por kilómetro',
        o: 1037
    },
    {
        k: 'H37',
        v: 'MegaOhm por metro',
        o: 1038
    },
    {
        k: 'H38',
        v: 'Megaamperio',
        o: 1039
    },
    {
        k: 'H39',
        v: 'Megahertz kilómetro',
        o: 1040
    },
    {
        k: 'H40',
        v: 'Newton por amperio',
        o: 1041
    },
    {
        k: 'H41',
        v: 'Newton metro watts elevado a la potencia menos 0.5',
        o: 1042
    },
    {
        k: 'H42',
        v: 'Pascal por metro',
        o: 1043
    },
    {
        k: 'H43',
        v: 'Siemens por centímetro',
        o: 1044
    },
    {
        k: 'H44',
        v: 'TeraOhm',
        o: 1045
    },
    {
        k: 'H45',
        v: 'Voltio segundo por metro',
        o: 1046
    },
    {
        k: 'H46',
        v: 'Voltio por segundo',
        o: 1047
    },
    {
        k: 'H47',
        v: 'Watt por metro cúbico',
        o: 1048
    },
    {
        k: 'H48',
        v: 'Attofarad',
        o: 1049
    },
    {
        k: 'H49',
        v: 'Centímetro por hora',
        o: 1050
    },
    {
        k: 'H50',
        v: 'Reciprocidad del centímetro cúbico',
        o: 1051
    },
    {
        k: 'H51',
        v: 'Decibel per kilometro',
        o: 1052
    },
    {
        k: 'H52',
        v: 'Decibel per metro',
        o: 1053
    },
    {
        k: 'H53',
        v: 'Kilogramo por bar',
        o: 1054
    },
    {
        k: 'H54',
        v: 'Kilogramo por decímetro cúbico kelvin',
        o: 1055
    },
    {
        k: 'H55',
        v: 'Kilogramo por decímetro cúbico bar',
        o: 1056
    },
    {
        k: 'H56',
        v: 'Kilogramo por metro cuadrado segundo',
        o: 1057
    },
    {
        k: 'H57',
        v: 'Pulgada por dos pi por radián',
        o: 1058
    },
    {
        k: 'H58',
        v: 'Metro por voltio segundo',
        o: 1059
    },
    {
        k: 'H59',
        v: 'Metro cuadrado por newton',
        o: 1060
    },
    {
        k: 'H60',
        v: 'Metro cúbico por metro cúbico',
        o: 1061
    },
    {
        k: 'H61',
        v: 'Milisiemens por centímetro',
        o: 1062
    },
    {
        k: 'H62',
        v: 'Milivoltio por minuto',
        o: 1063
    },
    {
        k: 'H63',
        v: 'Miligramo por centímetro cuadrado',
        o: 1064
    },
    {
        k: 'H64',
        v: 'Miligramo por gramo',
        o: 1065
    },
    {
        k: 'H65',
        v: 'Mililitro por metro cúbico',
        o: 1066
    },
    {
        k: 'H66',
        v: 'Milímetro por año',
        o: 1067
    },
    {
        k: 'H67',
        v: 'Milímetro por hora',
        o: 1068
    },
    {
        k: 'H68',
        v: 'MiliMol por gram',
        o: 1069
    },
    {
        k: 'H69',
        v: 'Picopascal por kilometro',
        o: 1070
    },
    {
        k: 'H70',
        v: 'Picosegundo',
        o: 1071
    },
    {
        k: 'H71',
        v: 'Por ciento al mes',
        o: 1072
    },
    {
        k: 'H72',
        v: 'Por ciento por hectobar',
        o: 1073
    },
    {
        k: 'H73',
        v: 'Por ciento por decakelvin',
        o: 1074
    },
    {
        k: 'H74',
        v: 'Watt por metro',
        o: 1075
    },
    {
        k: 'H75',
        v: 'Decapascal',
        o: 1076
    },
    {
        k: 'H76',
        v: 'Gramo por milímetro',
        o: 1077
    },
    {
        k: 'H77',
        v: 'Anchura del módulo',
        o: 1078
    },
    {
        k: 'H78',
        v: 'Centímetro convencional de agua',
        o: 1079
    },
    {
        k: 'H79',
        v: 'Escala francesa',
        o: 1080
    },
    {
        k: 'H80',
        v: 'Unidad de bastidor',
        o: 1081
    },
    {
        k: 'H81',
        v: 'Milímetro por minuto',
        o: 1082
    },
    {
        k: 'H82',
        v: 'Punto grande',
        o: 1083
    },
    {
        k: 'H83',
        v: 'Litro por kilogramo',
        o: 1084
    },
    {
        k: 'H84',
        v: 'Gramos milímetro',
        o: 1085
    },
    {
        k: 'H85',
        v: 'Semana recíproca',
        o: 1086
    },
    {
        k: 'H88',
        v: 'MegaOhm kilómetro',
        o: 1087
    },
    {
        k: 'H89',
        v: 'Por ciento por Ohmio',
        o: 1088
    },
    {
        k: 'H90',
        v: 'Porcentaje por grado',
        o: 1089
    },
    {
        k: 'H91',
        v: 'Por ciento por cada diez mil',
        o: 1090
    },
    {
        k: 'H92',
        v: 'Ciento por cien mil',
        o: 1091
    },
    {
        k: 'H93',
        v: 'Porcentaje por cien',
        o: 1092
    },
    {
        k: 'H94',
        v: 'Por ciento por mil',
        o: 1093
    },
    {
        k: 'H95',
        v: 'Por ciento por voltio',
        o: 1094
    },
    {
        k: 'H96',
        v: 'Tanto por ciento por bar',
        o: 1095
    },
    {
        k: 'H98',
        v: 'Por ciento por pulgada',
        o: 1096
    },
    {
        k: 'H99',
        v: 'Por ciento por metro',
        o: 1097
    },
    {
        k: 'HA',
        v: 'Madeja',
        o: 1098
    },
    {
        k: 'HAR',
        v: 'hectárea',
        o: 1099
    },
    {
        k: 'HBA',
        v: 'Hectobar',
        o: 1100
    },
    {
        k: 'HBX',
        v: 'Ciento de cajas',
        o: 1101
    },
    {
        k: 'HC',
        v: 'Conteo en cientos',
        o: 1102
    },
    {
        k: 'HD',
        v: 'Media docena',
        o: 1103
    },
    {
        k: 'HDW',
        v: 'Cien kilogramos, peso seco',
        o: 1104
    },
    {
        k: 'HE',
        v: 'Centésima de un quilate',
        o: 1105
    },
    {
        k: 'HEA',
        v: 'Cabeza',
        o: 1106
    },
    {
        k: 'HF',
        v: 'Cien pies',
        o: 1107
    },
    {
        k: 'HGM',
        v: 'Hectogramo',
        o: 1108
    },
    {
        k: 'HH',
        v: 'Cien pies cúbicos',
        o: 1109
    },
    {
        k: 'HI',
        v: 'Cien hojas',
        o: 1110
    },
    {
        k: 'HIU',
        v: 'Unidad internacional de cien',
        o: 1111
    },
    {
        k: 'HJ',
        v: 'Potencia métrica',
        o: 1112
    },
    {
        k: 'HK',
        v: 'Cien kilogramos',
        o: 1113
    },
    {
        k: 'HKM',
        v: 'Cien kilogramos, masa neta',
        o: 1114
    },
    {
        k: 'HL',
        v: 'Cien pies (lineal)',
        o: 1115
    },
    {
        k: 'HLT',
        v: 'Hectolitro',
        o: 1116
    },
    {
        k: 'HM',
        v: 'Milla por hora (milla estatal)',
        o: 1117
    },
    {
        k: 'HMQ',
        v: 'Millones de metros cúbicos',
        o: 1118
    },
    {
        k: 'HMT',
        v: 'Hectómetro',
        o: 1119
    },
    {
        k: 'HN',
        v: 'Milímetro convencional de mercurio',
        o: 1120
    },
    {
        k: 'HO',
        v: 'Cien onzas troy',
        o: 1121
    },
    {
        k: 'HP',
        v: 'Milímetro convencional de agua',
        o: 1122
    },
    {
        k: 'HPA',
        v: 'Hectolitro de alcohol puro',
        o: 1123
    },
    {
        k: 'HS',
        v: 'Cien pies cuadrados',
        o: 1124
    },
    {
        k: 'HT',
        v: 'media hora',
        o: 1125
    },
    {
        k: 'HTZ',
        v: 'Hertz',
        o: 1126
    },
    {
        k: 'HY',
        v: 'Cien yardas',
        o: 1127
    },
    {
        k: 'IA',
        v: 'Pulgada libra',
        o: 1128
    },
    {
        k: 'IC',
        v: 'Contar por pulgada',
        o: 1129
    },
    {
        k: 'IE',
        v: 'Personas',
        o: 1130
    },
    {
        k: 'IF',
        v: 'Pulgadas de agua',
        o: 1131
    },
    {
        k: 'II',
        v: 'Columna pulgada',
        o: 1132
    },
    {
        k: 'IM',
        v: 'Impresión',
        o: 1133
    },
    {
        k: 'INH',
        v: 'Pulgada',
        o: 1134
    },
    {
        k: 'INK',
        v: 'Pulgada cuadrada',
        o: 1135
    },
    {
        k: 'INQ',
        v: 'Pulgada cúbica',
        o: 1136
    },
    {
        k: 'IP',
        v: 'póliza de seguros',
        o: 1137
    },
    {
        k: 'ISD',
        v: 'Grado internacional de azúcar',
        o: 1138
    },
    {
        k: 'IT',
        v: 'Recuento por centímetro',
        o: 1139
    },
    {
        k: 'IU',
        v: 'Pulgada por segundo',
        o: 1140
    },
    {
        k: 'IV',
        v: 'Pulgada por segundo al cuadrado',
        o: 1141
    },
    {
        k: 'J10',
        v: 'Por ciento por milímetro',
        o: 1142
    },
    {
        k: 'J12',
        v: 'Por mille por psi',
        o: 1143
    },
    {
        k: 'J13',
        v: 'Grado api',
        o: 1144
    },
    {
        k: 'J14',
        v: 'Grado baume (escala de origen)',
        o: 1145
    },
    {
        k: 'J15',
        v: 'Grado baume (us pesado)',
        o: 1146
    },
    {
        k: 'J16',
        v: 'Grado baume (luz de los EUA)',
        o: 1147
    },
    {
        k: 'J17',
        v: 'Grado balling',
        o: 1148
    },
    {
        k: 'J18',
        v: 'Grado brix',
        o: 1149
    },
    {
        k: 'J19',
        v: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (termoquímico).',
        o: 1150
    },
    {
        k: 'J2',
        v: 'Joule por kilogramo',
        o: 1151
    },
    {
        k: 'J20',
        v: 'Grado fahrenheit por kelvin',
        o: 1152
    },
    {
        k: 'J21',
        v: 'Grado fahrenheit por bar',
        o: 1153
    },
    {
        k: 'J22',
        v: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (tabla internacional).',
        o: 1154
    },
    {
        k: 'J23',
        v: 'Grado fahrenheit por hora',
        o: 1155
    },
    {
        k: 'J24',
        v: 'Grado fahrenheit por minuto',
        o: 1156
    },
    {
        k: 'J25',
        v: 'Grado fahrenheit por segundo',
        o: 1157
    },
    {
        k: 'J26',
        v: 'Reciprocidad grado fahrenheit',
        o: 1158
    },
    {
        k: 'J27',
        v: 'Grado oechsle',
        o: 1159
    },
    {
        k: 'J28',
        v: 'Grado rankine por hora',
        o: 1160
    },
    {
        k: 'J29',
        v: 'Grado rankine por minuto',
        o: 1161
    },
    {
        k: 'J30',
        v: 'Grado rankine por segundo',
        o: 1162
    },
    {
        k: 'J31',
        v: 'Grado twaddel.',
        o: 1163
    },
    {
        k: 'J32',
        v: 'Micropoise',
        o: 1164
    },
    {
        k: 'J33',
        v: 'Microgramo por kilogramo',
        o: 1165
    },
    {
        k: 'J34',
        v: 'Microgramo por metro cúbico kelvin',
        o: 1166
    },
    {
        k: 'J35',
        v: 'Microgramo por metro cúbico bar',
        o: 1167
    },
    {
        k: 'J36',
        v: 'Microlitro por litro',
        o: 1168
    },
    {
        k: 'J38',
        v: 'Baud',
        o: 1169
    },
    {
        k: 'J39',
        v: 'Unidad térmica británica (significado)',
        o: 1170
    },
    {
        k: 'J40',
        v: 'Unidad térmica británica (tabla internacional) pie por hora pie cuadrado grado fahrenheit.',
        o: 1171
    },
    {
        k: 'J41',
        v: 'Unidad térmica británica (tabla internacional) pulgada por hora pie cuadrado grado fahrenheit.',
        o: 1172
    },
    {
        k: 'J42',
        v: 'Unidad térmica británica (tabla internacional) pulgada por segundo pie cuadrado grado fahrenheit.',
        o: 1173
    },
    {
        k: 'J43',
        v: 'Unidad térmica británica (tabla internacional) por libra grado fahrenheit',
        o: 1174
    },
    {
        k: 'J44',
        v: 'Unidad térmica británica (tabla internacional) por minuto',
        o: 1175
    },
    {
        k: 'J45',
        v: 'Unidad térmica británica (tabla internacional) por segundo',
        o: 1176
    },
    {
        k: 'J46',
        v: 'Unidad térmica británica (termoquímico) pie por hora pie cuadrado grado fahrenheit.',
        o: 1177
    },
    {
        k: 'J47',
        v: 'Unidad térmica británica (termoquímica) por hora',
        o: 1178
    },
    {
        k: 'J48',
        v: 'Unidad térmica británica (termoquímico) pulgada por hora pie cuadrado grado fahrenheit.',
        o: 1179
    },
    {
        k: 'J49',
        v: 'Unidad térmica británica (termoquímico) pulgada por segundo pie cuadrado grado fahrenheit.',
        o: 1180
    },
    {
        k: 'J50',
        v: 'Unidad térmica británica (termoquímico) por libra grado fahrenheit',
        o: 1181
    },
    {
        k: 'J51',
        v: 'Unidad térmica británica (termoquímica) por minuto',
        o: 1182
    },
    {
        k: 'J52',
        v: 'Unidad térmica británica (termoquímica) por segundo',
        o: 1183
    },
    {
        k: 'J53',
        v: 'Culombio metro cuadrado por kilogramo',
        o: 1184
    },
    {
        k: 'J54',
        v: 'Megabaud',
        o: 1185
    },
    {
        k: 'J55',
        v: 'Watt segundo',
        o: 1186
    },
    {
        k: 'J56',
        v: 'Bar por bar',
        o: 1187
    },
    {
        k: 'J57',
        v: 'Barril (uk petróleo)',
        o: 1188
    },
    {
        k: 'J58',
        v: 'Barril (petróleo UK) por minuto',
        o: 1189
    },
    {
        k: 'J59',
        v: 'Barril (petróleo UK) por día',
        o: 1190
    },
    {
        k: 'J60',
        v: 'Barril (petróleo UK) por hora',
        o: 1191
    },
    {
        k: 'J61',
        v: 'Barril (petróleo UK) por segundo',
        o: 1192
    },
    {
        k: 'J62',
        v: 'Barril (petróleo estados unidos) por hora',
        o: 1193
    },
    {
        k: 'J63',
        v: 'Barril (petróleo estados unidos) por segundo',
        o: 1194
    },
    {
        k: 'J64',
        v: 'Bushel (UK) por día',
        o: 1195
    },
    {
        k: 'J65',
        v: 'Bushel (UK) por hora',
        o: 1196
    },
    {
        k: 'J66',
        v: 'Bushel (UK) por minuto',
        o: 1197
    },
    {
        k: 'J67',
        v: 'Bushel (UK) por segundo',
        o: 1198
    },
    {
        k: 'J68',
        v: 'Bushel (seco, estados unidos) por día',
        o: 1199
    },
    {
        k: 'J69',
        v: 'Bushel (seco, estados unidos) por hora',
        o: 1200
    },
    {
        k: 'J70',
        v: 'Bushel (seco, estados unidos) por minuto',
        o: 1201
    },
    {
        k: 'J71',
        v: 'Bushel (seco, estados unidos) por segundo',
        o: 1202
    },
    {
        k: 'J72',
        v: 'Centinewton metro',
        o: 1203
    },
    {
        k: 'J73',
        v: 'Centipoise por kelvin',
        o: 1204
    },
    {
        k: 'J74',
        v: 'Centipoise por bar',
        o: 1205
    },
    {
        k: 'J75',
        v: 'Caloría',
        o: 1206
    },
    {
        k: 'J76',
        v: 'Caloría (tabla internacional) por gramo grado celsius',
        o: 1207
    },
    {
        k: 'J78',
        v: 'Caloría (termoquímica) por centímetro segundo grado celsius',
        o: 1208
    },
    {
        k: 'J79',
        v: 'Caloría (termoquímico) por gramo grado celsius',
        o: 1209
    },
    {
        k: 'J81',
        v: 'Caloría (termoquímica) por minuto',
        o: 1210
    },
    {
        k: 'J82',
        v: 'Caloría (termoquímica) por segundo',
        o: 1211
    },
    {
        k: 'J83',
        v: 'Clo',
        o: 1212
    },
    {
        k: 'J84',
        v: 'Centímetro por segundo kelvin',
        o: 1213
    },
    {
        k: 'J85',
        v: 'Centímetro por segundo bar',
        o: 1214
    },
    {
        k: 'J87',
        v: 'Centímetro cúbico por metro cúbico',
        o: 1215
    },
    {
        k: 'J89',
        v: 'Centímetro de mercurio',
        o: 1216
    },
    {
        k: 'J90',
        v: 'Decímetro cúbico por día',
        o: 1217
    },
    {
        k: 'J91',
        v: 'Decímetro cúbico por metro cúbico',
        o: 1218
    },
    {
        k: 'J92',
        v: 'Decímetro cúbico por minuto',
        o: 1219
    },
    {
        k: 'J93',
        v: 'Decímetro cúbico por segundo',
        o: 1220
    },
    {
        k: 'J94',
        v: 'Dina centímetro',
        o: 1221
    },
    {
        k: 'J95',
        v: 'Onza (fluido, UK) por día',
        o: 1222
    },
    {
        k: 'J96',
        v: 'Onza (fluido, UK) por hora',
        o: 1223
    },
    {
        k: 'J97',
        v: 'Onza (fluido, UK) por minuto',
        o: 1224
    },
    {
        k: 'J98',
        v: 'Onza (fluido, UK) por segundo',
        o: 1225
    },
    {
        k: 'J99',
        v: 'Onza (fluido, estados unidos) por día',
        o: 1226
    },
    {
        k: 'JB',
        v: 'Jumbo',
        o: 1227
    },
    {
        k: 'JE',
        v: 'Joule por kelvin',
        o: 1228
    },
    {
        k: 'JK',
        v: 'Megajoule por kilogramo',
        o: 1229
    },
    {
        k: 'JM',
        v: 'Megajoule por metro cúbico',
        o: 1230
    },
    {
        k: 'JNT',
        v: 'Junta de tubería',
        o: 1231
    },
    {
        k: 'Jo',
        v: 'Articulación',
        o: 1232
    },
    {
        k: 'JOU',
        v: 'Joule',
        o: 1233
    },
    {
        k: 'JPS',
        v: 'Cien metros',
        o: 1234
    },
    {
        k: 'JWL',
        v: 'Número de joyas',
        o: 1235
    },
    {
        k: 'K1',
        v: 'Demanda de kilowatt',
        o: 1236
    },
    {
        k: 'K10',
        v: 'Onza (fluido, estados unidos) por hora',
        o: 1237
    },
    {
        k: 'K11',
        v: 'Onza (fluido, estados unidos) por minuto',
        o: 1238
    },
    {
        k: 'K12',
        v: 'Onza (fluido, estados unidos) por segundo',
        o: 1239
    },
    {
        k: 'K13',
        v: 'Pie por grado fahrenheit',
        o: 1240
    },
    {
        k: 'K14',
        v: 'Pie por hora',
        o: 1241
    },
    {
        k: 'K15',
        v: 'Pie libra-fuerza por hora',
        o: 1242
    },
    {
        k: 'K16',
        v: 'Pie libra-fuerza por minuto',
        o: 1243
    },
    {
        k: 'K17',
        v: 'Pie por psi (libra por pulgada cuadrada)',
        o: 1244
    },
    {
        k: 'K18',
        v: 'Pie por segundo grado fahrenheit',
        o: 1245
    },
    {
        k: 'K19',
        v: 'Pie por segundo psi (libra por pulgada cuadrada)',
        o: 1246
    },
    {
        k: 'K2',
        v: 'Kilovoltios amperios demanda reactiva',
        o: 1247
    },
    {
        k: 'K20',
        v: 'Reciprocidad del pie cuadrado',
        o: 1248
    },
    {
        k: 'K21',
        v: 'Pie cúbico por grado fahrenheit',
        o: 1249
    },
    {
        k: 'K22',
        v: 'Pie cúbico por día',
        o: 1250
    },
    {
        k: 'K23',
        v: 'Pie cúbico por psi (libra por pulgada cuadrada)',
        o: 1251
    },
    {
        k: 'K24',
        v: 'Pie de agua',
        o: 1252
    },
    {
        k: 'K25',
        v: 'Pie de mercurio',
        o: 1253
    },
    {
        k: 'K26',
        v: 'Galón (UK) por día',
        o: 1254
    },
    {
        k: 'K27',
        v: 'Galón (UK) por hora',
        o: 1255
    },
    {
        k: 'K28',
        v: 'Galón (UK) por segundo',
        o: 1256
    },
    {
        k: 'K3',
        v: 'Kilovoltio amperio hora reactivo',
        o: 1257
    },
    {
        k: 'K30',
        v: 'Galón (líquido, EUA) por segundo',
        o: 1258
    },
    {
        k: 'K31',
        v: 'Gramo-fuerza por centímetro cuadrado',
        o: 1259
    },
    {
        k: 'K32',
        v: 'Gill (UK) por día',
        o: 1260
    },
    {
        k: 'K33',
        v: 'Gill (UK) por hora',
        o: 1261
    },
    {
        k: 'K34',
        v: 'Gill (UK) por minuto',
        o: 1262
    },
    {
        k: 'K35',
        v: 'Gill (UK) por segundo',
        o: 1263
    },
    {
        k: 'K36',
        v: 'Gill (estados unidos) por día',
        o: 1264
    },
    {
        k: 'K37',
        v: 'Gill (estados unidos) por hora',
        o: 1265
    },
    {
        k: 'K38',
        v: 'Gill (estados unidos) por minuto',
        o: 1266
    },
    {
        k: 'K39',
        v: 'Gill (estados unidos) por segundo',
        o: 1267
    },
    {
        k: 'K40',
        v: 'Aceleración estándar de la caída libre',
        o: 1268
    },
    {
        k: 'K41',
        v: 'Grano por galón (EUA)',
        o: 1269
    },
    {
        k: 'K42',
        v: 'Caballo de fuerza de caldera',
        o: 1270
    },
    {
        k: 'K43',
        v: 'Caballo de fuerza (eléctrico)',
        o: 1271
    },
    {
        k: 'K45',
        v: 'Pulgada por grado fahrenheit',
        o: 1272
    },
    {
        k: 'K46',
        v: 'Pulgada por psi (libra por pulgada cuadrada)',
        o: 1273
    },
    {
        k: 'K47',
        v: 'Pulgada por segundo grado fahrenheit',
        o: 1274
    },
    {
        k: 'K48',
        v: 'Pulgada por segundo psi (libra por pulgada cuadrada)',
        o: 1275
    },
    {
        k: 'K49',
        v: 'Reciprocidad de la pulgada cuadrada',
        o: 1276
    },
    {
        k: 'K5',
        v: 'Kilovoltios amperios (reactivos)',
        o: 1277
    },
    {
        k: 'K50',
        v: 'Kilobaud',
        o: 1278
    },
    {
        k: 'K51',
        v: 'Kilocaloría (significado)',
        o: 1279
    },
    {
        k: 'K52',
        v: 'Kilocaloría (tabla internacional) por hora metro grado celsius',
        o: 1280
    },
    {
        k: 'K53',
        v: 'Kilocaloría (termoquímico)',
        o: 1281
    },
    {
        k: 'K54',
        v: 'Kilocaloría (termoquímica) por minuto',
        o: 1282
    },
    {
        k: 'K55',
        v: 'Kilocaloría (termoquímica) por segundo',
        o: 1283
    },
    {
        k: 'K58',
        v: 'KiloMol por hora',
        o: 1284
    },
    {
        k: 'K59',
        v: 'KiloMol por metro cúbico kelvin',
        o: 1285
    },
    {
        k: 'K6',
        v: 'Kilolitro',
        o: 1286
    },
    {
        k: 'K60',
        v: 'KiloMol por metro cúbico bar',
        o: 1287
    },
    {
        k: 'K61',
        v: 'KiloMol por minuto',
        o: 1288
    },
    {
        k: 'K62',
        v: 'Litro por litro',
        o: 1289
    },
    {
        k: 'K63',
        v: 'Reciprocidad del litro',
        o: 1290
    },
    {
        k: 'K64',
        v: 'Libra (avoirdupois) por grado fahrenheit',
        o: 1291
    },
    {
        k: 'K65',
        v: 'Libra (avoirdupois) pie cuadrado',
        o: 1292
    },
    {
        k: 'K66',
        v: 'Libra (avoirdupois) por día',
        o: 1293
    },
    {
        k: 'K67',
        v: 'Libra por pie hora',
        o: 1294
    },
    {
        k: 'K68',
        v: 'Libra por pie segundo',
        o: 1295
    },
    {
        k: 'K69',
        v: 'Libra (avoirdupois) por pie cúbico grado fahrenheit',
        o: 1296
    },
    {
        k: 'K70',
        v: 'Libra (avoirdupois) por pie cúbico psi',
        o: 1297
    },
    {
        k: 'K71',
        v: 'Libra (avoirdupois) por galón (UK)',
        o: 1298
    },
    {
        k: 'K73',
        v: 'Libra (avoirdupois) por hora grados fahrenheit',
        o: 1299
    },
    {
        k: 'K74',
        v: 'Libra (avoirdupois) por hora libra-fuerza por pulgada cuadrada',
        o: 1300
    },
    {
        k: 'K75',
        v: 'Libra (avoirdupois) por pulgada cúbica grado fahrenheit',
        o: 1301
    },
    {
        k: 'K76',
        v: 'Libra (avoirdupois) por pulgada cúbica psi',
        o: 1302
    },
    {
        k: 'K77',
        v: 'Libra (avoirdupois) por psi',
        o: 1303
    },
    {
        k: 'K78',
        v: 'Libra (avoirdupois) por minuto',
        o: 1304
    },
    {
        k: 'K79',
        v: 'Libra (avoirdupois) por minuto grados fahrenheit',
        o: 1305
    },
    {
        k: 'K80',
        v: 'Libra (avoirdupois) por minuto libra-fuerza por pulgada cuadrada',
        o: 1306
    },
    {
        k: 'K81',
        v: 'Libra (avoirdupois) por segundo',
        o: 1307
    },
    {
        k: 'K82',
        v: 'Libra (avoirdupois)por segundo grados fahrenheit',
        o: 1308
    },
    {
        k: 'K83',
        v: 'Libra (avoirdupois) por segundo libra-fuerza por pulgada cuadrada',
        o: 1309
    },
    {
        k: 'K84',
        v: 'Libra por yarda cúbica',
        o: 1310
    },
    {
        k: 'K85',
        v: 'Libra-fuerza por pie cuadrado',
        o: 1311
    },
    {
        k: 'K86',
        v: 'Libra-fuerza por pulgada cuadrada grados fahrenheit',
        o: 1312
    },
    {
        k: 'K87',
        v: 'Libra-fuerza por pulgada cuadrada pulgada cúbica por segundo',
        o: 1313
    },
    {
        k: 'K88',
        v: 'Libra-fuerza por pulgada cuadrada litro por segundo',
        o: 1314
    },
    {
        k: 'K89',
        v: 'Libra-fuerza por pulgada cuadrada metro cúbico por segundo',
        o: 1315
    },
    {
        k: 'K90',
        v: 'Libra-fuerza por pulgada cuadrada yarda cúbica por segundo',
        o: 1316
    },
    {
        k: 'K91',
        v: 'Libra-fuerza segundo por pie cuadrado',
        o: 1317
    },
    {
        k: 'K92',
        v: 'Libra-fuerza segundo por pulgada cuadrada',
        o: 1318
    },
    {
        k: 'K93',
        v: 'Reciprocidad psi',
        o: 1319
    },
    {
        k: 'K94',
        v: 'Cuarto (líquido, UK) por día',
        o: 1320
    },
    {
        k: 'K95',
        v: 'Cuarto (líquido, UK) por hora',
        o: 1321
    },
    {
        k: 'K96',
        v: 'Cuarto (líquido, UK) por minuto',
        o: 1322
    },
    {
        k: 'K97',
        v: 'Cuarto (líquido, UK) por segundo',
        o: 1323
    },
    {
        k: 'K98',
        v: 'Cuarto (líquido, estados unidos) por día',
        o: 1324
    },
    {
        k: 'K99',
        v: 'Cuarto (líquido, estados unidos) por hora',
        o: 1325
    },
    {
        k: 'KA',
        v: 'Pastel',
        o: 1326
    },
    {
        k: 'KAT',
        v: 'Katal',
        o: 1327
    },
    {
        k: 'KB',
        v: 'Kilocaracter',
        o: 1328
    },
    {
        k: 'KBA',
        v: 'Kilobar',
        o: 1329
    },
    {
        k: 'KCC',
        v: 'Kilogramo de cloruro de colina',
        o: 1330
    },
    {
        k: 'KD',
        v: 'Kilogram decimal',
        o: 1331
    },
    {
        k: 'KDW',
        v: 'Kilogramo de peso neto drenado',
        o: 1332
    },
    {
        k: 'KEL',
        v: 'Kelvin',
        o: 1333
    },
    {
        k: 'KF',
        v: 'Kilopacket',
        o: 1334
    },
    {
        k: 'KGS',
        v: 'Kilogramo por segundo',
        o: 1335
    },
    {
        k: 'KHY',
        v: 'Kilogramo de peróxido de hidrógeno',
        o: 1336
    },
    {
        k: 'KHZ',
        v: 'Kilohertz',
        o: 1337
    },
    {
        k: 'KI',
        v: 'Kilogramo por milímetro de ancho',
        o: 1338
    },
    {
        k: 'KIC',
        v: 'Kilogramo, incluyendo el contenedor',
        o: 1339
    },
    {
        k: 'KIP',
        v: 'Kilogramo, incluyendo el empaquetado interno',
        o: 1340
    },
    {
        k: 'KJ',
        v: 'Kilosegmento',
        o: 1341
    },
    {
        k: 'KJO',
        v: 'Kilojoule',
        o: 1342
    },
    {
        k: 'KL',
        v: 'Kilogramo por metro',
        o: 1343
    },
    {
        k: 'KLK',
        v: 'Porcentaje de material seco láctico',
        o: 1344
    },
    {
        k: 'KLX',
        v: 'Kilolux',
        o: 1345
    },
    {
        k: 'KMA',
        v: 'Kilogramo de metilamina',
        o: 1346
    },
    {
        k: 'KMH',
        v: 'Kilómetro por hora',
        o: 1347
    },
    {
        k: 'KMK',
        v: 'Kilómetro cuadrado',
        o: 1348
    },
    {
        k: 'KMQ',
        v: 'Kilogramo por metro cúbico',
        o: 1349
    },
    {
        k: 'KMT',
        v: 'Kilómetro',
        o: 1350
    },
    {
        k: 'KNI',
        v: 'Kilogramo de nitrógeno',
        o: 1351
    },
    {
        k: 'KNM',
        v: 'Kolonewton por metro cuadrado',
        o: 1352
    },
    {
        k: 'KNS',
        v: 'Kilogramo sustancia nombrada',
        o: 1353
    },
    {
        k: 'KNT',
        v: 'Nudo',
        o: 1354
    },
    {
        k: 'KO',
        v: 'Miliequivalentes de potasa cáustica por gramo de producto',
        o: 1355
    },
    {
        k: 'KPA',
        v: 'Kilopascal',
        o: 1356
    },
    {
        k: 'KPH',
        v: 'Kilogramo de hidróxido de potasio (potasa cáustica)',
        o: 1357
    },
    {
        k: 'KPO',
        v: 'Kilogramo de óxido de potasio',
        o: 1358
    },
    {
        k: 'KPP',
        v: 'Kilogramo de pentóxido de fósforo (anhídrido fosfórico)',
        o: 1359
    },
    {
        k: 'KR',
        v: 'Kiloroentgen',
        o: 1360
    },
    {
        k: 'KS',
        v: 'Mil libras por pulgada cuadrada',
        o: 1361
    },
    {
        k: 'KSD',
        v: 'Kilogramo de sustancia 90% seco',
        o: 1362
    },
    {
        k: 'KSH',
        v: 'Kilogramo de hidróxido de sodio (sodio cáustica)',
        o: 1363
    },
    {
        k: 'KTN',
        v: 'Kilotonelada Métrica',
        o: 1364
    },
    {
        k: 'KUR',
        v: 'Kilogramo de uranio',
        o: 1365
    },
    {
        k: 'KVA',
        v: 'Kilovoltio - amperio',
        o: 1366
    },
    {
        k: 'KVR',
        v: 'Kilovar',
        o: 1367
    },
    {
        k: 'KVT',
        v: 'Kilovoltio',
        o: 1368
    },
    {
        k: 'KW',
        v: 'Kilogramo por milímetro',
        o: 1369
    },
    {
        k: 'KWH',
        v: 'Kilowatt hora',
        o: 1370
    },
    {
        k: 'KWN',
        v: 'Kilowatt hora por metro cúbico normalizado',
        o: 1371
    },
    {
        k: 'KWO',
        v: 'Kilogramo de trióxido de tungsteno',
        o: 1372
    },
    {
        k: 'KWS',
        v: 'Kilowatt hora por metro cúbico estándar',
        o: 1373
    },
    {
        k: 'KWT',
        v: 'Kilowatt',
        o: 1374
    },
    {
        k: 'KX',
        v: 'Mililitro por kilogramo',
        o: 1375
    },
    {
        k: 'L10',
        v: 'Cuarto (líquido, estados unidos) por minuto',
        o: 1376
    },
    {
        k: 'L11',
        v: 'Cuarto (líquido, estados unidos) por segundo',
        o: 1377
    },
    {
        k: 'L12',
        v: 'Metro por segundo kelvin',
        o: 1378
    },
    {
        k: 'L13',
        v: 'Metro por segundo bar',
        o: 1379
    },
    {
        k: 'L14',
        v: 'Metro cuadrado hora grado celsius por kilocaloría (tabla internacional)',
        o: 1380
    },
    {
        k: 'L15',
        v: 'Milipascal segundo por kelvin',
        o: 1381
    },
    {
        k: 'L16',
        v: 'Milipascal segundo por bar',
        o: 1382
    },
    {
        k: 'L17',
        v: 'Miligramo por metro cúbico kelvin',
        o: 1383
    },
    {
        k: 'L18',
        v: 'Miligramo por metro cúbico bar',
        o: 1384
    },
    {
        k: 'L19',
        v: 'Mililitro por litro',
        o: 1385
    },
    {
        k: 'L2',
        v: 'Litro por minuto',
        o: 1386
    },
    {
        k: 'L20',
        v: 'Reciprocidad del milímetro cúbico',
        o: 1387
    },
    {
        k: 'L21',
        v: 'Milímetro cúbico por metro cúbico',
        o: 1388
    },
    {
        k: 'L23',
        v: 'Mol por hora',
        o: 1389
    },
    {
        k: 'L24',
        v: 'Mol por kilogramo kelvin',
        o: 1390
    },
    {
        k: 'L25',
        v: 'Mol por kilogramo bar',
        o: 1391
    },
    {
        k: 'L26',
        v: 'Mol por litro kelvin',
        o: 1392
    },
    {
        k: 'L27',
        v: 'Mol por litro bar',
        o: 1393
    },
    {
        k: 'L28',
        v: 'Mol por metro cúbico kelvin',
        o: 1394
    },
    {
        k: 'L29',
        v: 'Mol por metro cúbico bar',
        o: 1395
    },
    {
        k: 'L30',
        v: 'Mol por minuto',
        o: 1396
    },
    {
        k: 'L31',
        v: 'Milliroentgen aequivalent men',
        o: 1397
    },
    {
        k: 'L32',
        v: 'Nanogramo por kilogramo',
        o: 1398
    },
    {
        k: 'L33',
        v: 'Onza (avoirdupois) por día',
        o: 1399
    },
    {
        k: 'L34',
        v: 'Onza (avoirdupois) por hora',
        o: 1400
    },
    {
        k: 'L35',
        v: 'Onza (avoirdupois) por minuto',
        o: 1401
    },
    {
        k: 'L36',
        v: 'Onza (avoirdupois) por segundo',
        o: 1402
    },
    {
        k: 'L37',
        v: 'Onza (avoirdupois) por galón (UK)',
        o: 1403
    },
    {
        k: 'L38',
        v: 'Onza (avoirdupois) por galón (EUA)',
        o: 1404
    },
    {
        k: 'L39',
        v: 'Onza (avoirdupois) por pulgada cúbica',
        o: 1405
    },
    {
        k: 'L40',
        v: 'Onza fuerza',
        o: 1406
    },
    {
        k: 'L41',
        v: 'Onza (avoirdupois) fuerza pulgada',
        o: 1407
    },
    {
        k: 'L42',
        v: 'Picosiemens por metro',
        o: 1408
    },
    {
        k: 'L43',
        v: 'Peck (UK)',
        o: 1409
    },
    {
        k: 'L44',
        v: 'Peck (UK) por día',
        o: 1410
    },
    {
        k: 'L45',
        v: 'Peck (UK) por hora',
        o: 1411
    },
    {
        k: 'L46',
        v: 'Peck (UK) por minuto',
        o: 1412
    },
    {
        k: 'L47',
        v: 'Peck (UK) por segundo',
        o: 1413
    },
    {
        k: 'L48',
        v: 'Peck (seco, estados unidos) por día',
        o: 1414
    },
    {
        k: 'L49',
        v: 'Peck (seco, estados unidos) por hora',
        o: 1415
    },
    {
        k: 'L50',
        v: 'Peck (seco, estados unidos) por minuto',
        o: 1416
    },
    {
        k: 'L51',
        v: 'Peck (seco, estados unidos) por segundo',
        o: 1417
    },
    {
        k: 'L52',
        v: 'Libra.fuerza por pulgada cuadrada por libra fuerza por pulgada cuadrada',
        o: 1418
    },
    {
        k: 'L53',
        v: 'Pinta (UK) por día',
        o: 1419
    },
    {
        k: 'L54',
        v: 'Pinta (UK) por hora',
        o: 1420
    },
    {
        k: 'L55',
        v: 'Pinta (UK) por minuto',
        o: 1421
    },
    {
        k: 'L56',
        v: 'Pinta (UK) por segundo',
        o: 1422
    },
    {
        k: 'L57',
        v: 'Pinta (líquido, estados unidos) por día',
        o: 1423
    },
    {
        k: 'L58',
        v: 'Pinta (líquido, estados unidos) por hora',
        o: 1424
    },
    {
        k: 'L59',
        v: 'Pinta (líquido, estados unidos) por minuto',
        o: 1425
    },
    {
        k: 'L60',
        v: 'Pinta (líquido, estados unidos) por segundo',
        o: 1426
    },
    {
        k: 'L61',
        v: 'Pinta (US seco)',
        o: 1427
    },
    {
        k: 'L62',
        v: 'Cuarto de galón (seco de los EUA)',
        o: 1428
    },
    {
        k: 'L63',
        v: 'Slug por día',
        o: 1429
    },
    {
        k: 'L64',
        v: 'Slug por pie segundo',
        o: 1430
    },
    {
        k: 'L65',
        v: 'Slug por pie cúbico',
        o: 1431
    },
    {
        k: 'L66',
        v: 'Slug por hora',
        o: 1432
    },
    {
        k: 'L67',
        v: 'Slug por minuto',
        o: 1433
    },
    {
        k: 'L68',
        v: 'Slug por segundo',
        o: 1434
    },
    {
        k: 'L69',
        v: 'Tonelada por kelvin',
        o: 1435
    },
    {
        k: 'L70',
        v: 'Tonelada por bar',
        o: 1436
    },
    {
        k: 'L71',
        v: 'Tonelada por día',
        o: 1437
    },
    {
        k: 'L72',
        v: 'Tonelada por día kelvin',
        o: 1438
    },
    {
        k: 'L73',
        v: 'Tonelada por día bar',
        o: 1439
    },
    {
        k: 'L74',
        v: 'Tonelada por hora kelvin',
        o: 1440
    },
    {
        k: 'L75',
        v: 'Tonelada por hora bar',
        o: 1441
    },
    {
        k: 'L76',
        v: 'Tonelada por metro cúbico kelvin',
        o: 1442
    },
    {
        k: 'L77',
        v: 'Tonelada por metro cúbico bar',
        o: 1443
    },
    {
        k: 'L78',
        v: 'Tonelada por minuto',
        o: 1444
    },
    {
        k: 'L79',
        v: 'Tonelada por minuto kelvin',
        o: 1445
    },
    {
        k: 'L80',
        v: 'Tonelada por minuto bar',
        o: 1446
    },
    {
        k: 'L81',
        v: 'Tonelada por segundo',
        o: 1447
    },
    {
        k: 'L82',
        v: 'Tonelada por segundo kelvin',
        o: 1448
    },
    {
        k: 'L83',
        v: 'Tonelada por segundo bar',
        o: 1449
    },
    {
        k: 'L84',
        v: 'Tonelada (flota UK)',
        o: 1450
    },
    {
        k: 'L85',
        v: 'Tonelada larga por día',
        o: 1451
    },
    {
        k: 'L86',
        v: 'Tonelada (flota estados unidos)',
        o: 1452
    },
    {
        k: 'L87',
        v: 'Tonelada corta por grado fahrenheit',
        o: 1453
    },
    {
        k: 'L88',
        v: 'Tonelada corta por día',
        o: 1454
    },
    {
        k: 'L89',
        v: 'Tonelada corta por hora grados fahrenheit',
        o: 1455
    },
    {
        k: 'L90',
        v: 'Tonelada corta por hora libra-fuerza por pulgada cuadrada',
        o: 1456
    },
    {
        k: 'L91',
        v: 'Tonelada corta por psi',
        o: 1457
    },
    {
        k: 'L92',
        v: 'Tonelada larga (UK) por yarda cúbica',
        o: 1458
    },
    {
        k: 'L93',
        v: 'Tonelada corta (estados unidos) por yarda cúbica',
        o: 1459
    },
    {
        k: 'L94',
        v: 'Tonelada fuerza',
        o: 1460
    },
    {
        k: 'L95',
        v: 'Año común',
        o: 1461
    },
    {
        k: 'L96',
        v: 'Año sideral',
        o: 1462
    },
    {
        k: 'L98',
        v: 'Yarda por grado fahrenheit',
        o: 1463
    },
    {
        k: 'L99',
        v: 'Yarda por psi (libra por pulgada cuadrada)',
        o: 1464
    },
    {
        k: 'LA',
        v: 'Libra por pulgada cúbica',
        o: 1465
    },
    {
        k: 'LAC',
        v: 'Porcentaje de exceso de lactosa',
        o: 1466
    },
    {
        k: 'LBR',
        v: 'Libra',
        o: 1467
    },
    {
        k: 'LBT',
        v: 'Troy pound',
        o: 1468
    },
    {
        k: 'LC',
        v: 'Centímetro lineal',
        o: 1469
    },
    {
        k: 'LD',
        v: 'Litro por día',
        o: 1470
    },
    {
        k: 'LE',
        v: 'Lite',
        o: 1471
    },
    {
        k: 'LEF',
        v: 'Hoja',
        o: 1472
    },
    {
        k: 'LF',
        v: 'Pie lineal',
        o: 1473
    },
    {
        k: 'LH',
        v: 'Hora de trabajo',
        o: 1474
    },
    {
        k: 'LI',
        v: 'Pulgada lineal',
        o: 1475
    },
    {
        k: 'LJ',
        v: 'Spray grande',
        o: 1476
    },
    {
        k: 'LK',
        v: 'Enlazar',
        o: 1477
    },
    {
        k: 'LM',
        v: 'Metro lineal',
        o: 1478
    },
    {
        k: 'LN',
        v: 'Longitud',
        o: 1479
    },
    {
        k: 'LO',
        v: 'Lote [unidad de adquisición]',
        o: 1480
    },
    {
        k: 'LP',
        v: 'Libra líquida',
        o: 1481
    },
    {
        k: 'LPA',
        v: 'Litro de alcohol puro',
        o: 1482
    },
    {
        k: 'LR',
        v: 'Capa',
        o: 1483
    },
    {
        k: 'LS',
        v: 'Suma global',
        o: 1484
    },
    {
        k: 'LTN',
        v: 'Tonelada (UK) o tonelada larga (estados unidos)',
        o: 1485
    },
    {
        k: 'LUB',
        v: 'Tonelada métrica, aceite lubricante',
        o: 1486
    },
    {
        k: 'LUM',
        v: 'Lumen',
        o: 1487
    },
    {
        k: 'LUX',
        v: 'Lux',
        o: 1488
    },
    {
        k: 'LX',
        v: 'Yarda lineal por libra',
        o: 1489
    },
    {
        k: 'LY',
        v: 'Yarda lineal',
        o: 1490
    },
    {
        k: 'M0',
        v: 'cinta magnética',
        o: 1491
    },
    {
        k: 'M1',
        v: 'Miligramo por litro',
        o: 1492
    },
    {
        k: 'M10',
        v: 'Reciprocidad de la yarda cuadrada',
        o: 1493
    },
    {
        k: 'M11',
        v: 'Yarda cúbica por grado fahrenheit',
        o: 1494
    },
    {
        k: 'M12',
        v: 'Yarda cúbica por día',
        o: 1495
    },
    {
        k: 'M13',
        v: 'Yarda cúbica por hora',
        o: 1496
    },
    {
        k: 'M14',
        v: 'Yarda cúbica por psi (libra por pulgada cuadrada)',
        o: 1497
    },
    {
        k: 'M15',
        v: 'Yarda cúbica por minuto',
        o: 1498
    },
    {
        k: 'M16',
        v: 'Yarda cúbica por segundo',
        o: 1499
    },
    {
        k: 'M17',
        v: 'Kilohertz metro',
        o: 1500
    },
    {
        k: 'M18',
        v: 'Gigahertz metro',
        o: 1501
    },
    {
        k: 'M19',
        v: 'Beaufort',
        o: 1502
    },
    {
        k: 'M20',
        v: 'Recíproco de megakelvin o megakelvin a la potencia menos 1',
        o: 1503
    },
    {
        k: 'M21',
        v: 'Kilovoltio-amperio hora reciprocidad',
        o: 1504
    },
    {
        k: 'M22',
        v: 'Milímetro por centímetro cuadrado minuto',
        o: 1505
    },
    {
        k: 'M23',
        v: 'Newton por centímetro',
        o: 1506
    },
    {
        k: 'M24',
        v: 'Ohm kilómetro',
        o: 1507
    },
    {
        k: 'M25',
        v: 'Porcentaje por grado celsius',
        o: 1508
    },
    {
        k: 'M26',
        v: 'GigaOhm por metro',
        o: 1509
    },
    {
        k: 'M27',
        v: 'Megahertz metro',
        o: 1510
    },
    {
        k: 'M29',
        v: 'Kilogramo por kilogramo',
        o: 1511
    },
    {
        k: 'M30',
        v: 'voltio-amperio segundo reciprocidad',
        o: 1512
    },
    {
        k: 'M31',
        v: 'Kilogramo por kilómetro',
        o: 1513
    },
    {
        k: 'M32',
        v: 'Segundos pascal por litro',
        o: 1514
    },
    {
        k: 'M33',
        v: 'MiliMol por litro',
        o: 1515
    },
    {
        k: 'M34',
        v: 'Newton metro por metro cuadrado',
        o: 1516
    },
    {
        k: 'M35',
        v: 'Milivoltio - amperio',
        o: 1517
    },
    {
        k: 'M36',
        v: 'Mes de 30 días',
        o: 1518
    },
    {
        k: 'M37',
        v: 'Actual 360',
        o: 1519
    },
    {
        k: 'M38',
        v: 'Kilómetro por segundo cuadrado',
        o: 1520
    },
    {
        k: 'M39',
        v: 'Centímetro por segundo cuadrado',
        o: 1521
    },
    {
        k: 'M4',
        v: 'Valor monetario',
        o: 1522
    },
    {
        k: 'M40',
        v: 'Yarda por segundo cuadrado',
        o: 1523
    },
    {
        k: 'M41',
        v: 'Milímetro por segundo cuadrado',
        o: 1524
    },
    {
        k: 'M42',
        v: 'Milla (milla estatal)  por segundo cuadrado',
        o: 1525
    },
    {
        k: 'M43',
        v: 'Mil (unidad de Medida Militar)',
        o: 1526
    },
    {
        k: 'M44',
        v: 'Revolución',
        o: 1527
    },
    {
        k: 'M45',
        v: 'Grado por segundo cuadrado',
        o: 1528
    },
    {
        k: 'M46',
        v: 'Revolución por minuto',
        o: 1529
    },
    {
        k: 'M47',
        v: 'Circular Mil',
        o: 1530
    },
    {
        k: 'M48',
        v: 'Milla cuadrada (basado en u.s survey foot)',
        o: 1531
    },
    {
        k: 'M49',
        v: 'Cadena',
        o: 1532
    },
    {
        k: 'M5',
        v: 'Microcurie',
        o: 1533
    },
    {
        k: 'M50',
        v: 'Estadio',
        o: 1534
    },
    {
        k: 'M51',
        v: 'Pie (Topografía UEA)',
        o: 1535
    },
    {
        k: 'M52',
        v: 'Milla',
        o: 1536
    },
    {
        k: 'M53',
        v: 'Metro por pascal',
        o: 1537
    },
    {
        k: 'M55',
        v: 'Metro por radián',
        o: 1538
    },
    {
        k: 'M56',
        v: 'Shake',
        o: 1539
    },
    {
        k: 'M57',
        v: 'Milla por minuto',
        o: 1540
    },
    {
        k: 'M58',
        v: 'Milla por segundo',
        o: 1541
    },
    {
        k: 'M59',
        v: 'Metro por segundo pascal',
        o: 1542
    },
    {
        k: 'M60',
        v: 'Metro por hora',
        o: 1543
    },
    {
        k: 'M61',
        v: 'Pulgada por año',
        o: 1544
    },
    {
        k: 'M62',
        v: 'Kilómetro por segundo',
        o: 1545
    },
    {
        k: 'M63',
        v: 'Pulgada por minuto',
        o: 1546
    },
    {
        k: 'M64',
        v: 'Yarda por segundo',
        o: 1547
    },
    {
        k: 'M65',
        v: 'Yarda por minuto',
        o: 1548
    },
    {
        k: 'M66',
        v: 'Yarda por hora',
        o: 1549
    },
    {
        k: 'M67',
        v: 'Acre-pie',
        o: 1550
    },
    {
        k: 'M68',
        v: 'Cordón',
        o: 1551
    },
    {
        k: 'M69',
        v: 'Milla cúbica (reinounido)',
        o: 1552
    },
    {
        k: 'M7',
        v: 'Micro-pulgada',
        o: 1553
    },
    {
        k: 'M70',
        v: 'Unidad tradicional de capacidad de carga',
        o: 1554
    },
    {
        k: 'M71',
        v: 'Metro cúbico por pascal (joules)',
        o: 1555
    },
    {
        k: 'M72',
        v: 'Bel',
        o: 1556
    },
    {
        k: 'M73',
        v: 'Kilogramo por metro cúbico pascal',
        o: 1557
    },
    {
        k: 'M74',
        v: 'Kilogramo por pascal',
        o: 1558
    },
    {
        k: 'M75',
        v: 'Kilolibra fuerza',
        o: 1559
    },
    {
        k: 'M76',
        v: 'Poundal',
        o: 1560
    },
    {
        k: 'M77',
        v: 'Kilogramo metro por segundo cuadrado',
        o: 1561
    },
    {
        k: 'M78',
        v: 'Pond',
        o: 1562
    },
    {
        k: 'M79',
        v: 'Pie cuadrado por hora',
        o: 1563
    },
    {
        k: 'M80',
        v: 'Stokes por pascal',
        o: 1564
    },
    {
        k: 'M81',
        v: 'Centímetro cuadrado por segundo',
        o: 1565
    },
    {
        k: 'M82',
        v: 'Metro cuadrado por segundo pascal',
        o: 1566
    },
    {
        k: 'M83',
        v: 'Denier',
        o: 1567
    },
    {
        k: 'M84',
        v: 'Libra por yarda',
        o: 1568
    },
    {
        k: 'M85',
        v: 'Tonelada, ensayo',
        o: 1569
    },
    {
        k: 'M86',
        v: 'Libra Alemana',
        o: 1570
    },
    {
        k: 'M87',
        v: 'Kilogramo por segundo pascal',
        o: 1571
    },
    {
        k: 'M88',
        v: 'Tonelada por mes',
        o: 1572
    },
    {
        k: 'M89',
        v: 'Tonelada por año',
        o: 1573
    },
    {
        k: 'M9',
        v: 'Millones de btu por 1000 pies cúbicos',
        o: 1574
    },
    {
        k: 'M90',
        v: 'Kilolibra por hora',
        o: 1575
    },
    {
        k: 'M91',
        v: 'Libra por libra',
        o: 1576
    },
    {
        k: 'M92',
        v: 'Libra fuerza pie',
        o: 1577
    },
    {
        k: 'M93',
        v: 'Newton metro por radián',
        o: 1578
    },
    {
        k: 'M94',
        v: 'Kilogramo metro',
        o: 1579
    },
    {
        k: 'M95',
        v: 'Poundal pie',
        o: 1580
    },
    {
        k: 'M96',
        v: 'Poundal pulgada',
        o: 1581
    },
    {
        k: 'M97',
        v: 'Dina metro',
        o: 1582
    },
    {
        k: 'M98',
        v: 'Kilogramo centímetro por segundo',
        o: 1583
    },
    {
        k: 'M99',
        v: 'Gramo centímetro por segundo',
        o: 1584
    },
    {
        k: 'MA',
        v: 'Máquina por unidad',
        o: 1585
    },
    {
        k: 'MAH',
        v: 'Megavoltio amperio reactivo hora',
        o: 1586
    },
    {
        k: 'MAL',
        v: 'Megalitro',
        o: 1587
    },
    {
        k: 'MAM',
        v: 'Megametro',
        o: 1588
    },
    {
        k: 'MAR',
        v: 'Megavar',
        o: 1589
    },
    {
        k: 'MAW',
        v: 'Megawatt',
        o: 1590
    },
    {
        k: 'MBE',
        v: 'Mil equivalente de ladrillo estándar',
        o: 1591
    },
    {
        k: 'MBF',
        v: 'Mil pies de tabla',
        o: 1592
    },
    {
        k: 'MBR',
        v: 'Milibar',
        o: 1593
    },
    {
        k: 'MC',
        v: 'Microgramo',
        o: 1594
    },
    {
        k: 'MCU',
        v: 'Milicurie',
        o: 1595
    },
    {
        k: 'MD',
        v: 'Tonelada métrica seca al aire',
        o: 1596
    },
    {
        k: 'MF',
        v: 'Miligramo por pie cuadrado por lado',
        o: 1597
    },
    {
        k: 'MHZ',
        v: 'Megahertz',
        o: 1598
    },
    {
        k: 'MIK',
        v: 'Milla cuadrada (milla estatal)',
        o: 1599
    },
    {
        k: 'MIL',
        v: 'Mil',
        o: 1600
    },
    {
        k: 'MIN',
        v: 'Minuto [unidad de tiempo]',
        o: 1601
    },
    {
        k: 'MIO',
        v: 'Millón',
        o: 1602
    },
    {
        k: 'MIU',
        v: 'Unidad internacional de millón',
        o: 1603
    },
    {
        k: 'MK',
        v: 'Miligramo por pulgada cuadrada',
        o: 1604
    },
    {
        k: 'MLD',
        v: 'Mil millones',
        o: 1605
    },
    {
        k: 'MMK',
        v: 'Milímetro cuadrado',
        o: 1606
    },
    {
        k: 'MMQ',
        v: 'Milímetro cúbico',
        o: 1607
    },
    {
        k: 'MMT',
        v: 'Milímetro',
        o: 1608
    },
    {
        k: 'MND',
        v: 'Kilogramo, peso seco',
        o: 1609
    },
    {
        k: 'MPA',
        v: 'Megapascal',
        o: 1610
    },
    {
        k: 'MQ',
        v: 'Mil metros',
        o: 1611
    },
    {
        k: 'MQH',
        v: 'Metro cúbico por hora',
        o: 1612
    },
    {
        k: 'MQS',
        v: 'Metro cúbico por segundo',
        o: 1613
    },
    {
        k: 'MSK',
        v: 'Metro por segundo cuadrado',
        o: 1614
    },
    {
        k: 'MTQ',
        v: 'Metro cúbico',
        o: 1615
    },
    {
        k: 'MTS',
        v: 'Metro por segundo',
        o: 1616
    },
    {
        k: 'MV',
        v: 'Número de mults',
        o: 1617
    },
    {
        k: 'MVA',
        v: 'Megavoltio - amperio',
        o: 1618
    },
    {
        k: 'MWH',
        v: 'Megawatt hora',
        o: 1619
    },
    {
        k: 'N1',
        v: 'Pluma calórica',
        o: 1620
    },
    {
        k: 'N10',
        v: 'Libra pie por segundo',
        o: 1621
    },
    {
        k: 'N11',
        v: 'Libra pulgada por segundo',
        o: 1622
    },
    {
        k: 'N12',
        v: 'Pferdestaerke',
        o: 1623
    },
    {
        k: 'N13',
        v: 'Centímetro de mercurio (0°)',
        o: 1624
    },
    {
        k: 'N14',
        v: 'Centímetro de agua (4°)',
        o: 1625
    },
    {
        k: 'N15',
        v: 'Pie de agua (39.2 °f)',
        o: 1626
    },
    {
        k: 'N16',
        v: 'Pulgada de mercurio (32 °f)',
        o: 1627
    },
    {
        k: 'N17',
        v: 'Pulgada de mercurio (60 °f)',
        o: 1628
    },
    {
        k: 'N18',
        v: 'Pulgada de agua (39.2 °f)',
        o: 1629
    },
    {
        k: 'N19',
        v: 'Pulgada de agua (60 °f)',
        o: 1630
    },
    {
        k: 'N2',
        v: 'número de líneas',
        o: 1631
    },
    {
        k: 'N20',
        v: 'Kip por pulgada cuadrada',
        o: 1632
    },
    {
        k: 'N21',
        v: 'Poundal por pie cuadrado',
        o: 1633
    },
    {
        k: 'N22',
        v: 'Onza (avoirdupois) por pulgada cuadrada',
        o: 1634
    },
    {
        k: 'N23',
        v: 'Metro convencional de agua',
        o: 1635
    },
    {
        k: 'N24',
        v: 'Gramo por milímetro cuadrado',
        o: 1636
    },
    {
        k: 'N25',
        v: 'Libra por yarda cuadrada',
        o: 1637
    },
    {
        k: 'N26',
        v: 'Poundal por pulgada cuadrada',
        o: 1638
    },
    {
        k: 'N27',
        v: 'Pie a la cuarta potencia',
        o: 1639
    },
    {
        k: 'N28',
        v: 'Decímetro cúbico por kilogramo',
        o: 1640
    },
    {
        k: 'N29',
        v: 'Pie cúbico por libra',
        o: 1641
    },
    {
        k: 'N3',
        v: 'Impresión de punto',
        o: 1642
    },
    {
        k: 'N30',
        v: 'Pulgada cúbica por libra',
        o: 1643
    },
    {
        k: 'N31',
        v: 'Kilonewton por metro',
        o: 1644
    },
    {
        k: 'N32',
        v: 'Poundal por pulgada',
        o: 1645
    },
    {
        k: 'N33',
        v: 'Libra-fuerza por yarda',
        o: 1646
    },
    {
        k: 'N34',
        v: 'Poundal segundo por pie cuadrado',
        o: 1647
    },
    {
        k: 'N35',
        v: 'Poise por pascal',
        o: 1648
    },
    {
        k: 'N36',
        v: 'Newton segundo por metro cuadrado',
        o: 1649
    },
    {
        k: 'N37',
        v: 'Kilogramo por metro segundo',
        o: 1650
    },
    {
        k: 'N38',
        v: 'Kilogramo por metro minuto',
        o: 1651
    },
    {
        k: 'N39',
        v: 'Kilogramo por metro día',
        o: 1652
    },
    {
        k: 'N40',
        v: 'Kilogramo por metro hora',
        o: 1653
    },
    {
        k: 'N41',
        v: 'Gramo por centímetro segundo',
        o: 1654
    },
    {
        k: 'N42',
        v: 'Poundal segundo por pulgada cuadrada',
        o: 1655
    },
    {
        k: 'N43',
        v: 'Libra por pie minuto',
        o: 1656
    },
    {
        k: 'N44',
        v: 'Libra por pie día',
        o: 1657
    },
    {
        k: 'N45',
        v: 'Metro cúbico por segundo pascal',
        o: 1658
    },
    {
        k: 'N46',
        v: 'Pie poundal',
        o: 1659
    },
    {
        k: 'N47',
        v: 'Pulgada poundal',
        o: 1660
    },
    {
        k: 'N48',
        v: 'Watt por centímetro cuadrado',
        o: 1661
    },
    {
        k: 'N49',
        v: 'Watt por pulgada cuadrada',
        o: 1662
    },
    {
        k: 'N50',
        v: 'Unidad térmica británica (tabla internacional) por pie cuadrado hora.',
        o: 1663
    },
    {
        k: 'N51',
        v: 'Unidad térmica británica (termoquímica) por pie cuadrado hora.',
        o: 1664
    },
    {
        k: 'N52',
        v: 'Unidad térmica británica (termoquímico) por pie cuadrado minuto.',
        o: 1665
    },
    {
        k: 'N53',
        v: 'Unidad térmica británica (tabla internacional) por pie cuadrado segundo.',
        o: 1666
    },
    {
        k: 'N54',
        v: 'Unidad térmica británica (termoquímica) por pie cuadrado segundo.',
        o: 1667
    },
    {
        k: 'N55',
        v: 'Unidad térmica británica (tabla internacional) por pulgada cuadrada segundo.',
        o: 1668
    },
    {
        k: 'N56',
        v: 'Caloría (termoquímica) por centímetro cuadrado minuto',
        o: 1669
    },
    {
        k: 'N57',
        v: 'Caloría (termoquímica) por centímetro cuadrado segundo',
        o: 1670
    },
    {
        k: 'N58',
        v: 'Unidad térmica británica (tabla internacional) por pie cúbico',
        o: 1671
    },
    {
        k: 'N59',
        v: 'Unidad térmica británica (termoquímica) por pie cúbico',
        o: 1672
    },
    {
        k: 'N60',
        v: 'Unidad térmica británica (tabla internacional) por grado fahrenheit',
        o: 1673
    },
    {
        k: 'N61',
        v: 'Unidad térmica británica (termoquímico) por grado fahrenheit',
        o: 1674
    },
    {
        k: 'N62',
        v: 'Unidad térmica británica (tabla internacional) por grado rankine',
        o: 1675
    },
    {
        k: 'N63',
        v: 'Unidad térmica británica (termoquímico) por grado rankine',
        o: 1676
    },
    {
        k: 'N64',
        v: 'Unidad térmica británica (termoquímico) por libra grado rankine',
        o: 1677
    },
    {
        k: 'N65',
        v: 'Kilocaloría (tabla internacional) por gramo kelvin',
        o: 1678
    },
    {
        k: 'N66',
        v: 'Unidad térmica británica (39 °f)',
        o: 1679
    },
    {
        k: 'N67',
        v: 'Unidad térmica británica (59 °f)',
        o: 1680
    },
    {
        k: 'N68',
        v: 'Unidad térmica británica (60 °f)',
        o: 1681
    },
    {
        k: 'N69',
        v: 'Caloría (20 °c)',
        o: 1682
    },
    {
        k: 'N70',
        v: 'Quad',
        o: 1683
    },
    {
        k: 'N71',
        v: 'Termia (energía comercial)',
        o: 1684
    },
    {
        k: 'N72',
        v: 'Termia (UEA)',
        o: 1685
    },
    {
        k: 'N73',
        v: 'Unidad térmica británica (termoquímica) por libra',
        o: 1686
    },
    {
        k: 'N74',
        v: 'Unidad térmica británica (tabla internacional) por hora pie cuadrado grado fahrenheit.',
        o: 1687
    },
    {
        k: 'N75',
        v: 'Unidad térmica británica (termoquímico) por hora pie cuadrado grado farenheit.',
        o: 1688
    },
    {
        k: 'N76',
        v: 'Unidad térmica británica (tabla internacional) por segundo pie cuadrado grado fahrenheit.',
        o: 1689
    },
    {
        k: 'N77',
        v: 'Unidad térmica británica (termoquímico) por segundo pie cuadrado grado fahrenheit.',
        o: 1690
    },
    {
        k: 'N78',
        v: 'Kilowatt por metro cuadrado kelvin',
        o: 1691
    },
    {
        k: 'N79',
        v: 'Kelvin por pascal',
        o: 1692
    },
    {
        k: 'N80',
        v: 'Watt por metro grado celsius',
        o: 1693
    },
    {
        k: 'N81',
        v: 'Kilowatt por metro kelvin',
        o: 1694
    },
    {
        k: 'N82',
        v: 'Kilowatt por metro grado celsius',
        o: 1695
    },
    {
        k: 'N83',
        v: 'Metro por grado celsius metro',
        o: 1696
    },
    {
        k: 'N84',
        v: 'Grado fahrenheit hora por unidad térmica británica (tabla internacional)',
        o: 1697
    },
    {
        k: 'N85',
        v: 'Grado fahrenheit hora por unidad térmica británica (termoquímico)',
        o: 1698
    },
    {
        k: 'N86',
        v: 'Grado fahrenheit segundo por unidad térmica británica (tabla internacional)',
        o: 1699
    },
    {
        k: 'N87',
        v: 'Grago fahrenheit segundo por unidad térmica británica (termoquímico)',
        o: 1700
    },
    {
        k: 'N88',
        v: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (tabla internacional) pulgada',
        o: 1701
    },
    {
        k: 'N89',
        v: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (termoquímico) pulgada.',
        o: 1702
    },
    {
        k: 'N90',
        v: 'Kilofaradio',
        o: 1703
    },
    {
        k: 'N91',
        v: 'Joule recíproco',
        o: 1704
    },
    {
        k: 'N92',
        v: 'Picosiemens',
        o: 1705
    },
    {
        k: 'N93',
        v: 'Amperio por pascal',
        o: 1706
    },
    {
        k: 'N94',
        v: 'Franklin',
        o: 1707
    },
    {
        k: 'N95',
        v: 'Amperio minuto',
        o: 1708
    },
    {
        k: 'N96',
        v: 'Biot',
        o: 1709
    },
    {
        k: 'N97',
        v: 'Gilbert',
        o: 1710
    },
    {
        k: 'N98',
        v: 'Voltio por pascal',
        o: 1711
    },
    {
        k: 'N99',
        v: 'Picovoltio',
        o: 1712
    },
    {
        k: 'NA',
        v: 'Miligramo por kilogramo',
        o: 1713
    },
    {
        k: 'NAR',
        v: 'Número de artículos',
        o: 1714
    },
    {
        k: 'NB',
        v: 'barcaza',
        o: 1715
    },
    {
        k: 'NBB',
        v: 'Número de bobinas',
        o: 1716
    },
    {
        k: 'NC',
        v: 'Carro',
        o: 1717
    },
    {
        k: 'NCL',
        v: 'Número de células',
        o: 1718
    },
    {
        k: 'ND',
        v: 'Barril neto',
        o: 1719
    },
    {
        k: 'NE',
        v: 'Litro neto',
        o: 1720
    },
    {
        k: 'NEW',
        v: 'Newton',
        o: 1721
    },
    {
        k: 'NF',
        v: 'Mensaje',
        o: 1722
    },
    {
        k: 'NG',
        v: 'Galón neto (us)',
        o: 1723
    },
    {
        k: 'NH',
        v: 'Hora del mensaje',
        o: 1724
    },
    {
        k: 'NI',
        v: 'Galón imperial neto',
        o: 1725
    },
    {
        k: 'NIL',
        v: 'Nil',
        o: 1726
    },
    {
        k: 'NIU',
        v: 'Número de unidades internacionales',
        o: 1727
    },
    {
        k: 'NJ',
        v: 'Número de pantallas',
        o: 1728
    },
    {
        k: 'NL',
        v: 'Carga',
        o: 1729
    },
    {
        k: 'NM3',
        v: 'Metro cúbico normalizado',
        o: 1730
    },
    {
        k: 'NMI',
        v: 'Milla náutica',
        o: 1731
    },
    {
        k: 'NMP',
        v: 'Número de paquetes',
        o: 1732
    },
    {
        k: 'NN',
        v: 'tren',
        o: 1733
    },
    {
        k: 'NPR',
        v: 'Número de parejas',
        o: 1734
    },
    {
        k: 'NPT',
        v: 'Número de partes',
        o: 1735
    },
    {
        k: 'NQ',
        v: 'Mho',
        o: 1736
    },
    {
        k: 'NR',
        v: 'Micromho',
        o: 1737
    },
    {
        k: 'NT',
        v: 'Tonelada neta',
        o: 1738
    },
    {
        k: 'NTT',
        v: 'Tonelada de registro neto',
        o: 1739
    },
    {
        k: 'NV',
        v: 'vehículo',
        o: 1740
    },
    {
        k: 'NY',
        v: 'Libra por tonelada métrica al aire seco',
        o: 1741
    },
    {
        k: 'NX',
        v: 'Parte por mil',
        o: 1742
    },
    {
        k: 'OA',
        v: 'Panel',
        o: 1743
    },
    {
        k: 'ODE',
        v: 'Equivalente de agotamiento del ozona',
        o: 1744
    },
    {
        k: 'Ohm',
        v: 'Ohm',
        o: 1745
    },
    {
        k: 'ON',
        v: 'Onza por yarda cuadrada',
        o: 1746
    },
    {
        k: 'ONZ',
        v: 'Onza (avoirdupois)',
        o: 1747
    },
    {
        k: 'OP',
        v: 'Dos paquetes',
        o: 1748
    },
    {
        k: 'OPM',
        v: 'Oscilaciones por minuto',
        o: 1749
    },
    {
        k: 'OT',
        v: 'Hora extra',
        o: 1750
    },
    {
        k: 'OZ',
        v: 'Onza AV',
        o: 1751
    },
    {
        k: 'OZA',
        v: 'Onza líquida (estados unidos)',
        o: 1752
    },
    {
        k: 'OZI',
        v: 'Onza líquida (UK)',
        o: 1753
    },
    {
        k: 'P0',
        v: 'Página electrónica',
        o: 1754
    },
    {
        k: 'P1',
        v: 'Tanto por ciento',
        o: 1755
    },
    {
        k: 'P10',
        v: 'Culombio por metro',
        o: 1756
    },
    {
        k: 'P11',
        v: 'Kiloweber',
        o: 1757
    },
    {
        k: 'P12',
        v: 'Gamma',
        o: 1758
    },
    {
        k: 'P13',
        v: 'Kilotesla',
        o: 1759
    },
    {
        k: 'P14',
        v: 'Joule por segundo',
        o: 1760
    },
    {
        k: 'P15',
        v: 'Joule por minuto',
        o: 1761
    },
    {
        k: 'P16',
        v: 'Joule por hora',
        o: 1762
    },
    {
        k: 'P17',
        v: 'Joule por día',
        o: 1763
    },
    {
        k: 'P18',
        v: 'Kilojoule por segundo',
        o: 1764
    },
    {
        k: 'P19',
        v: 'Kilojoule por minuto',
        o: 1765
    },
    {
        k: 'P2',
        v: 'Libra por pie',
        o: 1766
    },
    {
        k: 'P20',
        v: 'Kilojoule por hora',
        o: 1767
    },
    {
        k: 'P21',
        v: 'Kilojoule por día',
        o: 1768
    },
    {
        k: 'P22',
        v: 'NanoOhm',
        o: 1769
    },
    {
        k: 'P23',
        v: 'Ohm circular-mil por pie',
        o: 1770
    },
    {
        k: 'P24',
        v: 'Kilohenry',
        o: 1771
    },
    {
        k: 'P25',
        v: 'Lumen por pie cuadrado',
        o: 1772
    },
    {
        k: 'P26',
        v: 'Foto',
        o: 1773
    },
    {
        k: 'P27',
        v: 'Vela (medida)',
        o: 1774
    },
    {
        k: 'P28',
        v: 'Candela por pulgada cuadrada',
        o: 1775
    },
    {
        k: 'P29',
        v: 'Footlambert',
        o: 1776
    },
    {
        k: 'P3',
        v: 'Tres paquetes',
        o: 1777
    },
    {
        k: 'P30',
        v: 'Lambert',
        o: 1778
    },
    {
        k: 'P31',
        v: 'Stilb',
        o: 1779
    },
    {
        k: 'P32',
        v: 'Candela por pie cuadrado',
        o: 1780
    },
    {
        k: 'P33',
        v: 'Kilocandela',
        o: 1781
    },
    {
        k: 'P34',
        v: 'Milicandela',
        o: 1782
    },
    {
        k: 'P35',
        v: 'Hefner-kerze',
        o: 1783
    },
    {
        k: 'P36',
        v: 'Candela internacional',
        o: 1784
    },
    {
        k: 'P37',
        v: 'Unidad térmica británica (tabla internacional) por pie cuadrado',
        o: 1785
    },
    {
        k: 'P38',
        v: 'Unidad térmica británica (termoquímica) por pie cuadrado',
        o: 1786
    },
    {
        k: 'P39',
        v: 'Caloría (termoquímica) por centímetro cuadrado',
        o: 1787
    },
    {
        k: 'P4',
        v: 'paquete de cuatro',
        o: 1788
    },
    {
        k: 'P40',
        v: 'Langley',
        o: 1789
    },
    {
        k: 'P41',
        v: 'Década (logarítmica)',
        o: 1790
    },
    {
        k: 'P42',
        v: 'Pascal por segundo cuadrado',
        o: 1791
    },
    {
        k: 'P43',
        v: 'Bel por metro',
        o: 1792
    },
    {
        k: 'P44',
        v: 'Libra Mol',
        o: 1793
    },
    {
        k: 'P45',
        v: 'Libra de Mol por segundo',
        o: 1794
    },
    {
        k: 'P46',
        v: 'Libra Mol por minuto',
        o: 1795
    },
    {
        k: 'P47',
        v: 'KiloMol por kilogramo',
        o: 1796
    },
    {
        k: 'P48',
        v: 'Libra de Mol por libra',
        o: 1797
    },
    {
        k: 'P49',
        v: 'Newton metro cuadrado por amperio',
        o: 1798
    },
    {
        k: 'P5',
        v: 'Paquete de cinco',
        o: 1799
    },
    {
        k: 'P50',
        v: 'Metro weber',
        o: 1800
    },
    {
        k: 'P51',
        v: 'Mol por kilogramo pascal',
        o: 1801
    },
    {
        k: 'P52',
        v: 'Mol por metro cúbico pascal',
        o: 1802
    },
    {
        k: 'P53',
        v: 'Unit por segundo',
        o: 1803
    },
    {
        k: 'P54',
        v: 'MiliGray por segundo',
        o: 1804
    },
    {
        k: 'P55',
        v: 'MicroGray por segundo',
        o: 1805
    },
    {
        k: 'P56',
        v: 'NanoGray por segundo',
        o: 1806
    },
    {
        k: 'P57',
        v: 'Gray por minuto',
        o: 1807
    },
    {
        k: 'P58',
        v: 'MiliGray por minuto',
        o: 1808
    },
    {
        k: 'P59',
        v: 'Microgray por minuto',
        o: 1809
    },
    {
        k: 'P6',
        v: 'paquete de seis',
        o: 1810
    },
    {
        k: 'P60',
        v: 'Nanogray por minuto',
        o: 1811
    },
    {
        k: 'P61',
        v: 'Gray por hora',
        o: 1812
    },
    {
        k: 'P62',
        v: 'MiliGray por hora',
        o: 1813
    },
    {
        k: 'P63',
        v: 'Micro gray por hora',
        o: 1814
    },
    {
        k: 'P64',
        v: 'Nanogray por hora',
        o: 1815
    },
    {
        k: 'P65',
        v: 'Sievert por segundo',
        o: 1816
    },
    {
        k: 'P66',
        v: 'MilliSievert por segundo',
        o: 1817
    },
    {
        k: 'P67',
        v: 'MicroSievert por segundo',
        o: 1818
    },
    {
        k: 'P68',
        v: 'NanoSievert por segundo',
        o: 1819
    },
    {
        k: 'P69',
        v: 'Rem por segundo',
        o: 1820
    },
    {
        k: 'P7',
        v: 'Paquete de siete',
        o: 1821
    },
    {
        k: 'P70',
        v: 'Sievert por hora',
        o: 1822
    },
    {
        k: 'P71',
        v: 'MilliSievert por hora',
        o: 1823
    },
    {
        k: 'P72',
        v: 'Micro Sievert por hora',
        o: 1824
    },
    {
        k: 'P73',
        v: 'NanoSievert por hora',
        o: 1825
    },
    {
        k: 'P74',
        v: 'Sievert por minuto',
        o: 1826
    },
    {
        k: 'P75',
        v: 'MilliSievert por minuto',
        o: 1827
    },
    {
        k: 'P76',
        v: 'MicroSievert por minuto',
        o: 1828
    },
    {
        k: 'P77',
        v: 'NanoSievert pominut',
        o: 1829
    },
    {
        k: 'P78',
        v: 'Reciprocidad por segundo',
        o: 1830
    },
    {
        k: 'P79',
        v: 'Pascal metro cuadrado por kilogramo',
        o: 1831
    },
    {
        k: 'P8',
        v: 'Paquete de ocho',
        o: 1832
    },
    {
        k: 'P80',
        v: 'Milipascal por metro.',
        o: 1833
    },
    {
        k: 'P81',
        v: 'Kilopascal por metro.',
        o: 1834
    },
    {
        k: 'P82',
        v: 'Hectopascal por metro.',
        o: 1835
    },
    {
        k: 'P83',
        v: 'Atmosfera estándar por metro.',
        o: 1836
    },
    {
        k: 'P84',
        v: 'Atmosfera técnica por metro.',
        o: 1837
    },
    {
        k: 'P85',
        v: 'Torr por metro.',
        o: 1838
    },
    {
        k: 'P86',
        v: 'Psi por pulgada',
        o: 1839
    },
    {
        k: 'P87',
        v: 'Metro cúbico por segundo de metro cuadrado',
        o: 1840
    },
    {
        k: 'P88',
        v: 'Rhe',
        o: 1841
    },
    {
        k: 'P89',
        v: 'Libra por metro cúbico de pulgada',
        o: 1842
    },
    {
        k: 'P9',
        v: 'Nueve paquetes',
        o: 1843
    },
    {
        k: 'P90',
        v: 'Libra-fuerza por pulgada cuadrada',
        o: 1844
    },
    {
        k: 'P91',
        v: 'Permanente (0°c)',
        o: 1845
    },
    {
        k: 'P92',
        v: 'Permanente (23°c)',
        o: 1846
    },
    {
        k: 'P93',
        v: 'Byte por segundo',
        o: 1847
    },
    {
        k: 'P94',
        v: 'Kilobyte por segundo',
        o: 1848
    },
    {
        k: 'P95',
        v: 'Megabite por segundo',
        o: 1849
    },
    {
        k: 'P96',
        v: 'Recíproco de la unidad si deriva voltio',
        o: 1850
    },
    {
        k: 'P97',
        v: 'Reciprocidad de Radian',
        o: 1851
    },
    {
        k: 'P98',
        v: 'Pascal a la suma de potencia de los números estequimetricos',
        o: 1852
    },
    {
        k: 'P99',
        v: 'Mols por metro cúbico a la suma de potencia de los números estequimetricos',
        o: 1853
    },
    {
        k: 'PAL',
        v: 'Pascal',
        o: 1854
    },
    {
        k: 'PB',
        v: 'Par de la pulgada',
        o: 1855
    },
    {
        k: 'PD',
        v: 'Almohadilla',
        o: 1856
    },
    {
        k: 'PE',
        v: 'Libra equivalente',
        o: 1857
    },
    {
        k: 'PFL',
        v: 'Litro de prueba',
        o: 1858
    },
    {
        k: 'PGL',
        v: 'Galón de prueba',
        o: 1859
    },
    {
        k: 'PI',
        v: 'Tono',
        o: 1860
    },
    {
        k: 'PLA',
        v: 'Grado de platón',
        o: 1861
    },
    {
        k: 'PM',
        v: 'Porcentaje de libra',
        o: 1862
    },
    {
        k: 'PO',
        v: 'Libra por pulgada',
        o: 1863
    },
    {
        k: 'PQ',
        v: 'Página por pulgada',
        o: 1864
    },
    {
        k: 'PS',
        v: 'Libra fuerza por pulgada cuadrada',
        o: 1865
    },
    {
        k: 'PT',
        v: 'Pinta (US)',
        o: 1866
    },
    {
        k: 'PTD',
        v: 'Pinta seca (estados unidos)',
        o: 1867
    },
    {
        k: 'PTI',
        v: 'Pint (uk)',
        o: 1868
    },
    {
        k: 'PTL',
        v: 'Pinta líquida (estados unidos)',
        o: 1869
    },
    {
        k: 'PTN',
        v: 'Parte',
        o: 1870
    },
    {
        k: 'PV',
        v: 'Media pinta (US)',
        o: 1871
    },
    {
        k: 'PW',
        v: 'Libra por pulgada de ancho',
        o: 1872
    },
    {
        k: 'PY',
        v: 'Pico seco (EUA)',
        o: 1873
    },
    {
        k: 'PZ',
        v: 'Peck dry (UK)',
        o: 1874
    },
    {
        k: 'Q10',
        v: 'Joule por tesla',
        o: 1875
    },
    {
        k: 'Q11',
        v: 'Erlang',
        o: 1876
    },
    {
        k: 'Q12',
        v: 'Octeto',
        o: 1877
    },
    {
        k: 'Q13',
        v: 'Octeto por segundo',
        o: 1878
    },
    {
        k: 'Q14',
        v: 'Shannon',
        o: 1879
    },
    {
        k: 'Q15',
        v: 'Hartley',
        o: 1880
    },
    {
        k: 'Q16',
        v: 'Unidad natural de información',
        o: 1881
    },
    {
        k: 'Q17',
        v: 'Shannon por segundo',
        o: 1882
    },
    {
        k: 'Q18',
        v: 'Hartley por segundo',
        o: 1883
    },
    {
        k: 'Q19',
        v: 'Unidad natural de información por segundo',
        o: 1884
    },
    {
        k: 'Q20',
        v: 'Segundo por kilogramo',
        o: 1885
    },
    {
        k: 'Q21',
        v: 'Watt metro cuadrado',
        o: 1886
    },
    {
        k: 'Q22',
        v: 'Segundo por metro cúbicos de radianes',
        o: 1887
    },
    {
        k: 'Q23',
        v: 'Weber a la potencia menos uno',
        o: 1888
    },
    {
        k: 'Q24',
        v: 'Reciprocidad de Pulgada',
        o: 1889
    },
    {
        k: 'Q25',
        v: 'Dioptría',
        o: 1890
    },
    {
        k: 'Q26',
        v: 'Uno por uno',
        o: 1891
    },
    {
        k: 'Q27',
        v: 'Newtons metros por metro',
        o: 1892
    },
    {
        k: 'Q28',
        v: 'Kilogramo por metro cuadrado pascal segundo',
        o: 1893
    },
    {
        k: 'Q29',
        v: 'Microgramo por hectogramo',
        o: 1894
    },
    {
        k: 'Q3',
        v: 'Comida',
        o: 1895
    },
    {
        k: 'Q30',
        v: 'Ph (potencial de hidrogeno)',
        o: 1896
    },
    {
        k: 'Q31',
        v: 'Kilojoule por gramo',
        o: 1897
    },
    {
        k: 'Q32',
        v: 'Femtolitro',
        o: 1898
    },
    {
        k: 'Q33',
        v: 'Picolitro',
        o: 1899
    },
    {
        k: 'Q34',
        v: 'Nanolitro',
        o: 1900
    },
    {
        k: 'Q35',
        v: 'Megawatts por minuto',
        o: 1901
    },
    {
        k: 'Q36',
        v: 'Metro cuadrado por metro cúbico',
        o: 1902
    },
    {
        k: 'Q37',
        v: 'Metro cúbico estándar por día',
        o: 1903
    },
    {
        k: 'Q38',
        v: 'Metro cúbico estándar por hora',
        o: 1904
    },
    {
        k: 'Q39',
        v: 'Metro cúbico normalizado por día',
        o: 1905
    },
    {
        k: 'Q40',
        v: 'Metro cúbico normalizado por hora',
        o: 1906
    },
    {
        k: 'Q41',
        v: 'Joule por metro cúbico normalizado',
        o: 1907
    },
    {
        k: 'Q42',
        v: 'Joule por metro cúbico estándar',
        o: 1908
    },
    {
        k: 'QA',
        v: 'Página-fascimil',
        o: 1909
    },
    {
        k: 'QAN',
        v: 'Cuarto (de un año)',
        o: 1910
    },
    {
        k: 'QB',
        v: 'Página, copia impresa',
        o: 1911
    },
    {
        k: 'QD',
        v: 'Cuarta docena',
        o: 1912
    },
    {
        k: 'QH',
        v: 'Un cuarto de hora',
        o: 1913
    },
    {
        k: 'QK',
        v: 'Cuarto de kilogramo',
        o: 1914
    },
    {
        k: 'QR',
        v: 'Mano de papel',
        o: 1915
    },
    {
        k: 'QT',
        v: 'Cuarto (EUA)',
        o: 1916
    },
    {
        k: 'QTD',
        v: 'Cuarto seco (estados unidos)',
        o: 1917
    },
    {
        k: 'QTI',
        v: 'Cuarto (UK)',
        o: 1918
    },
    {
        k: 'QTL',
        v: 'Cuarto de líquido (estados unidos)',
        o: 1919
    },
    {
        k: 'QTR',
        v: 'Cuarto',
        o: 1920
    },
    {
        k: 'R1',
        v: 'Pica',
        o: 1921
    },
    {
        k: 'R9',
        v: 'Mil metros cúbicos',
        o: 1922
    },
    {
        k: 'RH',
        v: 'Hora de funcionamiento',
        o: 1923
    },
    {
        k: 'RK',
        v: 'Medida métrica de rollo',
        o: 1924
    },
    {
        k: 'RM',
        v: 'Resma',
        o: 1925
    },
    {
        k: 'RN',
        v: 'Medida métrica de Hojas (resma)',
        o: 1926
    },
    {
        k: 'ROM',
        v: 'Habitación',
        o: 1927
    },
    {
        k: 'RP',
        v: 'Libra por resma',
        o: 1928
    },
    {
        k: 'RPM',
        v: 'Revoluciones por minuto',
        o: 1929
    },
    {
        k: 'RPS',
        v: 'Revoluciones por segundo',
        o: 1930
    },
    {
        k: 'RS',
        v: 'Reiniciar',
        o: 1931
    },
    {
        k: 'RT',
        v: 'Milla de toneladas de ingresos',
        o: 1932
    },
    {
        k: 'RU',
        v: 'correr',
        o: 1933
    },
    {
        k: 'S3',
        v: 'Pie cuadrado por segundo',
        o: 1934
    },
    {
        k: 'S4',
        v: 'Metro cuadrado por segundo',
        o: 1935
    },
    {
        k: 'S5',
        v: 'Sesenta cuartos de pulgada',
        o: 1936
    },
    {
        k: 'S6',
        v: 'Sesión',
        o: 1937
    },
    {
        k: 'S7',
        v: 'unidad de almacenamiento',
        o: 1938
    },
    {
        k: 'S8',
        v: 'Unidad de publicidad estándar',
        o: 1939
    },
    {
        k: 'SAN',
        v: 'Medio año',
        o: 1940
    },
    {
        k: 'SCO',
        v: 'Puntuación',
        o: 1941
    },
    {
        k: 'SCR',
        v: 'Escrúpulo',
        o: 1942
    },
    {
        k: 'SD',
        v: 'Libra sólida',
        o: 1943
    },
    {
        k: 'SE',
        v: 'Sección',
        o: 1944
    },
    {
        k: 'SEC',
        v: 'Segundo [unidad de tiempo]',
        o: 1945
    },
    {
        k: 'SG',
        v: 'Segmento',
        o: 1946
    },
    {
        k: 'SHT',
        v: 'Tonelada de envíos',
        o: 1947
    },
    {
        k: 'SIE',
        v: 'Siemens',
        o: 1948
    },
    {
        k: 'SK',
        v: 'Camión cisterna con división',
        o: 1949
    },
    {
        k: 'SM3',
        v: 'Metro cúbico estándar',
        o: 1950
    },
    {
        k: 'SMI',
        v: 'Milla (milla estatal)',
        o: 1951
    },
    {
        k: 'SN',
        v: 'Barra cuadrada',
        o: 1952
    },
    {
        k: 'SQ',
        v: 'Cuadrado',
        o: 1953
    },
    {
        k: 'SQR',
        v: 'Cuadrado y techado',
        o: 1954
    },
    {
        k: 'SR',
        v: 'Tira',
        o: 1955
    },
    {
        k: 'SS',
        v: 'Medida métrica de hoja',
        o: 1956
    },
    {
        k: 'SST',
        v: 'Estándar corto (7200 partidos)',
        o: 1957
    },
    {
        k: 'STC',
        v: 'Palo',
        o: 1958
    },
    {
        k: 'STI',
        v: 'Estone (UK)',
        o: 1959
    },
    {
        k: 'STK',
        v: 'Palo, cigarrillo',
        o: 1960
    },
    {
        k: 'STL',
        v: 'Litro estándar',
        o: 1961
    },
    {
        k: 'STN',
        v: 'Tonelada (estados unidos) o tonelada corta (UK y estados unidos)',
        o: 1962
    },
    {
        k: 'STW',
        v: 'Paja',
        o: 1963
    },
    {
        k: 'SW',
        v: 'Número de madejas',
        o: 1964
    },
    {
        k: 'SX',
        v: 'Envío',
        o: 1965
    },
    {
        k: 'SYR',
        v: 'Jeringuilla',
        o: 1966
    },
    {
        k: 'T0',
        v: 'Línea de telecomunicaciones en servicio',
        o: 1967
    },
    {
        k: 'T1',
        v: 'Mil libras',
        o: 1968
    },
    {
        k: 'T3',
        v: 'Mil pedazos',
        o: 1969
    },
    {
        k: 'T4',
        v: 'Bolsa de mil',
        o: 1970
    },
    {
        k: 'T5',
        v: 'Mil envolturas',
        o: 1971
    },
    {
        k: 'T6',
        v: 'Mil galones (US)',
        o: 1972
    },
    {
        k: 'T7',
        v: 'Impresión de mil',
        o: 1973
    },
    {
        k: 'T8',
        v: 'Mil pulgadas lineales',
        o: 1974
    },
    {
        k: 'TA',
        v: 'Décimo de pie cúbico',
        o: 1975
    },
    {
        k: 'TAB',
        v: 'Tonelada de registro bruto',
        o: 1976
    },
    {
        k: 'TAH',
        v: 'Kiloamperio-hora (milamperio-hora)',
        o: 1977
    },
    {
        k: 'TAN',
        v: 'Número de acido total',
        o: 1978
    },
    {
        k: 'TC',
        v: 'Camión',
        o: 1979
    },
    {
        k: 'TD',
        v: 'Térmico',
        o: 1980
    },
    {
        k: 'TE',
        v: 'Totalizador',
        o: 1981
    },
    {
        k: 'TF',
        v: 'Diez yardas cuadradas',
        o: 1982
    },
    {
        k: 'TI',
        v: 'Mil pulgadas cuadradas',
        o: 1983
    },
    {
        k: 'TIC',
        v: 'Tonelada métrica, incluido el contenedor',
        o: 1984
    },
    {
        k: 'TIP',
        v: 'Tonelada métrica, incluido el embalaje interior',
        o: 1985
    },
    {
        k: 'TJ',
        v: 'Mil centímetros cuadrados',
        o: 1986
    },
    {
        k: 'TKM',
        v: 'Tonelada kilometro',
        o: 1987
    },
    {
        k: 'TL',
        v: 'Mil pies (lineal)',
        o: 1988
    },
    {
        k: 'TMS',
        v: 'Kilogramo de carne importada, menos despojos',
        o: 1989
    },
    {
        k: 'TNE',
        v: 'Tonelada métrica',
        o: 1990
    },
    {
        k: 'TP',
        v: 'Paquete de diez',
        o: 1991
    },
    {
        k: 'TPI',
        v: 'Dientes por pulgada',
        o: 1992
    },
    {
        k: 'TPR',
        v: 'Decenas de pares',
        o: 1993
    },
    {
        k: 'TQ',
        v: 'Mil pies',
        o: 1994
    },
    {
        k: 'TQD',
        v: 'Mil metros cúbicos por día',
        o: 1995
    },
    {
        k: 'TR',
        v: 'Diez pies cuadrados',
        o: 1996
    },
    {
        k: 'TRL',
        v: 'Trillones (eur)',
        o: 1997
    },
    {
        k: 'Ts',
        v: 'Mil pies cuadrados',
        o: 1998
    },
    {
        k: 'TSD',
        v: 'Tonelada de sustancia 90% seca',
        o: 1999
    },
    {
        k: 'TSH',
        v: 'Tonelada de vapor por hora',
        o: 2000
    },
    {
        k: 'TST',
        v: 'Decena de conjuntos',
        o: 2001
    },
    {
        k: 'TT',
        v: 'Mil metros lineales',
        o: 2002
    },
    {
        k: 'TTS',
        v: 'Decenas de millar de pegatinas',
        o: 2003
    },
    {
        k: 'Tu',
        v: 'Contenedor externo',
        o: 2004
    },
    {
        k: 'TV',
        v: 'Mil kilogramos',
        o: 2005
    },
    {
        k: 'TW',
        v: 'Mil hojas',
        o: 2006
    },
    {
        k: 'U1',
        v: 'Tratamiento',
        o: 2007
    },
    {
        k: 'U2',
        v: 'Número de tabletas',
        o: 2008
    },
    {
        k: 'UA',
        v: 'Torr',
        o: 2009
    },
    {
        k: 'UB',
        v: 'Línea de telecomunicaciones en servicio promedio',
        o: 2010
    },
    {
        k: 'UC',
        v: 'Puerto de telecomunicaciones',
        o: 2011
    },
    {
        k: 'UD',
        v: 'Décimo minuto',
        o: 2012
    },
    {
        k: 'UE',
        v: 'Hora de décimo',
        o: 2013
    },
    {
        k: 'UF',
        v: 'Uso por línea de telecomunicaciones promedio',
        o: 2014
    },
    {
        k: 'UH',
        v: 'Diez mil yardas',
        o: 2015
    },
    {
        k: 'UM',
        v: 'Millón de unidades',
        o: 2016
    },
    {
        k: 'UN',
        v: 'Newton metro',
        o: 2017
    },
    {
        k: 'VA',
        v: 'Voltio-amperio por kilogramo',
        o: 2018
    },
    {
        k: 'VLT',
        v: 'Voltio',
        o: 2019
    },
    {
        k: 'VP',
        v: 'Volumen porcentual',
        o: 2020
    },
    {
        k: 'VS',
        v: 'Visita',
        o: 2021
    },
    {
        k: 'W2',
        v: 'Kilo húmedo',
        o: 2022
    },
    {
        k: 'W4',
        v: 'Dos semanas',
        o: 2023
    },
    {
        k: 'WA',
        v: 'Watt por kilogramo',
        o: 2024
    },
    {
        k: 'WB',
        v: 'Libra húmeda',
        o: 2025
    },
    {
        k: 'WCD',
        v: 'Cable',
        o: 2026
    },
    {
        k: 'WE',
        v: 'Tonelada húmeda',
        o: 2027
    },
    {
        k: 'WEB',
        v: 'Weber',
        o: 2028
    },
    {
        k: 'WEE',
        v: 'Semana',
        o: 2029
    },
    {
        k: 'WG',
        v: 'Galón de vino',
        o: 2030
    },
    {
        k: 'WH',
        v: 'Rueda',
        o: 2031
    },
    {
        k: 'WHR',
        v: 'Watt hora',
        o: 2032
    },
    {
        k: 'WI',
        v: 'Peso por pulgada cuadrada',
        o: 2033
    },
    {
        k: 'WM',
        v: 'Mes de trabajo',
        o: 2034
    },
    {
        k: 'WR',
        v: 'Envolver',
        o: 2035
    },
    {
        k: 'WSD',
        v: 'Estándar',
        o: 2036
    },
    {
        k: 'WTT',
        v: 'Watt',
        o: 2037
    },
    {
        k: 'WW',
        v: 'Mililitro de agua',
        o: 2038
    },
    {
        k: 'X1',
        v: 'Cadena de gunter',
        o: 2039
    },
    {
        k: 'X1A',
        v: 'Tambor de acero',
        o: 2040
    },
    {
        k: 'X1B',
        v: 'Tambor de aluminio',
        o: 2041
    },
    {
        k: 'X1D',
        v: 'Tambor contrachapado',
        o: 2042
    },
    {
        k: 'X1F',
        v: 'Contenedor flexible',
        o: 2043
    },
    {
        k: 'X1G',
        v: 'Tambor de fibra',
        o: 2044
    },
    {
        k: 'X1w',
        v: 'Tambor de madera',
        o: 2045
    },
    {
        k: 'X2C',
        v: 'Barril de madera',
        o: 2046
    },
    {
        k: 'X3A',
        v: 'Bidón de acero',
        o: 2047
    },
    {
        k: 'X3H',
        v: 'Bidón de plástico',
        o: 2048
    },
    {
        k: 'X43',
        v: 'Bolsa de gran tamaño',
        o: 2049
    },
    {
        k: 'X44',
        v: 'Bolsa de plástico',
        o: 2050
    },
    {
        k: 'X4A',
        v: 'Caja de acero',
        o: 2051
    },
    {
        k: 'X4B',
        v: 'Caja de aluminio',
        o: 2052
    },
    {
        k: 'X4C',
        v: 'Caja de  madera natural',
        o: 2053
    },
    {
        k: 'X4D',
        v: 'Caja de contrachapado',
        o: 2054
    },
    {
        k: 'X4F',
        v: 'Caja de madera reconstituida',
        o: 2055
    },
    {
        k: 'X4G',
        v: 'Caja de cartón',
        o: 2056
    },
    {
        k: 'X4H',
        v: 'Caja de plástico',
        o: 2057
    },
    {
        k: 'X5H',
        v: 'Bolsa de plástico tejido',
        o: 2058
    },
    {
        k: 'X5L',
        v: 'Bolsa textil',
        o: 2059
    },
    {
        k: 'X5M',
        v: 'Bolsa de papel',
        o: 2060
    },
    {
        k: 'X6H',
        v: 'Recipiente de plástico, Contenedor compuesto.',
        o: 2061
    },
    {
        k: 'X6P',
        v: 'Recipiente de vidrio, Contenedor compuesto.',
        o: 2062
    },
    {
        k: 'X7A',
        v: 'Estuche para carro',
        o: 2063
    },
    {
        k: 'X7B',
        v: 'Estuche de madera',
        o: 2064
    },
    {
        k: 'X8A',
        v: 'Pallet de madera',
        o: 2065
    },
    {
        k: 'X8B',
        v: 'Cajón de madera',
        o: 2066
    },
    {
        k: 'X8C',
        v: 'Madera flejada',
        o: 2067
    },
    {
        k: 'XAA',
        v: 'Contenedor intermedio  para gráneles de plástico rígido',
        o: 2068
    },
    {
        k: 'XAB',
        v: 'Contenedor de fibra',
        o: 2069
    },
    {
        k: 'XAC',
        v: 'Contenedor de papel',
        o: 2070
    },
    {
        k: 'XAD',
        v: 'Contenedor de madera',
        o: 2071
    },
    {
        k: 'XAE',
        v: 'Aerosol',
        o: 2072
    },
    {
        k: 'XAF',
        v: 'Pallet modular con collares,  80cms * 60cms',
        o: 2073
    },
    {
        k: 'XAG',
        v: 'Pallet o empaquetado',
        o: 2074
    },
    {
        k: 'XAH',
        v: 'Pallet, 100cms X 110cms',
        o: 2075
    },
    {
        k: 'XAI',
        v: 'Contenedor tipo concha',
        o: 2076
    },
    {
        k: 'XAJ',
        v: 'Cono',
        o: 2077
    },
    {
        k: 'XAL',
        v: 'Esfera',
        o: 2078
    },
    {
        k: 'XAM',
        v: 'Ampolleta no protegida',
        o: 2079
    },
    {
        k: 'XAP',
        v: 'Ampolleta protegida',
        o: 2080
    },
    {
        k: 'XAT',
        v: 'Atomizador',
        o: 2081
    },
    {
        k: 'XAV',
        v: 'Cápsula',
        o: 2082
    },
    {
        k: 'XB4',
        v: 'Cinturón',
        o: 2083
    },
    {
        k: 'XBA',
        v: 'Barril',
        o: 2084
    },
    {
        k: 'XBB',
        v: 'Bobina',
        o: 2085
    },
    {
        k: 'XBC',
        v: 'Cajón para botellas / Estante para botellas',
        o: 2086
    },
    {
        k: 'XBD',
        v: 'Tablero',
        o: 2087
    },
    {
        k: 'XBE',
        v: 'Flejado',
        o: 2088
    },
    {
        k: 'XBF',
        v: 'Globo no protegido',
        o: 2089
    },
    {
        k: 'XBG',
        v: 'Bolso',
        o: 2090
    },
    {
        k: 'XBH',
        v: 'Manojo',
        o: 2091
    },
    {
        k: 'XBI',
        v: 'Compartimiento',
        o: 2092
    },
    {
        k: 'XBJ',
        v: 'Cubeta',
        o: 2093
    },
    {
        k: 'XBK',
        v: 'Cesta',
        o: 2094
    },
    {
        k: 'XBL',
        v: 'Paca comprimida',
        o: 2095
    },
    {
        k: 'XBM',
        v: 'Cuenco',
        o: 2096
    },
    {
        k: 'XBN',
        v: 'Paca no comprimida',
        o: 2097
    },
    {
        k: 'XBO',
        v: 'Botella no-protegida y cilíndrica',
        o: 2098
    },
    {
        k: 'XBP',
        v: 'Globo protegido',
        o: 2099
    },
    {
        k: 'XBQ',
        v: 'Botella cilíndrica protegida',
        o: 2100
    },
    {
        k: 'XBR',
        v: 'Barra',
        o: 2101
    },
    {
        k: 'XBS',
        v: 'Botella, no-protegida en forma de bulbo',
        o: 2102
    },
    {
        k: 'XBT',
        v: 'Rollo de tela',
        o: 2103
    },
    {
        k: 'XBU',
        v: 'Butt',
        o: 2104
    },
    {
        k: 'XBV',
        v: 'Botella de bulbo protegido',
        o: 2105
    },
    {
        k: 'XBW',
        v: 'Caja para líquidos',
        o: 2106
    },
    {
        k: 'XBY',
        v: 'Tablero, con fleje/ agrupados/ armados',
        o: 2107
    },
    {
        k: 'XBZ',
        v: 'Barras, con fleje/ agrupados/ armados',
        o: 2108
    },
    {
        k: 'XCA',
        v: 'Lata rectangular',
        o: 2109
    },
    {
        k: 'XCB',
        v: 'Cajón para cerveza',
        o: 2110
    },
    {
        k: 'XCC',
        v: 'Mantequera',
        o: 2111
    },
    {
        k: 'XCD',
        v: 'Lata con mango y boquilla',
        o: 2112
    },
    {
        k: 'XCE',
        v: 'Cesto tejido',
        o: 2113
    },
    {
        k: 'XCF',
        v: 'Cofre',
        o: 2114
    },
    {
        k: 'XCG',
        v: 'Contenedor tipo Jaula',
        o: 2115
    },
    {
        k: 'XCH',
        v: 'Cajonera',
        o: 2116
    },
    {
        k: 'XCI',
        v: 'Frasco',
        o: 2117
    },
    {
        k: 'XCJ',
        v: 'Ataúd',
        o: 2118
    },
    {
        k: 'XCK',
        v: 'Barrica',
        o: 2119
    },
    {
        k: 'XCL',
        v: 'Espiral',
        o: 2120
    },
    {
        k: 'XCM',
        v: 'Paquete de tarjetas',
        o: 2121
    },
    {
        k: 'XCN',
        v: 'Contenedor, no especificado como equipo de transporte',
        o: 2122
    },
    {
        k: 'XCO',
        v: 'Garrafón no protegido',
        o: 2123
    },
    {
        k: 'XCP',
        v: 'Garrafón protegido',
        o: 2124
    },
    {
        k: 'XCQ',
        v: 'Cartucho',
        o: 2125
    },
    {
        k: 'XCR',
        v: 'Cajón',
        o: 2126
    },
    {
        k: 'XCS',
        v: 'Estuche',
        o: 2127
    },
    {
        k: 'XCT',
        v: 'Cartón',
        o: 2128
    },
    {
        k: 'XCU',
        v: 'Vaso',
        o: 2129
    },
    {
        k: 'XCV',
        v: 'Cubierta',
        o: 2130
    },
    {
        k: 'XCW',
        v: 'Jaula estilo rodillo',
        o: 2131
    },
    {
        k: 'XCX',
        v: 'Lata cilíndrica',
        o: 2132
    },
    {
        k: 'XCY',
        v: 'Cilindro',
        o: 2133
    },
    {
        k: 'XCZ',
        v: 'Lona',
        o: 2134
    },
    {
        k: 'XDA',
        v: 'Cajón multicapa de plástico',
        o: 2135
    },
    {
        k: 'XDB',
        v: 'Cajón de varias capas de madera',
        o: 2136
    },
    {
        k: 'XDC',
        v: 'Cajón multicapa de cartón',
        o: 2137
    },
    {
        k: 'XDG',
        v: 'Jaula, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP))',
        o: 2138
    },
    {
        k: 'XDH',
        v: 'Caja, Según la clasificación de la compañía (CHEP), Eurobox',
        o: 2139
    },
    {
        k: 'XDI',
        v: 'Tambor de hierro',
        o: 2140
    },
    {
        k: 'XDJ',
        v: 'damajuana o garrafa, no protegido',
        o: 2141
    },
    {
        k: 'XDK',
        v: 'Cajón a granel, cartón',
        o: 2142
    },
    {
        k: 'XDL',
        v: 'Cajas de plástico',
        o: 2143
    },
    {
        k: 'XDM',
        v: 'Cajones a granel de madera',
        o: 2144
    },
    {
        k: 'XDN',
        v: 'Dispensador',
        o: 2145
    },
    {
        k: 'XDP',
        v: 'damajuana o garrafa, protegido',
        o: 2146
    },
    {
        k: 'XDR',
        v: 'Tambor',
        o: 2147
    },
    {
        k: 'XDS',
        v: 'Bandeja de una capa sin cubierta y de plástico',
        o: 2148
    },
    {
        k: 'XDT',
        v: 'Bandeja de una capa sin cubierta y de madera',
        o: 2149
    },
    {
        k: 'XDU',
        v: 'Bandeja de una capa sin cubierta y  poliestireno',
        o: 2150
    },
    {
        k: 'XDV',
        v: 'Bandeja de una capa sin cubierta y de cartón',
        o: 2151
    },
    {
        k: 'XDW',
        v: 'Bandeja de dos capas sin tapa y con bandeja de plástico',
        o: 2152
    },
    {
        k: 'XDX',
        v: 'Bandeja de dos capas sin cubierta y de madera',
        o: 2153
    },
    {
        k: 'XDY',
        v: 'Bandeja de dos capas sin cubierta y de cartón',
        o: 2154
    },
    {
        k: 'XEC',
        v: 'Bolsa de plástico',
        o: 2155
    },
    {
        k: 'XED',
        v: 'Estuche, con pallet de base',
        o: 2156
    },
    {
        k: 'XEE',
        v: 'Estuche, con pallet base de madera',
        o: 2157
    },
    {
        k: 'XEF',
        v: 'Estuche, con pallet base de cartón',
        o: 2158
    },
    {
        k: 'XEG',
        v: 'Estuche, con pallet base de plástico',
        o: 2159
    },
    {
        k: 'XEH',
        v: 'Estuche, con pallet base de metal',
        o: 2160
    },
    {
        k: 'XEI',
        v: 'Estuche isotérmico',
        o: 2161
    },
    {
        k: 'XEN',
        v: 'Sobre',
        o: 2162
    },
    {
        k: 'XFB',
        v: 'Bolsa flexible',
        o: 2163
    },
    {
        k: 'XFC',
        v: 'Cajón para fruta',
        o: 2164
    },
    {
        k: 'XFD',
        v: 'Cajón enmarcado',
        o: 2165
    },
    {
        k: 'XFE',
        v: 'Tanque flexible',
        o: 2166
    },
    {
        k: 'XFI',
        v: 'Firkin',
        o: 2167
    },
    {
        k: 'XFL',
        v: 'Matraz',
        o: 2168
    },
    {
        k: 'XFO',
        v: 'Cajón para zapatos',
        o: 2169
    },
    {
        k: 'XFP',
        v: 'Caja auxiliar para película fotográfica',
        o: 2170
    },
    {
        k: 'XFR',
        v: 'Marco',
        o: 2171
    },
    {
        k: 'XFT',
        v: 'Contenedor para alimentos',
        o: 2172
    },
    {
        k: 'XFW',
        v: 'Carro de cama plana',
        o: 2173
    },
    {
        k: 'XFX',
        v: 'Bolsa flexible tipo contenedor',
        o: 2174
    },
    {
        k: 'XGB',
        v: 'Botella para gas',
        o: 2175
    },
    {
        k: 'XGI',
        v: 'Viga',
        o: 2176
    },
    {
        k: 'XGL',
        v: 'Contenedor tipo galón',
        o: 2177
    },
    {
        k: 'XGR',
        v: 'Recipiente de vidrio',
        o: 2178
    },
    {
        k: 'XGU',
        v: 'Bandeja contenedor para apilar horizontalmente objetos planos',
        o: 2179
    },
    {
        k: 'XGY',
        v: 'Costal de Yute',
        o: 2180
    },
    {
        k: 'XGZ',
        v: 'Vigas con correas o agrupadas',
        o: 2181
    },
    {
        k: 'XHA',
        v: 'Cesta con mango y de plástico',
        o: 2182
    },
    {
        k: 'XHB',
        v: 'Cesta con mango y de madera',
        o: 2183
    },
    {
        k: 'XHC',
        v: 'Cesta  con asa y de  cartón',
        o: 2184
    },
    {
        k: 'XHG',
        v: 'Hogshead',
        o: 2185
    },
    {
        k: 'XHN',
        v: 'Gancho',
        o: 2186
    },
    {
        k: 'XHR',
        v: 'Cesto',
        o: 2187
    },
    {
        k: 'XIA',
        v: 'Paquete con pantalla y de madera',
        o: 2188
    },
    {
        k: 'XIB',
        v: 'Paquete  con pantalla y de cartón',
        o: 2189
    },
    {
        k: 'XIC',
        v: 'Paquete con pantalla y de plástico',
        o: 2190
    },
    {
        k: 'XID',
        v: 'Paquete con pantalla y de metal',
        o: 2191
    },
    {
        k: 'XIE',
        v: 'Paquete de mostrador.',
        o: 2192
    },
    {
        k: 'XIF',
        v: 'Envase para alimentos',
        o: 2193
    },
    {
        k: 'XIG',
        v: 'Paquete envuelto en papel',
        o: 2194
    },
    {
        k: 'XIH',
        v: 'Tambor de plástico',
        o: 2195
    },
    {
        k: 'XIK',
        v: 'Paquete de cartón con los agujeros para botellas',
        o: 2196
    },
    {
        k: 'XIL',
        v: 'Bandeja rígida con tapa y apilable (CEN TS 14482: 2002)',
        o: 2197
    },
    {
        k: 'XIN',
        v: 'Lingote',
        o: 2198
    },
    {
        k: 'XIZ',
        v: 'Lingotes  con correas/ agrupados',
        o: 2199
    },
    {
        k: 'XJB',
        v: 'Bolsa jumbo',
        o: 2200
    },
    {
        k: 'XJC',
        v: 'Bidón rectangular',
        o: 2201
    },
    {
        k: 'XJG',
        v: 'Jarra',
        o: 2202
    },
    {
        k: 'XJR',
        v: 'Tarro',
        o: 2203
    },
    {
        k: 'XJT',
        v: 'Bolsa de yute',
        o: 2204
    },
    {
        k: 'XJY',
        v: 'Bidón, cilíndrico',
        o: 2205
    },
    {
        k: 'XKG',
        v: 'Barrilete',
        o: 2206
    },
    {
        k: 'XLE',
        v: 'Valijas',
        o: 2207
    },
    {
        k: 'XLG',
        v: 'Bitácora',
        o: 2208
    },
    {
        k: 'XLU',
        v: 'Caja de arrastre',
        o: 2209
    },
    {
        k: 'XLV',
        v: 'Contenedor pequeño',
        o: 2210
    },
    {
        k: 'XLZ',
        v: 'Registros  con fleje/ agrupados/ armados',
        o: 2211
    },
    {
        k: 'XMA',
        v: 'Cajón metálico',
        o: 2212
    },
    {
        k: 'XMB',
        v: 'Múltiplo de bolsas',
        o: 2213
    },
    {
        k: 'XMC',
        v: 'Cajón para leche',
        o: 2214
    },
    {
        k: 'XME',
        v: 'Contenedor de metal',
        o: 2215
    },
    {
        k: 'XMR',
        v: 'Recipiente de metal',
        o: 2216
    },
    {
        k: 'XMS',
        v: 'Saco milti-pared',
        o: 2217
    },
    {
        k: 'XMT',
        v: 'Tapete',
        o: 2218
    },
    {
        k: 'XMW',
        v: 'Contenedor envuelto en plástico',
        o: 2219
    },
    {
        k: 'XMX',
        v: 'Caja pequeña de cerillos',
        o: 2220
    },
    {
        k: 'XNA',
        v: 'No disponible',
        o: 2221
    },
    {
        k: 'XNE',
        v: 'Sin empaque o no empaquetado',
        o: 2222
    },
    {
        k: 'XNF',
        v: 'Sin empaque o no empaquetado, unidad simple',
        o: 2223
    },
    {
        k: 'XNG',
        v: 'Sin empaque o no empaquetado, unidades múltiples',
        o: 2224
    },
    {
        k: 'XNS',
        v: 'Caja nido',
        o: 2225
    },
    {
        k: 'XNT',
        v: 'Red',
        o: 2226
    },
    {
        k: 'XNU',
        v: 'Red de plástico con tubo',
        o: 2227
    },
    {
        k: 'XNV',
        v: 'Red textil con tubo',
        o: 2228
    },
    {
        k: 'XOA',
        v: 'Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 40 cm x 60 cm',
        o: 2229
    },
    {
        k: 'XOB',
        v: 'Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 80 cm x 120 cm',
        o: 2230
    },
    {
        k: 'XOC',
        v: 'Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 100 cm x 120 cm',
        o: 2231
    },
    {
        k: 'XOD',
        v: 'Pallet, AS 4068-1993',
        o: 2232
    },
    {
        k: 'XOE',
        v: 'Pallet, ISO T11',
        o: 2233
    },
    {
        k: 'XOF',
        v: 'Plataforma, peso o dimensión no especificada',
        o: 2234
    },
    {
        k: 'XOK',
        v: 'Bloque',
        o: 2235
    },
    {
        k: 'XOT',
        v: 'Octabin',
        o: 2236
    },
    {
        k: 'XP2',
        v: 'Charola',
        o: 2237
    },
    {
        k: 'XPA',
        v: 'Cajetilla',
        o: 2238
    },
    {
        k: 'XPB',
        v: 'Pallet, Caja combinada y abierta con caja y pallet.',
        o: 2239
    },
    {
        k: 'XPC',
        v: 'Paquete postal',
        o: 2240
    },
    {
        k: 'XPD',
        v: 'Pallet modular con collares (80cms * 100cms)',
        o: 2241
    },
    {
        k: 'XPE',
        v: 'Pallet modular con collares (80cms * 120cms)',
        o: 2242
    },
    {
        k: 'XPF',
        v: 'Corral',
        o: 2243
    },
    {
        k: 'XPG',
        v: 'Placa',
        o: 2244
    },
    {
        k: 'XPH',
        v: 'Cantaro',
        o: 2245
    },
    {
        k: 'XPI',
        v: 'Pleca',
        o: 2246
    },
    {
        k: 'XPJ',
        v: 'Canastilla',
        o: 2247
    },
    {
        k: 'XPL',
        v: 'Balde',
        o: 2248
    },
    {
        k: 'XPN',
        v: 'Tablón',
        o: 2249
    },
    {
        k: 'XPO',
        v: 'Bolsa pequeña',
        o: 2250
    },
    {
        k: 'XPR',
        v: 'Contenedor de plástico',
        o: 2251
    },
    {
        k: 'XPT',
        v: 'Maceta',
        o: 2252
    },
    {
        k: 'XPU',
        v: 'Cacerola',
        o: 2253
    },
    {
        k: 'XPV',
        v: 'Tubos, con fleje/ agrupados/ armados',
        o: 2254
    },
    {
        k: 'XPX',
        v: 'Pallet',
        o: 2255
    },
    {
        k: 'XPY',
        v: 'Placas con fleje/ agrupados/ armados',
        o: 2256
    },
    {
        k: 'XPZ',
        v: 'Tablones con fleje/ agrupados/ armados',
        o: 2257
    },
    {
        k: 'XQA',
        v: 'Tambor de acero con cabeza no desmontable',
        o: 2258
    },
    {
        k: 'XQB',
        v: 'Tambor de  acero con cabeza extraíble',
        o: 2259
    },
    {
        k: 'XQC',
        v: 'Tambor de aluminio con cabeza no extraíble',
        o: 2260
    },
    {
        k: 'XQD',
        v: 'Tambor de aluminio con cabeza extraíble',
        o: 2261
    },
    {
        k: 'XQF',
        v: 'Tambor, plástico con cabeza no desmontable',
        o: 2262
    },
    {
        k: 'XQG',
        v: 'Tambor, plástico, cabeza extraíble',
        o: 2263
    },
    {
        k: 'XQH',
        v: 'Barril de madera con tapón',
        o: 2264
    },
    {
        k: 'XQJ',
        v: 'Barril de madera con cabeza desprendible',
        o: 2265
    },
    {
        k: 'XQK',
        v: 'Bidón de acero con cabeza no desmontable',
        o: 2266
    },
    {
        k: 'XQL',
        v: 'Bidón de acero con cabeza desmontable',
        o: 2267
    },
    {
        k: 'XQM',
        v: 'Bidón de plástico con cabeza no desmontable',
        o: 2268
    },
    {
        k: 'XQN',
        v: 'Bidón de plástico con cabeza extraíble',
        o: 2269
    },
    {
        k: 'XQP',
        v: 'Caja de madera natural estándar',
        o: 2270
    },
    {
        k: 'XQQ',
        v: 'Caja de madera natural con muros a prueba de filtraciones',
        o: 2271
    },
    {
        k: 'XQR',
        v: 'Caja de plástico expandida',
        o: 2272
    },
    {
        k: 'XQS',
        v: 'Caja de plástico sólida',
        o: 2273
    },
    {
        k: 'XRD',
        v: 'Rod',
        o: 2274
    },
    {
        k: 'XRG',
        v: 'Anillo',
        o: 2275
    },
    {
        k: 'XRJ',
        v: 'Estante, Perchero para ropa',
        o: 2276
    },
    {
        k: 'XRK',
        v: 'Estante',
        o: 2277
    },
    {
        k: 'XRL',
        v: 'Carrete',
        o: 2278
    },
    {
        k: 'XRO',
        v: 'Rollo',
        o: 2279
    },
    {
        k: 'XRT',
        v: 'Red Roja',
        o: 2280
    },
    {
        k: 'XRZ',
        v: 'Varillas  con fleje/ agrupados/ armados',
        o: 2281
    },
    {
        k: 'XSA',
        v: 'Saco',
        o: 2282
    },
    {
        k: 'XSB',
        v: 'Losa',
        o: 2283
    },
    {
        k: 'XSC',
        v: 'Cajón poco profundo',
        o: 2284
    },
    {
        k: 'XSD',
        v: 'Huso',
        o: 2285
    },
    {
        k: 'XSE',
        v: 'Baúl',
        o: 2286
    },
    {
        k: 'XSH',
        v: 'Bolsa pequeña hermética',
        o: 2287
    },
    {
        k: 'XSI',
        v: 'Patín',
        o: 2288
    },
    {
        k: 'XSK',
        v: 'Carcasa esqueleto',
        o: 2289
    },
    {
        k: 'XSL',
        v: 'Hoja de deslizamiento',
        o: 2290
    },
    {
        k: 'XSM',
        v: 'Hoja de metal',
        o: 2291
    },
    {
        k: 'XSO',
        v: 'Carrete pequeño',
        o: 2292
    },
    {
        k: 'XSP',
        v: 'Hoja de empaque de plástico',
        o: 2293
    },
    {
        k: 'XSS',
        v: 'Cajón de acero',
        o: 2294
    },
    {
        k: 'XSU',
        v: 'Maleta',
        o: 2295
    },
    {
        k: 'XSV',
        v: 'Sobre de acero',
        o: 2296
    },
    {
        k: 'XSW',
        v: 'Envoltorio',
        o: 2297
    },
    {
        k: 'XSY',
        v: 'Manga',
        o: 2298
    },
    {
        k: 'XSZ',
        v: 'Hojas  con fleje/ agrupados/ armados',
        o: 2299
    },
    {
        k: 'XT1',
        v: 'Tableta',
        o: 2300
    },
    {
        k: 'XTB',
        v: 'Tina',
        o: 2301
    },
    {
        k: 'XTC',
        v: 'Caja para té',
        o: 2302
    },
    {
        k: 'XTD',
        v: 'Tubo plegable',
        o: 2303
    },
    {
        k: 'XTG',
        v: 'Contenedor de tanque genérico',
        o: 2304
    },
    {
        k: 'XTI',
        v: 'Tierce',
        o: 2305
    },
    {
        k: 'XTK',
        v: 'Tanque rectangular',
        o: 2306
    },
    {
        k: 'XTL',
        v: 'Tina con tapa',
        o: 2307
    },
    {
        k: 'XTN',
        v: 'Hojalata',
        o: 2308
    },
    {
        k: 'XTO',
        v: 'Tonel',
        o: 2309
    },
    {
        k: 'XTR',
        v: 'Maletero',
        o: 2310
    },
    {
        k: 'XTS',
        v: 'Estructura',
        o: 2311
    },
    {
        k: 'XTT',
        v: 'Bolsa de mano',
        o: 2312
    },
    {
        k: 'XTU',
        v: 'Tubo',
        o: 2313
    },
    {
        k: 'XTV',
        v: 'Tubo con boquilla',
        o: 2314
    },
    {
        k: 'XTW',
        v: 'Pallet tricapa',
        o: 2315
    },
    {
        k: 'XTY',
        v: 'Tanque cilíndrico',
        o: 2316
    },
    {
        k: 'XTZ',
        v: 'Tubos  con fleje/ agrupados/ armados',
        o: 2317
    },
    {
        k: 'XUC',
        v: 'Sin empaque',
        o: 2318
    },
    {
        k: 'XVA',
        v: 'Tanque',
        o: 2319
    },
    {
        k: 'XVG',
        v: 'Tanque de gas (a 1,031 mbar y 15° C)',
        o: 2320
    },
    {
        k: 'XVI',
        v: 'Frasco pequeño',
        o: 2321
    },
    {
        k: 'XVK',
        v: 'Paquete transportable',
        o: 2322
    },
    {
        k: 'XVL',
        v: 'Contenedor para líquidos a granel',
        o: 2323
    },
    {
        k: 'XVN',
        v: 'Vehículo',
        o: 2324
    },
    {
        k: 'XVO',
        v: 'Contenedor para sólido de partículas grandes a granel (\'nódulos\')',
        o: 2325
    },
    {
        k: 'XVP',
        v: 'Envasado al vacío',
        o: 2326
    },
    {
        k: 'XVQ',
        v: 'Tanque para Gas licuado (a temperatura / presión anormal)',
        o: 2327
    },
    {
        k: 'XVR',
        v: 'Contenedor para sólidos de partículas granulares a granel (Granos)',
        o: 2328
    },
    {
        k: 'XVS',
        v: 'Contenedor de chatarra a granel',
        o: 2329
    },
    {
        k: 'XVY',
        v: 'Contenedor para sólido de partículas finas a granel (\'polvos\')',
        o: 2330
    },
    {
        k: 'XWA',
        v: 'Contenedor de granel intermedio',
        o: 2331
    },
    {
        k: 'XWB',
        v: 'Botella de mimbre',
        o: 2332
    },
    {
        k: 'XWC',
        v: 'Contenedor intermedio para gráneles y de acero',
        o: 2333
    },
    {
        k: 'XWD',
        v: 'Contenedor intermedio para gráneles y de aluminio',
        o: 2334
    },
    {
        k: 'XWF',
        v: 'Contenedor intermedio para gráneles y de metal',
        o: 2335
    },
    {
        k: 'XWG',
        v: 'Contenedor intermedio para gráneles y de acero presurizado menor a 10 kpa',
        o: 2336
    },
    {
        k: 'XWH',
        v: 'Contenedor intermedio para gráneles y de aluminio, presurizado menor a 10 kpa',
        o: 2337
    },
    {
        k: 'XWJ',
        v: 'Contenedor intermedio para gráneles y de metal con una presión de 10 kpa',
        o: 2338
    },
    {
        k: 'XWK',
        v: 'Contenedor intermedio para gráneles y de acero para líquido',
        o: 2339
    },
    {
        k: 'XWL',
        v: 'Contenedor intermedio para gráneles y de aluminio para líquido',
        o: 2340
    },
    {
        k: 'XWM',
        v: 'Contenedor intermedio para gráneles y de metal para líquido',
        o: 2341
    },
    {
        k: 'XWN',
        v: 'Contenedor intermedio para gráneles con tejido plástico sin capa con revestimiento',
        o: 2342
    },
    {
        k: 'XWP',
        v: 'Contenedor intermedio para gráneles con tejido plástico y recubierto',
        o: 2343
    },
    {
        k: 'XWQ',
        v: 'Contenedor intermedio para gráneles con tejido plástico con revestimiento',
        o: 2344
    },
    {
        k: 'XWR',
        v: 'Contenedor intermedio para gráneles con tejido plástico, revestido y con forro',
        o: 2345
    },
    {
        k: 'XWS',
        v: 'Contenedor intermedio para gráneles con película de plástico',
        o: 2346
    },
    {
        k: 'XWT',
        v: 'Contenedor intermedio para gráneles textil sin capa / forro',
        o: 2347
    },
    {
        k: 'XWU',
        v: 'Contenedor intermedio para gráneles de madera natural con forro interior',
        o: 2348
    },
    {
        k: 'XWV',
        v: 'Contenedor intermedio para gráneles textil recubierto',
        o: 2349
    },
    {
        k: 'XWW',
        v: 'Contenedor intermedio para gráneles textil con revestimiento',
        o: 2350
    },
    {
        k: 'XWX',
        v: 'Contenedor intermedio para gráneles textil recubierto y con forro',
        o: 2351
    },
    {
        k: 'XWY',
        v: 'Contenedor intermedio para gráneles contrachapado con revestimiento interior',
        o: 2352
    },
    {
        k: 'XWZ',
        v: 'Contenedor intermedio para gráneles de madera reconstituida con revestimiento interior',
        o: 2353
    },
    {
        k: 'XXA',
        v: 'Bolsa de tejido plástico, sin abrigo interior ni forro',
        o: 2354
    },
    {
        k: 'XXB',
        v: 'Bolsa de tejido plástico a prueba de filtraciones',
        o: 2355
    },
    {
        k: 'XXC',
        v: 'Bolsa de tejido plástico resistente al agua',
        o: 2356
    },
    {
        k: 'XXD',
        v: 'Bolsa con película de plástico',
        o: 2357
    },
    {
        k: 'XXF',
        v: 'Bolsa textil sin capa ni forro interior',
        o: 2358
    },
    {
        k: 'XXG',
        v: 'Bolsa textil a prueba de filtraciones',
        o: 2359
    },
    {
        k: 'XXH',
        v: 'Bolsa textil resistente al agua',
        o: 2360
    },
    {
        k: 'XXJ',
        v: 'Bolsa de papel multi-pared',
        o: 2361
    },
    {
        k: 'XXK',
        v: 'Bolsa de papel multi-pared, resistente al agua',
        o: 2362
    },
    {
        k: 'XYA',
        v: 'Empaque compuesto, recipiente de plástico en tambor de acero',
        o: 2363
    },
    {
        k: 'XYB',
        v: 'Empaque compuesto, recipiente de plástico en cajas de acero',
        o: 2364
    },
    {
        k: 'XYC',
        v: 'Empaque compuesto, recipiente de plástico en tambor de aluminio',
        o: 2365
    },
    {
        k: 'XYD',
        v: 'Empaque compuesto, recipiente de plástico en cajón de aluminio',
        o: 2366
    },
    {
        k: 'XYF',
        v: 'Empaque compuesto, recipiente de plástico en caja de madera',
        o: 2367
    },
    {
        k: 'XYG',
        v: 'Empaque compuesto, recipiente de plástico en tambor de madera contrachapada',
        o: 2368
    },
    {
        k: 'XYH',
        v: 'Empaque compuesto, recipiente de plástico en caja de madera contrachapada',
        o: 2369
    },
    {
        k: 'XYJ',
        v: 'Empaque compuesto, recipiente de plástico en tambor de fibra',
        o: 2370
    },
    {
        k: 'XYK',
        v: 'Empaque compuesto, recipiente de plástico en caja de cartón',
        o: 2371
    },
    {
        k: 'XYL',
        v: 'Empaque compuesto, recipiente de plástico en el tambor de plástico',
        o: 2372
    },
    {
        k: 'XYM',
        v: 'Empaque compuesto, recipiente de plástico en caja de plástico sólido',
        o: 2373
    },
    {
        k: 'XYN',
        v: 'Empaque compuesto, receptáculo de vidrio en tambor de acero',
        o: 2374
    },
    {
        k: 'XYP',
        v: 'Empaque compuesto, receptáculo de vidrio en caja de cajas de acero',
        o: 2375
    },
    {
        k: 'XYQ',
        v: 'Empaque compuesto, recipiente de vidrio en tambor de aluminio',
        o: 2376
    },
    {
        k: 'XYR',
        v: 'Empaque compuesto, receptáculo de vidrio en caja de aluminio',
        o: 2377
    },
    {
        k: 'XYS',
        v: 'Empaque compuesto, recipiente de vidrio en caja de madera',
        o: 2378
    },
    {
        k: 'XYT',
        v: 'Empaque compuesto, recipiente de vidrio en tambor de madera contrachapada',
        o: 2379
    },
    {
        k: 'Xyv',
        v: 'Empaque compuesto, recipiente de vidrio en el cesto de mimbre',
        o: 2380
    },
    {
        k: 'XYW',
        v: 'Empaque compuesto, recipiente de vidrio en tambor de fibra',
        o: 2381
    },
    {
        k: 'XYX',
        v: 'Empaque compuesto, recipiente de vidrio en caja de cartón',
        o: 2382
    },
    {
        k: 'XYY',
        v: 'Empaque compuesto, recipiente de vidrio en paquete de plástico expandible',
        o: 2383
    },
    {
        k: 'XYZ',
        v: 'Empaque compuesto, recipiente de vidrio en paquete de plástico sólido',
        o: 2384
    },
    {
        k: 'XZA',
        v: 'Contenedor de granel intermedio, papel, multi-pared',
        o: 2385
    },
    {
        k: 'XZB',
        v: 'Bolsa grande',
        o: 2386
    },
    {
        k: 'XZC',
        v: 'Contenedor intermedio para gráneles de papel, multi-pared y resistente al agua',
        o: 2387
    },
    {
        k: 'XZD',
        v: 'Contenedor intermedio para gráneles de plástico rígido, con equipo estructural para sólidos',
        o: 2388
    },
    {
        k: 'XZF',
        v: 'Contenedor intermedio para gráneles de plástico rígido, autoportante para sólidos',
        o: 2389
    },
    {
        k: 'XZG',
        v: 'Contenedor intermedio para gráneles de plástico rígido, con equipo estructural, presurizado',
        o: 2390
    },
    {
        k: 'XZH',
        v: 'Contenedor intermedio para gráneles de plástico rígido, autoportante y presurizado',
        o: 2391
    },
    {
        k: 'XZJ',
        v: 'Contenedor intermedio para gráneles de plástico rígido, con equipo estructural para líquidos',
        o: 2392
    },
    {
        k: 'XZK',
        v: 'Contenedor intermedio para gráneles de plástico rígido, autoportante, líquidos',
        o: 2393
    },
    {
        k: 'XZL',
        v: 'Contenedor intermedio para gráneles, compuesto y de plástico rígido, sólidos',
        o: 2394
    },
    {
        k: 'XZM',
        v: 'Contenedor intermedio para gráneles, compuesto y de plástico flexible, sólidos',
        o: 2395
    },
    {
        k: 'XZN',
        v: 'Contenedor intermedio para gráneles, compuesto y de plástico rígido, presurizado',
        o: 2396
    },
    {
        k: 'XZP',
        v: 'Contenedor intermedio para gráneles, compuesto y de plástico flexible, presurizado',
        o: 2397
    },
    {
        k: 'XZQ',
        v: 'Contenedor intermedio para gráneles, compuesto y de plástico rígido, líquidos',
        o: 2398
    },
    {
        k: 'XZR',
        v: 'Contenedor intermedio para gráneles, compuesto y de plástico flexible para líquidos',
        o: 2399
    },
    {
        k: 'XZS',
        v: 'Contenedor intermedio para gráneles, compuesto',
        o: 2400
    },
    {
        k: 'XZT',
        v: 'Contenedor intermedio para gráneles con tablero de fibras',
        o: 2401
    },
    {
        k: 'XZU',
        v: 'Contenedor intermedio para gráneles flexible',
        o: 2402
    },
    {
        k: 'XZV',
        v: 'Contenedor intermedio para gráneles de metal, distinto del acero',
        o: 2403
    },
    {
        k: 'XZW',
        v: 'Contenedor intermedio para gráneles, de madera natural',
        o: 2404
    },
    {
        k: 'XZX',
        v: 'Contenedor intermedio para gráneles, de contrachapado',
        o: 2405
    },
    {
        k: 'XZY',
        v: 'Contenedor intermedio para gráneles, de madera reconstituida',
        o: 2406
    },
    {
        k: 'YDK',
        v: 'Yarda cuadrada',
        o: 2407
    },
    {
        k: 'YDQ',
        v: 'Yarda cúbica',
        o: 2408
    },
    {
        k: 'YL',
        v: 'Cien yardas lineales',
        o: 2409
    },
    {
        k: 'YRD',
        v: 'Yarda',
        o: 2410
    },
    {
        k: 'YT',
        v: 'Diez yardas',
        o: 2411
    },
    {
        k: 'Z1',
        v: 'Furgoneta',
        o: 2412
    },
    {
        k: 'Z11',
        v: 'Contenedor colgante',
        o: 2413
    },
    {
        k: 'Z5',
        v: 'Arrastre',
        o: 2414
    },
    {
        k: 'Z6',
        v: 'Punto de conferencia',
        o: 2415
    },
    {
        k: 'Z8',
        v: 'Página de noticias',
        o: 2416
    },
    {
        k: 'ZP',
        v: 'Páginas',
        o: 2417
    },
    {
        k: 'ZZ',
        v: 'Mutuamente definido',
        o: 2418
    }
]
