<template>
  <b-sidebar
    id="add-new-product-sidebar"
    :visible="isAddNewProductSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="600px"
    no-close-on-esc
    no-close-on-backdrop
    no-close-on-route-change
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-product-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('Add New Product') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <b-col cols="6">
              <!-- Product ID -->
              <validation-provider
                #default="validationContext"
                name="Id"
                rules="required"
              >
                <b-form-group
                  :label="t('Id')"
                  label-for="id"
                  :invalid-feedback="t('productidr')"
                  v-b-tooltip.hover.top="t('tprodid')"
                  label-size="sm"
                >
                  
                    <b-form-input
                      id="id"
                      v-model="productData.id"
                      autofocus
                      :state="getValidationState(validationContext)"
                      :formatter="fmt15num"
                      trim
                      :placeholder="t('idholder')"
                    />
                  
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <!-- Product SKU -->
              <validation-provider
                #default="validationContext"
                name="SKU"                
              >
                <b-form-group
                  :label="t('SKU')"
                  label-for="sku"
                  label-size="sm"
                >
                  <b-form-input
                    id="sku"
                    v-model="productData.sku"
                    autofocus
                    :state="getValidationState(validationContext)"
                    :formatter="fmtUppercase"
                    trim
                    :placeholder="t('skuholder')"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Clasificacion SAT -->
          <validation-provider
            #default="validationContext"
            name="SAT Concept Class"
            rules="required"
          >
            <b-form-group
              :label="t('SAT Concept Class')"
              label-for="satclass"
              label-size="sm"
            >
              <v-select
                id="satclass"
                v-model="productData.sat_class"
                text-field="v"
                label="v"
                :filterable="false"
                :options="satclassList"
                @search="searchPYS"
                @input="changePYS"
              >
                <template slot="no-options">
                  Introduce clave o termino de busqueda...
                </template>
                <template #selected-option="option">
                  <div v-if="option && option.v !== ''" style="display: flex; align-items: baseline">
                    <strong>{{ option.k }}</strong>
                    <em style="margin-left: 0.5rem">: {{ option.v.length > 52 ? option.v.substr(0,52) : option.v }}</em>
                  </div>
                  <div v-else>
                  </div>
                </template>
                <template v-slot:option="option">
                  {{ option.k }} : {{ option.v }}
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Unidad de Medida -->
          <validation-provider
            #default="validationContext"
            name="SAT Concept Unit"
            rules="required"
          >
            <b-form-group
              :label="t('SAT Concept Unit')"
              label-for="satunit"
              label-size="sm"
            >
              <v-select
                id="satunit"
                v-model="productData.sat_unit"
                text-field="v"
                label="v"
                :options="satunitList"
              >
                <template #selected-option="option">
                  <div v-if="option && option.v !== ''" style="display: flex; align-items: baseline">
                    <strong>{{ option.k }}</strong>
                    <em style="margin-left: 0.5rem">: {{ option.v }}</em>
                  </div>
                  <div v-else>
                  </div>
                </template>
                <template v-slot:option="option">
                  {{ option.k }} : {{ option.v }}
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Descripción del producto -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              :label="t('Description')"
              label-for="desc"
              :invalid-feedback="t('descreq')"
              v-b-tooltip.hover.top="t('tdescrip')"
              label-size="sm"
            >
            <!-- ILL: 230623 se agrega validación para que no se pueda capturar un pipe en la descripción del producto, ya que el mismo no es permitido conforme a XSD (validateNoPipe)-->
              <b-form-input
                id="desc"
                v-model="productData.desc"
                :state="getValidationState(validationContext) && validateNoPipe"
                :formatter="fmt1000num"
                trim
              />
            </b-form-group>
          </validation-provider>

          <div class="divider my-2" v-if="sectionRealstate || sectionSchoolPrivate">
            <div class="divider-text">
              {{ t('Special Specifications') }}              
            </div>
          </div> 

          <b-row v-if="sectionRealstate">
            <b-col cols="3">
              <b-form-group
                :label="t('Lease')"
                label-for="lease"
                label-size="sm"
              >
                <b-form-checkbox
                  id="lease"
                  disabled
                  v-model="productData.extra.lease.flag"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col cols="9">
                <validation-provider
                #default="validationContext"
                name="Description"
                rules="required|regex:^[0-9a-zA-Z]*$"
              >                
                <b-form-group
                  :label="t('Cadastral Key')"
                  label-for="cadkey"
                  :invalid-feedback="t('cadastral')"
                  label-size="sm"
                  v-b-tooltip.hover.top="t('tcadastral')"
                >
                  <b-form-input
                    id="cadkey"
                    v-model="productData.extra.lease.id"
                    :disabled="productData.extra.lease.flag === false ? true : false"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="t('cadkeyholder')"
                  />
                </b-form-group>
                </validation-provider>                  
              </b-col>
          </b-row>

          <b-row v-if="sectionSchoolPrivate">
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="Auth RVOE"                  
              >   
                <b-form-group
                  :label="t('RVOE / CCT')"
                  label-for="school-rvoe"
                  label-size="sm"
                  v-b-tooltip.hover.top="t('trvoe')"
                >
                  <b-form-input
                    id="school-rvoe"
                    v-model="productData.extra.school.auth"
                    type="text"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="t('RVOE / CCT')"
                  />
                </b-form-group>
              </validation-provider>   
            </b-col>
            <b-col cols="6">
                <validation-provider
                #default="validationContext"
                name="Nivel Escolar"                  
              >                
                <b-form-group
                  :label="t('School Level')"
                  label-for="school-level"                  
                  label-size="sm"
                >
                  <v-select
                    id="school-level"
                    v-model="productData.extra.school.level"
                    text-field="v"
                    value-field="k"
                    label="v"
                    :state="getValidationState(validationContext)"
                    :options="sateduSchoolLevelList"
                  >
                  </v-select>
                </b-form-group>
                </validation-provider>                  
              </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              {{ t('Pricing') }}              
            </div>
          </div>          
            <b-row>
              <b-col cols="3">
                <b-form-group
                  :label="t('Dynamic Price')"
                  label-for="ptype"
                  v-b-tooltip.hover.top="t('dynpricecheck')"
                  label-size="sm"
                >
                  <b-form-checkbox
                    id="ptype"
                    v-model="productData.price.type"
                    value="dynamic"
                    unchecked-value="fixed"
                    switch
                  />
                </b-form-group>
              </b-col>
              <b-col cols="5">
                <validation-provider
                 #default="validationContext"
                  name="Description"
                  rules="required|regex:^[0-9]*(\.[0-9]{2})?$"
                ><!--ILL: 170322: CORRECCIÓN DE REGEX-->
                  <b-form-group
                    :label="t('Amount')"
                    label-for="pamount"
                    :invalid-feedback="t('desamount')"
                    v-b-tooltip.hover.top="t('tprice')"
                    label-size="sm"
                  >
                    <b-form-input
                      id="pamount"
                      v-model="productData.price.amount"
                      style="text-align: right;"
                      :state="getValidationState(validationContext)"
                      trim
                    >
                    </b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  :label="t('Currency')"
                  label-for="pcurrency"
                >
                  <v-select
                    id="pcurrency"
                    v-model="productData.price.currency"
                    text-field="v"
                    value-field="k"
                    label="k"
                    :options="currencyList"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          

          <div class="divider my-2">
            <div class="divider-text">
              {{ t('Taxes') }}              
            </div>
          </div>

          <b-row>
            <b-col cols="4">
              <b-form-group
                :label="t('Apply IVA Translate')"
                label-for="tiva_translate_apply"
                label-size="sm"
              >
                <b-form-checkbox
                  id="tiva_translate_apply"
                  v-model="productData.taxes.iva_ta"
                  @change="productData.taxes.iva_ta === true ? productData.taxes.iva_tr = sattaxIvaTranslateList[0] : productData.taxes.iva_tr = ''"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="t('IVA Translate')"
                label-for="tiva_translate"
                label-size="sm"
              >
                <v-select
                  id="tiva_translate"
                  :disabled="productData.taxes.iva_ta === false ? true : false"
                  v-model="productData.taxes.iva_tr"
                  text-field="v"
                  value-field="k"
                  label="v"
                  :options="sattaxIvaTranslateList"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="4">
              <b-form-group
                :label="t('Apply IVA Retention')"
                label-for="tiva_retention_apply"
                label-size="sm"
              >
                <b-form-checkbox
                  id="tiva_retention_apply"
                  v-model="productData.taxes.iva_ra"
                  switch
                  @change="productData.taxes.iva_ra === true ? void(0) : productData.taxes.iva_rr = ''"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="t('IVA Retention')"
                label-for="tiva_retention"
                label-size="sm"
              >
                <v-select
                  id="tiva_retention"
                  :disabled="productData.taxes.iva_ra === false ? true : false"
                  v-model="productData.taxes.iva_rr"
                  text-field="v"
                  value-field="k"
                  label="v"
                  value="k"
                  :options="sattaxIvaRetentionList"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
            </b-col>
          </b-row>
          
          <b-row>
            <b-col cols="4">
              <b-form-group
                :label="t('Apply ISR Retention')"
                label-for="tisr_retention_apply"
                label-size="sm"
              >
                <b-form-checkbox
                  id="tisr_retention_apply"
                  v-model="productData.taxes.isr_ra"
                  switch
                  @change="productData.taxes.isr_ra === true ? void(0) : productData.taxes.isr_rr = ''"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="t('ISR Retention')"
                label-for="tisr_retention"
                label-size="sm"
              >
                <v-select
                  id="tisr_retention"
                  :disabled="productData.taxes.isr_ra === false ? true : false"
                  v-model="productData.taxes.isr_rr"
                  text-field="v"
                  value-field="k"
                  label="v"
                  value="k"
                  :options="sattaxIsrRetentionList"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="4">
              <b-form-group
                :label="t('Apply IEPS Translate')"
                label-for="tieps_translate_apply"
                label-size="sm"
              >
                <b-form-checkbox
                  id="tieps_translate_apply"
                  v-model="productData.taxes.ieps_ta"
                  switch
                  @change="productData.taxes.ieps_ta === true ? void(0) : productData.taxes.ieps_tt = 'rate'; productData.taxes.ieps_tr = ''; productData.taxes.ieps_tq = ''"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="t('IEPS Translate Type')"
                label-for="tieps_translate_type"
                label-size="sm"
              >
                <b-form-checkbox
                  id="tieps_translate_type"
                  :disabled="productData.taxes.ieps_ta === false ? true : false"
                  v-model="productData.taxes.ieps_tt"
                  switch
                  value="quote"
                  unchecked-value="rate"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                v-if="productData.taxes.ieps_tt === 'rate'"
                :label="t('Rate')"
                label-for="tieps_translate_rate"
                label-size="sm"
              >
                <v-select
                  id="tieps_translate_rate"
                  :disabled="productData.taxes.ieps_ta === false ? true : false"
                  v-model="productData.taxes.ieps_tr"
                  text-field="v"
                  value-field="k"
                  label="v"
                  :options="sattaxIepsTranslateList"
                >
                </v-select>
              </b-form-group>
              <b-form-group
                v-if="productData.taxes.ieps_tt === 'quote'"
                :label="t('Quote')"
                label-for="tieps_translate_quote"
                :invalid-feedback="t('desamount')"
                v-b-tooltip.hover.top="t('iepscuota')"
                label-size="sm"
              >
                <b-form-input
                  id="tieps_translate_quote"
                  :disabled="productData.taxes.ieps_ta === false ? true : false"
                  v-model="productData.taxes.ieps_tq"
                  style="text-align: right;"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="4">
              <b-form-group
                :label="t('Apply IEPS Retention')"
                label-for="tieps_retention_apply"
                label-size="sm"
              >
                <b-form-checkbox
                  id="tieps_retention_apply"
                  v-model="productData.taxes.ieps_ra"
                  switch
                  @change="productData.taxes.ieps_ra === true ? void(0) : productData.taxes.ieps_rt = 'rate'; productData.taxes.ieps_rr = ''; productData.taxes.ieps_rq = ''"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="t('IEPS Translate Type')"
                label-for="tieps_retention_type"
                label-size="sm"
              >
                <b-form-checkbox
                  id="tieps_retention_type"
                  :disabled="productData.taxes.ieps_ra === false ? true : false"
                  v-model="productData.taxes.ieps_rt"
                  switch
                  value="quote"
                  unchecked-value="rate"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                v-if="productData.taxes.ieps_rt === 'rate'"
                :label="t('Rate')"
                label-for="tieps_retention_rate"
                label-size="sm"
              >
                <v-select
                  id="tieps_retention_rate"
                  :disabled="productData.taxes.ieps_ra === false ? true : false"
                  v-model="productData.taxes.ieps_rr"
                  text-field="v"
                  value-field="k"
                  label="v"
                  :options="sattaxIepsRetentionList"
                >
                </v-select>
              </b-form-group>
              <b-form-group
                v-if="productData.taxes.ieps_rt === 'quote'"
                :label="t('Quote')"
                label-for="tieps_retention_quote"
                v-b-tooltip.hover.top="t('iepscuota')"
                label-size="sm"
              >
                <b-form-input
                  id="tieps_retention_quote"
                  :disabled="productData.taxes.ieps_ra === false ? true : false"
                  v-model="productData.taxes.ieps_rq"
                  style="text-align: right;"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="!isSaveEnabled"
            >
              {{ t('Add') }}   
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BRow, BCol, BFormCheckbox, VBTooltip, BInputGroup,BInputGroupPrepend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import satunitList from '@/@catalogs/sat/c_claveunidad'
import sattaxIvaTranslateList from '@/@catalogs/sat/c_iva_tra'
import sattaxIvaRetentionList from '@/@catalogs/sat/c_iva_ret'
import sattaxIepsTranslateList from '@/@catalogs/sat/c_ieps_tra'
import sattaxIepsRetentionList from '@/@catalogs/sat/c_ieps_ret'
import sattaxIsrRetentionList from '@/@catalogs/sat/c_isr_ret'
import sateduSchoolLevelList from '@/@catalogs/sat/c_niveledu'
/** fer@arkeline.dev 21/03/2023 Product Currency Feature */
import currencyList from '@/@catalogs/sat/c_moneda'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupPrepend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  model: {
    prop: 'isAddNewProductSidebarActive',
    event: 'update:is-add-new-product-sidebar-active',
  },
  props: {
    isAddNewProductSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isSaveEnabled: {
      get() {
        let evalRealState     = this.productData.extra.lease.flag ? this.productData.extra.lease.id !== '' : true
        let evalSchoolPrivate = this.productData.extra.school.flag ? this.productData.extra.school.auth !== '' && this.productData.extra.school.level && this.productData.extra.school.level.k !== '' : true
        
        return this.productData.id !== '' && 
               this.productData.desc !== '' &&
               this.productData.price.type !== '' &&
               this.productData.price.amount !== '' && 
               this.productData.sat_class.k !== '' &&
               this.productData.sat_unit.k !== '' &&
               evalRealState &&
               evalSchoolPrivate
      }
    },
    validateNoPipe(){
      const regEx = /^[^|]{1,1000}$/
      const isValid = regEx.test(this.productData.desc);
      this.descripValid = isValid
      if(isValid){        
      }else{
        this.productData.desc = ''
      }

      return isValid;
    }    
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      satunitList,
      sattaxIvaTranslateList,
      sattaxIvaRetentionList,
      sattaxIsrRetentionList,
      sattaxIepsTranslateList,
      sattaxIepsRetentionList,
      sateduSchoolLevelList,

      /** fer@arkeline.dev 21/03/2023 Product Currency Feature */
      currencyList,
      descripValid: false,
      satclassList: [],
      session: null,
      supportRealstate: false,
      supportSchoolPrivate: false,
      sectionRealstate: false,
      sectionSchoolPrivate: false
    }
  },
  mounted () {
      this.session = JSON.parse(localStorage.getItem('session'))
      let taxpayerArray = Array.isArray(this.session.business.taxpayer) ? this.session.business.taxpayer : [this.session.business.taxpayer]
      taxpayerArray.forEach(e => {
        /** We need to find a better way to resolve this */
        if(e.k === '606' || e.k === '601' || e.k === '603' || e.k === '626') { // Arrendamiento, Regimen General de Personas Morales, Personas morales fines no lucrativos, Resico 
          this.supportRealstate = true
        }
  //ILL: 180822 se agrega rif (621) para colegiaturas
        if(e.k === '601' || e.k === '603' || e.k === '626' || e.k === '612' || e.k === '621') { // Regimen General de Personas Morales, Personas morales fines no lucrativos, Resico, Personas Fisicas con Actividad Empresarial, RIF
          this.supportSchoolPrivate = true
        }
      })
  },  
  setup(props, { emit }) {
    const blankProductData = {
      id: '', // ID
      sku: '', // SKU
      type: '', // Tipo P/S
      config: '*', // Configuracion, si no existe es compatible en todos lados
      sat_class: { // Clasificacion SAT
        k: '',
        v: '',
      }, 
      sat_unit: { // Unidad SAT
        k: '',
        v: ''
      },
      desc: '',
      extra: {
        lease: {
          flag: false,
          id: '',
        },
        school: {
          flag: false,
          auth: '',
          level: '',
          _alummni_name: '',
          _alummni_popid: ''
        }
      },
      price : {
        type: 'fixed',
        amount: '',
        /** fer@arkeline.dev 21/03/2023 Product Currency Feature
         *                   By default, mexican peso MXN, is selected
         */
        currency: {
          k: 'MXN',
          v: 'Peso Mexicano',
          or: 1,
          de: 2
        }
      },
      taxes : {
        iva_ta: false,
        iva_ra: false,
        isr_ra: false,
        ieps_ta: false,
        ieps_ra: false,
        iva_tr: '',
        iva_rr: '',
        isr_rr: '',
        ieps_tt: 'rate',
        ieps_tr: '',
        ieps_tq: '',
        ieps_rt: 'rate',
        ieps_rr: '',
        ieps_rq: ''
      }
    }

    const productData = ref(JSON.parse(JSON.stringify(blankProductData)))
    const resetproductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const onSubmit = () => {
      // Hay que validar antes el envio
      store.dispatch('fg-product/addProduct', productData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-product-sidebar-active', false)
          const v0 = 'Datos Actualizados'
          //const v0 =  t("buy instead of")
          
          const v1 = 'Producto Agregado'

          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: v0,
              icon: 'CheckIcon',
              variant: 'success',
              text: v1,
            },
          })                    
        })
        .catch((error) => {
          const v0 = 'Error al agregar el producto'
          const v1 = 'El Identificador del producto ya existe, por favor verificar'
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title:  v0,
              icon: 'AlertTriangleIcon',
              variant: 'warning',
              text: v1,
            },
          })

        })        
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetproductData)

    /** lsandoval: 26/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils()
    const toast = useToast()
    return {
      productData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      t,
    }
  },
  methods: {
    fmtUppercase(value) {
        let v = value.toUpperCase()
        return v
    },
    // ILL: 270122 para que la longitud del campo phone sea max = 10
    fmt10num(value) {        
        let v=value
        v = v.length > 10 ? v.substr(0,10) : v
        return v
    },
    // ILL: 231122 para que la longitud del campo descripción se max = 999
        fmt1000num(value) {        
        let v=value
        v = v.length > 999 ? v.substr(0,999) : v
        return v
    },  
      // ILL: 120423 para que la longitud del campo id sea max = 15
        fmt15num(value) {        
        let v = value.toUpperCase()
        v = v.length > 20 ? v.substr(0,20) : v
        return v
    },      
    changePYS () {
      console.log('pys selection', this.productData.sat_class)
      if(this.productData.sat_class && this.productData.sat_class.k !== '') {
        this.productData.type = this.productData.sat_class.t
      } else {
        this.productData.type = ''
      }

      /** Realstate section */
      this.sectionRealstate = false
      this.productData.extra.lease.flag = false
      this.productData.extra.lease.id = ''
      /** School Private section */
      this.sectionSchoolPrivate = false
      this.productData.extra.school.flag = false
      this.productData.extra.school.auth = ''
      this.productData.extra.school.level = ''
      
      if(this.productData.sat_class && this.productData.sat_class.c){
        switch(this.productData.sat_class.c.b) {
          case 'fg.leasing.realstate':
            this.sectionRealstate = true 
            this.productData.config = 'fg.leasing.realstate'
            this.productData.extra.lease.flag = true
          break;
          case 'fg.school.private':
            this.sectionSchoolPrivate = true 
            this.productData.config = 'fg.school.private'
            this.productData.extra.school.flag = true
          break;
        }
        this.productData.sat_unit = this.satunitList.find(o => o.k === this.productData.sat_class.c.u[0])
        /** fer@arkeline.io We need to check if t is an Object or an Array */
        if(Array.isArray(this.productData.sat_class.c.t)) {
          if(this.productData.sat_class.c.t.find(item => item === 'iva_t')) { 
            this.productData.taxes.iva_ta = true
          }
        } else if(this.productData.sat_class.c.t.iva_t) { 
          this.productData.taxes.iva_ta = true
          this.productData.taxes.iva_tr = { k: this.productData.sat_class.c.t.iva_t.t, v: this.productData.sat_class.c.t.iva_t.t, c_impuesto_k: '002' }
        }
      } else {
        this.productData.taxes.iva_ta = false
        this.productData.taxes.iva_ra = false
        this.productData.taxes.isr_ra = false
        this.productData.taxes.ieps_ta = false
      }

    },
    searchPYS (value, loading) {
      // Hay que validar antes el envio
      if(value && value.length < 3)
        return 

      loading(true)
      this.$store.dispatch('fg-product/searchPYS', value )
        .then((response) => {
          const pysList = response.data.list
          const pysListConcrete = []

          pysList.forEach(e => {
            if(e.c) {
              if(this.supportRealstate && e.c.b === 'fg.leasing.realstate'){
                pysListConcrete.push(e)
              }

              if(this.supportSchoolPrivate && e.c.b === 'fg.school.private'){
                pysListConcrete.push(e)
              }
            } else pysListConcrete.push(e)
          })

          this.satclassList = pysListConcrete
          loading(false)
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
